/** @jsxImportSource @emotion/react */
import React from "react";
import { useForm } from "react-hook-form";
import { css } from "@emotion/react"
import { SalesDetailHeading } from "../../../../molecules/Heading/Sales/Detail";
import { InputLabel } from "../../../../molecules/Form/InputLabel";
import { SelectBox } from "../../../../atoms/Form/Select";
import { Text } from "../../../../atoms/Text"
import { Button } from "../../../../atoms/Button";
import { iconStyle } from "../../../../atoms/Icon";

const defaultProps = {};

type Props = {
  children?: React.ReactNode;
} & typeof defaultProps;

export default function SalesDetail(props: Props) {
  const {
    control,
    register
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  return (
    <>
      <SalesDetailHeading>営業進捗</SalesDetailHeading>
      <div css={todoListWrapStyle}>
        <Text Tag="p" color="black" size="xxl">ToDoリスト</Text>
        <ul css={todoListStyle}>
          <li css={todoContentWrapStyle}>
            <div css={todoContentStyle}>
              <Text Tag="p" color="black" size="xl">プロフィールシートの作成</Text>
              <Text Tag="p" color="link" size="m">編集リンクをコピー</Text>
            </div>
            <Button type="quaternary" Tag="button" size="s" propStyle={todoButtonStyle}>開く</Button>
          </li>
          <li css={todoContentWrapStyle}>
            <div css={todoContentStyle}>
              <Text Tag="p" color="black" size="xl">面談練習</Text>
              <Text Tag="p" color="gray" size="m">自己紹介の研修はもちろん、髪型や服装の確認も行いましょう</Text>
            </div>
            <Button type="quaternary" Tag="button" size="s" propStyle={todoButtonStyle}>開く</Button>
          </li>
        </ul>
      </div>
      <div css={salesWrapStyle}>
        <InputLabel label={"営業開始日"} type={"date"} name={"email"} register={register} rules={{required: true}}/>
        <div css={salesSelectOverWrapStyle}>
          <Text Tag="p" color="black" size="m" isBold>提案中の案件</Text>
          <ul css={salesSelectWrapStyle}>
            <li css={salesSelectItemStyle}>
              <SelectBox options={[{id:1,name:"インフラドットコム・インフラ案件"},{id:2,name:"インフラドットコム・インフラ案件"},{id:3,name:"インフラドットコム・インフラ案件"},{id:4,name:"インフラドットコム・インフラ案件"}]} name={"name01"} control={control} propStyle={caseStyle} placeholder={"選択してください"} />
              <SelectBox options={[{id:1,name:"スキルシート送付"},{id:2,name:"面談"},{id:3,name:"お見送り"},{id:4,name:"入職"}]} name={"name10"} control={control} propStyle={stateStyle} placeholder={"選択してください"} />
            </li>
            <li css={salesSelectItemStyle}>
              <SelectBox options={[{id:1,name:"インフラドットコム・インフラ案件"},{id:2,name:"インフラドットコム・インフラ案件"},{id:3,name:"インフラドットコム・インフラ案件"},{id:4,name:"インフラドットコム・インフラ案件"}]} name={"name02"} control={control} propStyle={caseStyle} placeholder={"選択してください"} />
              <SelectBox options={[{id:1,name:"スキルシート送付"},{id:2,name:"面談"},{id:3,name:"お見送り"},{id:4,name:"入職"}]} name={"name20"} control={control} propStyle={stateStyle} placeholder={"選択してください"} />
            </li>
            <li css={salesSelectItemStyle}>
              <SelectBox options={[{id:1,name:"インフラドットコム・インフラ案件"},{id:2,name:"インフラドットコム・インフラ案件"},{id:3,name:"インフラドットコム・インフラ案件"},{id:4,name:"インフラドットコム・インフラ案件"}]} name={"name03"} control={control} propStyle={caseStyle} placeholder={"選択してください"} />
              <SelectBox options={[{id:1,name:"スキルシート送付"},{id:2,name:"面談"},{id:3,name:"お見送り"},{id:4,name:"入職"}]} name={"name30"} control={control} propStyle={stateStyle} placeholder={"選択してください"} />
            </li>
          </ul>
        </div>
      </div>
      {/* <ul>
        {props.users.map((item,key) => {
          return (
            <li key={key}>
              <SalesList item={item} />
            </li>
          )
        })}
      </ul> */}
    </>
  );
}

SalesDetail.defaultProps = defaultProps;

const todoListWrapStyle = css`
  margin-top: 32px;
`
const todoListStyle = css`
  >li {
    margin-top: 16px;
  }
`
const todoContentWrapStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  /* White */
  background: #FFFFFF;
  /* Mono/400 */
  border: 1px solid #CCCCCC;
  border-radius: 4px;
`
const todoContentStyle = css`
  display: flex;
  align-items: center;
  ${iconStyle("icon-Icon_state_off")}
  ::before {
    font-size: 20px;
    margin-right: 10px;
  }
  >:last-of-type {
    margin-left: 10px;
  }
`
const todoButtonStyle = css`
  width: 100%;
  max-width: 64px;
  max-height: 32px;
  height: 100%;
  padding: 9px;
`
const salesWrapStyle = css`
  margin-top: 32px;
  padding: 40px 32px;
  /* White */
  background: #FFFFFF;
`
const salesSelectOverWrapStyle = css`
  margin-top: 32px;
`
const salesSelectWrapStyle = css`
  >:first-of-type {
    margin-top: 0;
  }
`
const salesSelectItemStyle = css`
  display: flex;
  margin-top: 8px;
  >:first-of-type {
    flex: 1;
  }
  >:last-of-type {
    margin-left: 10px;
  }
`
const caseStyle = css`
  width: 523px;
`
const stateStyle = css`
  width: 200px;
`