/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
// import { useNavigate } from "react-router-dom";
// import { useState } from 'react'
// import { useForm } from 'react-hook-form'
import { Text } from "../../../atoms/Text"
// import Input from "../../../atoms/Form/Input"
// import { iconStyle } from "../../../atoms/Icon";
import { colors } from "../../../../styles/_var";

type Props = {
  openToolTip: {flag:boolean}
  handleShowToolTip: (id:number) => void
  children: string
  propStyle?: SerializedStyles
}
export const SalesHeading: React.FC<Props> = ({ children, openToolTip, handleShowToolTip, propStyle }) => {
  // const {
  //   handleSubmit,
  //   getValues,
  //   register,
  // } = useForm({
  //   mode:"onChange",
  //   criteriaMode: "all",
  //   shouldFocusError: false,
  // });
  // const [focusFlag, setFocusFlag] = useState(false);
  // const onSubmit = () => {
  //   const data = getValues()
  //   console.log(data)
  // }
  // const onFocusFunc = () => {
  //   // フォーカスが当たったときの処理
  //   setFocusFlag(true)
  // }
  // const onBlurFunc = () => {
  //   // フォーカスが外れたときの処理
  //   if(getValues("sales") === "" ) {
  //     setFocusFlag(false)
  //   }
  // }
  // let navigate = useNavigate();
  return (
    <div css={[headingWrapStyle,propStyle]}>
      <div css={TtlWrapStyle} onClick={()=>handleShowToolTip(1)}>
        <Text Tag="p" color="black" size="4xl" >{children}</Text>
        {/* <div css={[iconWidthStyle,listMenuStyle(true)]} >
          {openToolTip.flag === true && (
            <div css={toolTipStyle}>
              <p css={listMenuEditStyle} onClick={() => navigate("/sales")}>営業</p>
              <p css={listMenuEditStyle} onClick={() => navigate("/client")}>営業先管理</p>
            </div>
          )}
        </div> */}
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)} css={headerFormStyle}>
        <div css={headerInputWrapStyle(focusFlag)}>
          <Input type={"text"} name={"sales"} register={register} placeholder={"営業進捗を検索"} onFocus={onFocusFunc} onBlur={onBlurFunc} propStyle={headerInputStyle(focusFlag)} />
        </div>
      </form> */}
   </div>
  )
}
const headingWrapStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.mono300};
`
const TtlWrapStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  cursor: pointer;
  &::before {
    color: ${colors.mono600};
    font-size: 38px;
    margin-left: 5px;
  }
`
// const headerFormStyle = css`
//   display: flex;
//   align-items: center;
// `
// const headerInputWrapStyle = ( isFocus: boolean ) => css`
//   margin: 0 0 0 auto;
//   width: 260px;
//   position: relative;
//   ${!isFocus ? setFoucusStyle():null}
// `
// const setFoucusStyle = () => {
//   const inputProp = css`
//   ${iconStyle('icon-Search')};
//   &::before {
//     position: absolute;
//     left: 24px;
//     top: 50%;
//     transform: translate(0, -50%);
//   }
//   `
//   return inputProp
// }
// const headerInputStyle = ( isFocus: boolean ) =>  css`
//   padding: ${!isFocus ? "12px 44px": "12px" };
//   &:focus {
//     padding: 9px;
//   }
// `

// const iconWidthStyle = css`
//   width: 100%;
//   position: absolute;
//   top: 0;
//   right: -100%;
//   z-index: 9999;
//   &::before {
//     font-size: 16px;
//     cursor: pointer;
//   }
// `
// const toolTipStyle = css`
//   border: 1px solid ${colors.mono300};
//   >:first-of-type {
//     border-bottom: 1px solid ${colors.mono300};;
//   }
// `
// const listMenuStyle = (displayMenu? :boolean) => css`
//   >div{
//     width: 120px;
//     text-align: left;
//   }
// `
// const listMenuEditStyle = css`
//   cursor: pointer;
//   padding: 12px;
//   border-radius: 2px 2px 0 0;
//   background-color: ${colors.green100};
//   /* border-left: 1px solid ${colors.mono300};
//   border-right: 1px solid ${colors.mono300}; */
// `