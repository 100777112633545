import React from "react";
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import { Global } from "@emotion/react"
import { global } from "./styles/_global"
import * as serviceWorker from "./serviceWorker";
import store from "./App/store";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById('root')as any)
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Global styles={global}/>
          <App />
        </BrowserRouter>
      </Provider>
  </React.StrictMode>
);

serviceWorker.unregister();
