import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "../../App/store";
// import { UserModel } from '../../api/model/user'
import { getAdminLoginUsers } from '../../api/login'
import { serializeError } from "serialize-error";

// type AdminLoginLoaded = {
//   login: boolean;
// }
type AdminLoginState = {
  auth: any;
  loading: boolean;
  error: any;
};

const initialState: AdminLoginState = {
  auth: "",
  loading: false,
  error: null
};

const adminLogin = createSlice({
  name: "adminLogin",
  initialState,
  reducers: {
    getAdminLoginUsersStart(state) {
      state.loading = true;
    },
    getAdminLoginUsersSuccess(state, action: PayloadAction<any>) {
      const { auth } = action.payload
      state.auth = auth;
      state.loading = false;
      state.error = null;
    },
    getAdminLoginUsersFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    }
  }
});

export const {
  getAdminLoginUsersStart,
  getAdminLoginUsersSuccess,
  getAdminLoginUsersFailure,
  resetError
} = adminLogin.actions;
export default adminLogin.reducer;

export const submitAdminLogin = ( data: any ): any => async (dispatch:any) => {
  try {
    dispatch(getAdminLoginUsersStart());
    const res = await getAdminLoginUsers(data)
    dispatch(
      getAdminLoginUsersSuccess({
        auth: res,
      })
    );
  } catch (err: any) {
    dispatch(getAdminLoginUsersFailure({ message: err.response.data.errors }));
    throw err;
  }
};