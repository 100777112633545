/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Tab } from "../../atoms/Tab"
// import { colors } from "../../../styles/_var"

type Props = {
  openTab: number
  setOpenTab: any
  list: any
  propStyle?: SerializedStyles
}
export const TabList: React.FC<Props> = ({list,openTab,setOpenTab,propStyle}) => {

  return(
    <ul css={tabListStyle}>
      {list.map((item:any,key:number)=>{
        return (
          <li key={key}>
            <Tab number={key} openTab={openTab} setOpenTab={setOpenTab} count={item.count} >{item.name}</Tab>
          </li>
        )
      })}
    </ul>
  )
}
const tabListStyle = css`
  display: flex;
`