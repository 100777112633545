/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import AdminHeader from "../../../features/user/Header";
import Header from "../../organisms/Header/User";
import RecruitHeader from "../../../features/user/Header/Recruit"
import SalesHeader from "../../../features/user/Header/Sales"
import SalesSidebarLeft from "../../organisms/Sidebar/Left/Sales";
import SalesTechSidebarLeft from "../../organisms/Sidebar/Left/SalesTech"
import SidebarLeft from "../../../features/user/Sidebar";
import SalesSidebar from "../../../features/user/Sidebar/Right/Sales";
import RecruitSidebar from "../../../features/user/Sidebar/Right/Recruit"
import ClientSidebar from "../../../features/client/Sidebar";
import SalesProfileSidebar from "../../organisms/Sidebar/Right/Sales/Profile"

import Snackbars from "../../../features/root/Snackbar";
import Error from "../../../features/root/Error";
import Loading from "../../../features/root/Loading";

import { colors } from "../../../styles/_var";

const defaultProps = {
  path: "home"
};

export type Props = {
  children: React.ReactNode;
  path: string
} & typeof defaultProps;

export default function Root(props: Props) {
  const { children,path } = props;
  console.log(path)

  const changeHeader = ( path: string ) => {
    switch(path) {
      case "/company":
        return (
          <AdminHeader />
        )
      case "/" :
        return (
          <></>
        )
      case "/adminLogin" :
        return (
          <></>
        )
      default:
        return (
          <Header />
        )
    }
  }
  const showSidebarRight = ( path: string ) => {
    switch(path) {
      case "/recruit" :
        return (
          <RecruitSidebar />
        )
      // case "/sales" :
      //   return (
      //     <SalesSidebar />
      //   )
      // case "/client" :
      //   return (
      //     <ClientSidebar />
      //   )
      case "/sales/admin/profile" :
        return (
          <SalesProfileSidebar path={path} />
        )
      case "/sales/admin/tech/skill" :
        return (
          <SalesProfileSidebar path={path} />
        )
      default:
        return (
          <></>
        )
    }
  }
  const showSidebarLeft = ( path: string ) => {
    switch(path) {
      case "/sales/admin" :
        return (
          <SalesSidebarLeft path={path} />
        )
      case "/sales/admin/operation" :
        return (
          <SalesSidebarLeft path={path} />
        )
      case "/sales/admin/profile" :
        return (
          <SalesSidebarLeft path={path} />
        )
      case "/sales/admin/tech":
        return(
          <SalesTechSidebarLeft path={path} />
        )
      case "/sales/admin/tech/skill" :
        return (
          <SalesTechSidebarLeft path={path} />
        )
      case "/sales/admin/tech/operation" :
        return (
          <SalesTechSidebarLeft path={path} />
        )
      case "/home" :
        return (
          <SidebarLeft />
        )
      case "/talent" :
        return (
          <SidebarLeft />
        )
      case "/recruit" :
        return (
          <SidebarLeft />
        )
      case "/sales" :
        return (
          <SidebarLeft />
        )
      default:
        return (
          <></>
        )
    }
  }
  return (
    <div css={overWrapStyle(path)}>
      {/* <Snackbars /> */}
      {/* <Error /> */}
      {/* <Loading /> */}
      {/* TODO: 出しわけどうしよう */}
      { changeHeader(path) }
      <div css={globalWrapStyle}>
        { showSidebarLeft(path) }
        <main css={mainStyle(path)}>{children}</main>
        {/* { showSidebarRight(path) } */}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

Root.defaultProps = defaultProps;

const overWrapStyle = (path:string) => css`
  min-height: 100vh;
  background-color: ${ path !== "/company" && colors.green100 };
`
const globalWrapStyle = css`
  display: flex;
`
const mainStyle = (path:string) => css`
  padding: 30px;
  max-width: ${ path === "/company" || path === "/recruit/detail" ? "1040px" : "880px" };
  margin: 0 auto;
  width: 100%;
`