/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
// import { Button } from "../../../../atoms/Button"
// import { Text } from "../../../../atoms/Text"
// import { iconStyle } from "../../../../atoms/Icon"
import { colors } from "../../../../../styles/_var";
import A from "../../../../atoms/A"
// import { useForm } from 'react-hook-form'

const defaultProps = {
  users: [],
  total: 1
};

type Props = {
  path: string
  children?: React.ReactNode;
} & typeof defaultProps;

export default function SalesTechSidebarLeft(props: Props) {
  const { path } = props
  // const {
  // } = useForm({
  //   mode:"onChange",
  //   criteriaMode: "all",
  //   shouldFocusError: false,
  // });

  // const onSubmit = () => {
  //   const data = getValues()
  //   console.log(data)
  // }

  return (
    <>
      <div css={sidebarWrapStyle}>
        <ul css={listStyle(path)}>
          <li css={listTtemStyle}>
            <A to="/sales/admin/tech">営業管理</A>
          </li>
          <li css={listTtemStyle}>
            <A to="/sales/admin/tech/skill">スキルシート</A>
          </li>
          <li css={listTtemStyle}>
            <A to="/sales/admin/tech/operation">稼働管理</A>
          </li>
        </ul>
      </div>
    </>
  );
}

SalesTechSidebarLeft.defaultProps = defaultProps;

const sidebarWrapStyle = css`
  width: 200px;
  border-right: 1px solid ${colors.mono300};
  min-height:100%;
`
const listStyle = (path:string) => css`
  >li{
    cursor: pointer;
  }
  >:first-of-type {
    background-color: ${path === "/sales/admin/tech" ? colors.green200 :null};
  }
  >:nth-last-of-type(2) {
    background-color: ${path === "/sales/admin/tech/skill" ? colors.green200 :null};
  }
  >:last-of-type {
    background-color: ${path === "/sales/admin/tech/operation" ? colors.green200 :null};
  }
`
const listTtemStyle = css`
  padding: 12px 20px;
`