/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form'
import { InputLabel } from "../../../molecules/Form/InputLabel"
import { SelectLabel } from "../../../molecules/Form/SelectLabel";

import { Text } from "../../../atoms/Text"
import Input from "../../../atoms/Form/Input"
import { SelectBox } from "../../../atoms/Form/Select"


const defaultProps = {
};

export type Props = {
  register: UseFormRegister<any>
  control: any
} & typeof defaultProps;

export default function SkillForm(props: Props) {
  const { register, control } = props;
  return (
    <div css={formCardWrapStyle}>
      <Text Tag="p" size="xxl" color="black">スキル・資格</Text>

      <div css={textFormWrapStyle}>
        <Text Tag="p" size="xl" color="black">エンジニアリング</Text>
        <div>
          <div css={textFormFlexStyle}>
            <InputLabel label="言語 / ツール" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
            <InputLabel label="FW / CMS ライブラリ等" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
            <InputLabel label="経験年数" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
            <SelectLabel label="レベル" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />
          </div>
          <div css={textFormFlexStyle}>
            <Input type="text" name="tel" register={register} propStyle={InputStyle} />
            <Input type="text" name="tel" register={register} propStyle={InputStyle} />
            <Input type="text" name="tel" register={register} propStyle={InputStyle} />
            <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={selectStyle} />
          </div>
          <div css={textFormFlexStyle}>
            <Input type="text" name="tel" register={register} propStyle={InputStyle} />
            <Input type="text" name="tel" register={register} propStyle={InputStyle} />
            <Input type="text" name="tel" register={register} propStyle={InputStyle} />
            <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={selectStyle} />
          </div>
        </div>
      </div>
 
      <div css={borderStyle}>
        <div css={textFormWrapStyle}>
          <Text Tag="p" size="xl" color="black">デザイン</Text>
          <div>
            <div css={textFormFlexStyle}>
              <InputLabel label="言語 / ツール" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
              <InputLabel label="FW / CMS ライブラリ等" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
              <InputLabel label="経験年数" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
              <SelectLabel label="レベル" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />
            </div>
            <div css={textFormFlexStyle}>
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={selectStyle} />
            </div>
            <div css={textFormFlexStyle}>
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={selectStyle} />
            </div>
          </div>
        </div>
      </div>

      <div css={borderStyle}>
        <div css={textFormWrapStyle}>
          <Text Tag="p" size="xl" color="black">業務環境</Text>
          <div>
            <div css={textFormFlexStyle}>
              <InputLabel label="言語 / ツール" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
              <InputLabel label="FW / CMS ライブラリ等" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
              <InputLabel label="経験年数" name="name_kama" type="text" register={register} propStyle={textFormStyle} />
              <SelectLabel label="レベル" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />
            </div>
            <div css={textFormFlexStyle}>
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={selectStyle} />
            </div>
            <div css={textFormFlexStyle}>
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <Input type="text" name="tel" register={register} propStyle={InputStyle} />
              <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={selectStyle} />
            </div>
          </div>
        </div>
      </div>

      <div css={borderStyle}>
        <div css={textFormWrapStyle}>
          <Text Tag="p" size="xl" color="black">資格</Text>
          <div>
            <div css={textFormFlexStyle}>
              <InputLabel label="資格名称" name="name_kama" type="text" register={register} />
              <SelectLabel label="取得日" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={dateSelectwidthStyle} />
            </div>
            <div css={textFormFlexStyle}>
              <Input type="text" name="tel" register={register} />
              <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={dateSelectStyle} />
            </div>
            <div css={textFormFlexStyle}>
              <Input type="text" name="tel" register={register} />
              <SelectBox options={[{id:1,name:"22222"}]} name={"name"} control={control} placeholder={"選択してください"} propStyle={dateSelectStyle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
SkillForm.defaultProps = defaultProps;

const formCardWrapStyle = css`
`
const textFormWrapStyle = css`
  margin-top: 32px;
`
const textFormFlexStyle = css`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: end;
  >:first-child {
    margin-left: 0;
  }
  >:nth-child(3) {
    width: 80px;
  }
`
const textFormStyle = css`
  width: 283px;
  margin-left: 8px;
`
const InputStyle = css`
  width: 283px;
  margin-left: 8px;
`
const selectwidthStyle = css`
  width: 80px;
  margin-left: 8px;
  > div {
    height: 48px;
  }
`
const selectStyle = css`
  width: 80px;
  margin-left: 8px;
  height: 48px;
`
const dateSelectwidthStyle = css`
  width: 136px;
  margin-left: 8px;
  > div {
    height: 48px;
  }
`
const dateSelectStyle = css`
  width: 136px;
  margin-left: 8px;
  height: 48px;
`
const borderStyle = css`
  margin-top: 32px;
  border-top: 1px solid #DDDDDD;
`