import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import LinearProgress from "@material-ui/core/LinearProgress";
import { RootState } from "../../../App/rootReducer";
import Company from "../../../components/pages/Company";
import { changeModalState } from "../../user/userSlice";
import { fetchCompany,onCreateCompany,onDeleteCompany,onChengeCompany } from "../companySlice"

type Props = {
  children?: React.ReactNode;
};

export default function CompanyWithRedux(props: Props) {
  const campanys = useSelector((state: RootState) => state.company.companys);
  const company = useSelector((state: RootState) => state.company.company)
  const auth = useSelector((state: RootState) => state.user.auth) as any
  const modalFlag = useSelector((state: RootState) => state.user.modalFlag);
  const modalType = useSelector((state: RootState) => state.user.modalType);

  const dispatch = useDispatch();

  useEffect(() => {
    async function f() {
      // 前処理
      try {
        await dispatch(fetchCompany(auth));
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch,auth]);

  const hundleCreateCompany = (data:any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onCreateCompany(data,auth))
        await dispatch(changeModalState("done", true))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  const hundleDeleteCompany = (id:any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onDeleteCompany(id,auth))
        await dispatch(changeModalState("", false,))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  const hundleChengeCompany = (data:any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onChengeCompany(data,auth))
        await dispatch(changeModalState("done", true))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  const onChangeModalState = (type: string, flag: boolean) => {
    async function f() {
      // 前処理
      try {
        await dispatch(changeModalState(type, flag))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
    <React.Fragment>
      <Company
        campanys={campanys}
        company={company}
        onCreate={(data:any) => hundleCreateCompany(data)}
        onDelete={(id:any) => hundleDeleteCompany(id)}
        onChenge={(data:any) => hundleChengeCompany(data)}
        modalState={{type:modalType,flag:modalFlag}}
        setOpenModal={onChangeModalState}
      />
    </React.Fragment>
  );
}
