import { css } from "@emotion/react"

export const fonts = css`
  @font-face {
    font-family: 'NotoSans';
    src: url('/assets/fonts/NotoSansJP.woff') format('woff');
  }
  @font-face {
    font-family: 'NotoSans';
    src: url('/assets/fonts/NotoSansJP-Bold.woff') format('woff');
    font-weight: bold;
  }

  @font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot?m7926d');
    src: url('/assets/fonts/icomoon.eot?m7926d#iefix') format('embedded-opentype'),
      url('/assets/fonts/icomoon.ttf?m7926d') format('truetype'),
      url('/assets/fonts/icomoon.woff?m7926d') format('woff'),
      url('/assets/fonts/icomoon.svg?m7926d#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  body {
    font-family: 'NotoSans';
  }

.icon-A:before {
  content: "\e900";
}
.icon-Arrow_Bottom_B:before {
  content: "\e901";
}
.icon-Arrow_Bottom_L:before {
  content: "\e902";
}
.icon-Arrow_Left_L:before {
  content: "\e903";
}
.icon-Arrow_Right_B:before {
  content: "\e904";
}
.icon-Arrow_Right_L:before {
  content: "\e905";
}
.icon-Arrow_Top_B:before {
  content: "\e906";
}
.icon-Arrow_Top_L:before {
  content: "\e907";
}
.icon-Cellphone:before {
  content: "\e908";
}
.icon-Check_circle:before {
  content: "\e909";
}
.icon-Check:before {
  content: "\e90a";
}
.icon-Circle_o:before {
  content: "\e90b";
}
.icon-Cross:before {
  content: "\e90c";
}
.icon-Dots:before {
  content: "\e90d";
}
.icon-eye_none:before {
  content: "\e90e";
}
.icon-eye:before {
  content: "\e90f";
}
.icon-Heart:before {
  content: "\e910";
}
.icon-help:before {
  content: "\e911";
}
.icon-lock:before {
  content: "\e912";
}
.icon-Mail:before {
  content: "\e913";
}
.icon-Minus:before {
  content: "\e914";
}
.icon-Off:before {
  content: "\e915";
}
.icon-On:before {
  content: "\e916";
}
.icon-plus_circle:before {
  content: "\e917";
}
.icon-Plus:before {
  content: "\e918";
}
.icon-Q:before {
  content: "\e919";
}
.icon-Search:before {
  content: "\e91a";
}
.icon-Square_R:before {
  content: "\e91b";
}
.icon-Unlock:before {
  content: "\e91c";
}
.icon-upload:before {
  content: "\e91d";
}
.icon-window:before {
  content: "\e91e";
}
`