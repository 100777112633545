import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "../../App/store";
// import { UserModel } from '../../api/model/user'
import { getCompanys,createCompany,deleteCompany,chengeCompany } from '../../api/company'
import { serializeError } from "serialize-error";

type CompanyState = {
  companys: any
  company: any
  loading: boolean;
  error: any;
};

const initialState: CompanyState = {
  companys: [
    {id: 1, name: "", user: {loginid: ""}}
  ],
  company: {
    id: "aaa",
    pass: "aaa"
  },
  loading: false,
  error: null
};

const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    getCompanysStart(state) {
      state.loading = true;
    },
    getCompanysSuccess(state, action: PayloadAction<any>) {
      const { companys } = action.payload
      state.companys = companys;
      state.loading = false;
      state.error = null;
    },
    getCompanysFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    createCompanyStart(state) {
      state.loading = true;
    },
    createCompanySuccess(state, action: PayloadAction<any>) {
      const { companys } = action.payload
      state.companys = companys;
      state.loading = false;
      state.error = null;
    },
    createCompanyFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    deleteCompanyStart(state) {
      state.loading = true;
    },
    deleteCompanySuccess(state, action: PayloadAction<any>) {
      const { companys } = action.payload
      state.companys = companys;
      state.loading = false;
      state.error = null;
    },
    deleteCompanyFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    chengeCompanyStart(state) {
      state.loading = true;
    },
    chengeCompanySuccess(state, action: PayloadAction<any>) {
      const { companys } = action.payload
      state.companys = companys;
      state.loading = false;
      state.error = null;
    },
    chengeCompanyFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    }
  }
});

export const {
  getCompanysStart,
  getCompanysSuccess,
  getCompanysFailure,
  createCompanyStart,
  createCompanySuccess,
  createCompanyFailure,
  deleteCompanyStart,
  deleteCompanySuccess,
  deleteCompanyFailure,
  chengeCompanyStart,
  chengeCompanySuccess,
  chengeCompanyFailure,
  resetError
} = company.actions;
export default company.reducer;

export const fetchCompany = ( auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(getCompanysStart());
    const res = await getCompanys(auth)
    dispatch(
      getCompanysSuccess({
        companys: res.data.data,
      })
    );
  } catch (err: any) {
    dispatch(getCompanysFailure({ message: err.response.data.errors }));
    throw err;
  }
};

export const onCreateCompany = ( data:any,auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(createCompanyStart());
    const res = await createCompany(data,auth)
    dispatch(
      createCompanySuccess({
        companys: res.data.data,
      })
    );
  } catch (err: any) {
    dispatch(createCompanyFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onDeleteCompany = ( id:any,auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(deleteCompanyStart());
    const res = await deleteCompany(id,auth)
    dispatch(
      deleteCompanySuccess({
        companys: res.data.data,
      })
    );
  } catch (err: any) {
    dispatch(deleteCompanyFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onChengeCompany = ( data:any,auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(chengeCompanyStart());
    const res = await chengeCompany(data,auth)
    dispatch(
      chengeCompanySuccess({
        companys: res.data.data,
      })
    );
  } catch (err: any) {
    dispatch(chengeCompanyFailure({ message: err.response.data.errors }));
    throw err;
  }
};