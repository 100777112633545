/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../Text"
import { colors } from "../../../styles/_var"

type Props = {
  openTab: number
  setOpenTab: any
  number: number
  count: string
  children: string
  propStyle?: SerializedStyles
}
export const Tab: React.FC<Props> = ({openTab,setOpenTab,number,count,children,propStyle}) => {
  return(
    <div css={[(openTab === number ? activeStyle :null),tagStyle, propStyle]} onClick={()=>setOpenTab(number)}>
      <Text Tag="p" color={"gray"} size="s" >{children}</Text>
      <Text Tag="p" color={"gray"} size="s" >{count}</Text>
    </div>

  )
}
const tagStyle =  css`
  display: flex;
  padding: 13px;
  border-radius: 4px;
  >:last-of-type {
    margin-left: 5px;
  }
`
const activeStyle = css`
  background-color: ${colors.green300};
  cursor: pointer;
  > P {
    font-weight: bold;
    color: ${colors.black}
  }
`