import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom"
import { RootState } from "../../../../App/rootReducer";
import { fetchRecruitUserDetail,onPatchStepForm,openSnackbar } from "../../recruitSlice"
import { changeModalState } from "../../../user/userSlice";

import RecruitDetail from "../../../../components/pages/Recruit/Detail";

type Props = {
  children?: React.ReactNode;
};

export default function RecruitDetailWithRedux(props: Props) {
  // TODO: as any良くない
  const userDetail = useSelector((state: RootState) => state.recruit.recruitUserDetail) as any;
  const status = useSelector((state: RootState) => state.recruit.status) as any
  const statusDisplay = useSelector((state: RootState) => state.recruit.statusDisplay) as any
  const statusNum = useSelector((state: RootState) => state.recruit.statusNum) as any
  const check_items = useSelector((state: RootState) => state.recruit.check_items) as any
  const auth = useSelector((state: RootState) => state.user.auth) as any
  const modalFlag = useSelector((state: RootState) => state.user.modalFlag);
  const modalType = useSelector((state: RootState) => state.user.modalType);
  const location = useLocation()
  const selectId: string = location.state as string
  const dispatch = useDispatch();
  
  useEffect(() => {
    async function f() {
      // 前処理
      try {
        dispatch(fetchRecruitUserDetail(selectId,auth));
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch,selectId,auth]);

  const hundleSendStepForm = (data:any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onPatchStepForm(userDetail.id,userDetail.status,data,auth))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  const onChangeModalState = (type: string, flag: boolean) => {
    async function f() {
      // 前処理
      try {
        await dispatch(changeModalState(type, flag))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  const onOpenSnackbar = (state:string) => {
    async function f() {
      // 前処理
      try {
        await dispatch(openSnackbar(state))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  

  return (
      <RecruitDetail
        userDetail={userDetail}
        status={status}
        statusDisplay={statusDisplay}
        statusNum={statusNum}
        check_items={check_items}
        onSendStepForm={hundleSendStepForm}
        modalState={{type:modalType,flag:modalFlag}}
        setOpenModal={onChangeModalState}
        setOpenSnackbar={onOpenSnackbar}
      />
  );
}
