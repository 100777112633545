/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { IconString } from "../../../types"
export const iconStyle = (type:IconString) => css`
  font-family: 'icomoon' !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::before {
    content: "${typeCheck(type)}"
  }
`
const typeCheck = (type: IconString):string => {
  switch (type) {
    case "icon-A":
      return "\\e900"
    case "icon-Icon_state_on":
        return "\\e901"
    case "icon-Cellphone":
        return "\\e900"
    case "icon-help":
        return "\\e91d"
    case "icon-AA":
        return "\\e907"
    case "icon-Arrow_Bottom_B":
        return "\\e908"
    case "icon-Arrow_Right_B":
        return "\\e909"
    case "icon-arrow_tin_bottom":
        return "\\e90a"
    case "icon-arrow_tin_left":
        return "\\e90b"
    case "icon-arrow_tin_right":
        return "\\e90c"
    case "icon-arrow_tin_top":
        return "\\e90d"
    case "icon-Arrow_Top_B":
        return "\\e90e"
    case "icon-arrowtriangle_tin_Bottom":
        return "\\e90f"
    case "icon-arrowtriangle_tin_Left":
        return "\\e910"
    case "icon-arrowtriangle_tin_Right":
        return "\\e911"
    case "icon-arrowtriangle_tin_Top":
        return "\\e912"
    case "icon-check_state_off":
        return "\\e913"
    case "icon-check_state_on":
        return "\\e914"
    case "icon-Check":
        return "\\e915"
    case "icon-Circle_o":
        return "\\e916"
    case "icon-Cross":
        return "\\e917"
    case "icon-Dots":
        return "\\e918"
    case "icon-eye_none":
        return "\\e919"
    case "icon-eye":
        return "\\e91a"
    case "icon-filter":
        return "\\e91b"
    case "icon-Heart":
        return "\\e91c"
    case "icon-Icon_state_off":
        return "\\e91e"
    case "icon-lock":
        return "\\e91f"
    case "icon-Mail":
        return "\\e920"
    case "icon-Minus":
        return "\\e921"
    case "icon-Off":
        return "\\e922"
    case "icon-On":
        return "\\e923"
    case "icon-Pencil":
        return "\\e924"
    case "icon-plus_circle":
        return "\\e925"
    case "icon-Plus":
        return "\\e926"
    case "icon-Q":
        return "\\e927"
    case "icon-Search":
        return "\\e928"
    case "icon-slash":
        return "\\e929"
    case "icon-Square_R":
        return "\\e92a"
    case "icon-triangle_bottom":
        return "\\e92b"
    case "icon-triangle_top":
        return "\\e92c"
    case "icon-Unlock":
        return "\\e92d"
    case "icon-upload":
        return "\\e92e"
    case "icon-window":
        return "\\e92f"
      
    default:
      return "no option"
  }
}