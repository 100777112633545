import { apiClient } from "./client";

/**
 * API 定義
 * USER
 *
 */

export async function getTalentDetail(id:string,auth:any,users:any) {
  const talents:any = users
  const talent:any = talents[Number(id)-1]
  const talentId:any = talents[Number(id)-1].id

  const newTalent = {
    id:talentId,
    last_name: talent.last_name,
    first_name: talent.first_name,
    last_name_kana: talent.last_name_kana,
    first_name_kana: talent.first_name_kana,
    parent_status: talent.parent_status,
    agent:{
      name:talent.agent.name
    },
    recruit: {
      front_status: talent.recruit.front_status
    },
    start_date:"",
    preferred_date:"",
    final_proposal:"",
    state:"",
    fee:"",
    update:"",
    age:"20",
    gender:{
      name:talent.sex
    },
    prefecture:{
      name:"愛媛県"
    }
  }
  console.log(newTalent)
  return newTalent
}

export async function chengeUserDetail(id:any,data:any,auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.patch<any>(`/employees/${id}`,data,config);
}