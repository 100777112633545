import React, { useState } from 'react';

const styles = {
  educationDetail: {
    fontFamily: 'Arial, sans-serif',
    // maxWidth: '800px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    backgroundColor: '#fff',
    borderBottom: '1px solid #ddd',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    color: '#666',
  },
  content: {
    padding: '24px',
    // backgroundColor: '#fff',
    // borderBottomLeftRadius: '8px',
    // borderBottomRightRadius: '8px',
  },
  studentWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  studentInfo: {

  },
  studentName: {
    fontSize: '24px',
    fontWeight: '400',
    letterSpacing: '0.05em',
  },
  studentFurigana: {
    color: '#666',
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '24px',
  },
  progressBar: {
    backgroundColor: '#eee',
    width: '200px',
    height: '8px',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  progress: {
    backgroundColor: '#4caf50',
    height: '100%',
    width: '10px',
  },
  progressText: {
    textAlign: 'right' as const,
    fontSize: '14px',
    marginTop: '4px',
  },
  section: {
    margin: '24px 0',
    padding: '16px',
    backgroundColor: '#fff',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '12px',
  },
  lessonRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  lessonPrefix: {
    width: '120px',
    fontWeight: 'bold',
  },
  lessonItem: {
    display: 'flex',
    flexDirection: 'column' as const,
    marginRight: '20px',
  },
  lessonInputWrap: {
    // display: 'flex',
    width: '320px',
    alignItems: 'center',
    gap: '8px',
    marginTop: '4px',
    border: '1px solid #ddd',
  },
  lessonLabel: {
    fontSize: '12px',
    fontWeight: '400',
    letterSpacing: '0.05em',
    borderBottom: '1px solid #ddd',
    padding: '10px',
  },
  lessonInputs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  dateInput: {
    fontSize: '12px',
    padding: '4px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  checkbox: {
    width: '16px',
    height: '16px',
  },
  footer: {
    padding: '24px',
    backgroundColor: '#fff',
    borderTop: '1px solid #ddd',
    textAlign: 'center' as const,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  updateButton: {
    backgroundColor: '#4caf50',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  footerText: {
    marginTop: '12px',
    fontSize: '14px',
    color: '#666',
  },
};

export default function Detail() {
  const [selectedDate, setSelectedDate] = useState('2020-12-01');
  const [checkedLessons, setCheckedLessons] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (lessonId: string) => {
    setCheckedLessons(prev => ({
      ...prev,
      [lessonId]: !prev[lessonId]
    }));
  };

  const renderLessonRow = (prefix: string, lessons: string[]) => {
    return (
      <div style={styles.lessonRow}>
        <div style={styles.lessonPrefix}>{prefix}</div>
        {lessons.map((lesson, index) => {
          const lessonId = `${prefix}-${lesson}-${index}`;
          return (
            <div key={lessonId} style={styles.lessonInputWrap}>
              <div style={{ color: checkedLessons[lessonId] ? 'orange' : 'initial' }}>{lesson}</div>
              <div style={styles.lessonInputs}>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  style={styles.dateInput}
                />
                <input 
                  type="checkbox" 
                  checked={checkedLessons[lessonId] || false}
                  onChange={() => handleCheckboxChange(lessonId)}
                  style={{
                    ...styles.checkbox,
                    accentColor: checkedLessons[lessonId] ? 'orange' : 'initial'
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={styles.educationDetail}>
      {/* <div style={styles.header}>
        <h2 style={styles.headerTitle}>教育詳細</h2>
        <button style={styles.closeButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={styles.closeIcon}>
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div> */}
      
      <div style={styles.content}>
        <div style={styles.studentWrap}>
          <div style={styles.studentInfo}>
            <div>No.1039</div>
            <div style={styles.studentName}>阿部サラ</div>
            <div style={styles.studentFurigana}>アベ サラ</div>
          </div>
          
          <div style={styles.progressContainer}>
            <div style={styles.progressBar}>
              <div style={styles.progress}></div>
            </div>
            <div style={styles.progressText}>3/30</div>
          </div>
        </div>
        
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>導入</h3>
          <div style={styles.lessonInputWrap}>
            <div style={styles.lessonLabel}>
              <label>導入</label>
            </div>
            <div style={styles.lessonInputs}>
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                style={styles.dateInput}
              />
              <input
                type="checkbox" 
                checked={checkedLessons['introduction'] || false}
                onChange={() => handleCheckboxChange('introduction')}
                style={{
                  ...styles.checkbox,
                  accentColor: checkedLessons['introduction'] ? 'orange' : 'initial'
                }}
              />
            </div>
          </div>
        </div>
        
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>初級</h3>
          {renderLessonRow('div-puzzle-pc', ['lesson1', 'lesson2', 'lesson3', 'lesson4'])}
          {renderLessonRow('div-puzzle-sp', ['lesson1', 'lesson2'])}
          {renderLessonRow('pc-site', ['pc-site'])}
          {renderLessonRow('responsive-site', ['pc-site'])}
        </div>
        
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>中級</h3>
          {renderLessonRow('div-puzzle-pc', ['lesson1', 'lesson2', 'lesson3', 'lesson4'])}
          {renderLessonRow('div-puzzle-sp', ['lesson1', 'lesson2'])}
          {renderLessonRow('pc-site', ['pc-site'])}
          {renderLessonRow('responsive-site', ['pc-site'])}
        </div>
        
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>上級</h3>
          {renderLessonRow('div-puzzle-pc', ['lesson1', 'lesson2', 'lesson3', 'lesson4'])}
          {renderLessonRow('div-puzzle-sp', ['lesson1', 'lesson2'])}
          {renderLessonRow('pc-site', ['pc-site'])}
          {renderLessonRow('responsive-site', ['pc-site'])}
        </div>
      </div>
      
      <div style={styles.footer}>
        <button style={styles.updateButton}>
          カリキュラムを更新する
        </button>
        <p style={styles.footerText}>
          カリキュラムを更新するとステータスが教育中の受講生に変更されます。
        </p>
      </div>
    </div>
  );
}
