export const colors = {
  white: "#fff",
  black: "#333333",
  mono100: "#f5f5f5",
  mono200: "#EEEEEE",
  mono300: "#DDDDDD",
  mono350: "#D9D9D9",
  mono400: "#CCCCCC",
  mono500: "#999999",
  mono600: "#666666",
  mono800: "#444444",
  mono900: "#222222",
  blue500: "#1A67DC",
  green100: "#F8F9FD",
  green200: "#DFF6FD",
  green300: "#C2EAF6",
  green400: "#AED6E2",
  green500: "#3187A2",
  green600: "#1A6A82",
  green700: "#064558",
  orange100: "#FFEFE9",
  orange200: "#FFC7B0",
  orange300: "#FFB39C",
  orange400: "#FF9F88",
  orange500: "#FF7760",
  orange600: "#C33B24",
  orange700: "#AA2711",
  pink600: "#DD1539",
  linkDefault:"#0DADC2",
  transparent: "transparent"
}
export const tagColors = {
  defaultColor: colors.green500,
  defaultBgColor: colors.green500,
  defaultBorderColor: colors.green500,
  workColor: colors.orange500,
  workBgColor: colors.orange500,
  workBorderColor: colors.orange500,
}
export const formColors = {
  bgColor: colors.green100,
  borderColor: colors.mono400,
  placeholderColor: colors.mono600,
  errorColor: colors.pink600
}
export const buttonColors = {
  primaryColor: colors.orange500,
  borderColor: colors.orange600,
  primaryDisableColor: colors.green200,
  secondaryColor: colors.green700,
  secondaryBorderColor: colors.mono300,
  tertiaryColor: colors.mono300,
  tertiaryBorderColor: colors.mono300,
  quaternaryBorderColor: colors.mono600,
  quinaryBorderColor: colors.mono400,
  white: colors.white,
  transparent:colors.transparent,
  tertiaryFontColor: colors.mono500,
  quaternaryFontColor: colors.mono600,
  senaryColor: colors.green200,
  senaryBorderColor: colors.green400,
  senaryFontColor: colors.mono900,

}
export const zIndexes = {
  normal: 1,
  menu: 5,
  modal: 10,
  loader: 15
}

export const breakPoints = {
  pc: 768,
}
export const mq = {
  "u-sp": `@media (max-width: ${breakPoints.pc}px)`,
  "o-sp": `@media (min-width: ${breakPoints.pc + 1}px)`
}