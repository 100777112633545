/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { useForm } from "react-hook-form";
// import { SalesHeading } from "../../../molecules/Heading/Sales";
import { InputLabel } from "../../molecules/Form/InputLabel";
// import { SelectLabel } from "../../../molecules/Form/SelectLabel";
// import { TextareaLabel } from "../../../molecules/Form/TextareaLabel";

// import Input from "../../../atoms/Form/Input"
import { Text } from "../../atoms/Text"
import { colors } from "../../../styles/_var";
// import Radio from "../../../atoms/Form/radio"
import { Button } from "../../atoms/Button"
import { AccountIcon } from "../../atoms/AccountIcon";
// import { iconStyle } from "../../atoms/Icon";


const defaultProps = {};

type Props = {
  // users:[
  //   {
  //     name_kana: string;
  //     name_name: string,
  //     kana_kana: string,
  //     kana_name: string,
  //     no: string;
  //     case: []
  //   }
  // ]
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Setting(props: Props) {
  const {
    register
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  // // const onSubmit = () => {
  // //   const data = getValues()
  //   // console.log(props)
  // // }
  // const submit = () => {
  //   console.log("tab")
  // }

  return (
    <>
      <div css={settingWrapStyle}>
        <Text Tag="p" color="black" size="4xl">基本情報の編集</Text>
        <div css={accountIconWrapStyle}>
          <div>
            <Text Tag="p" color="black" size="m" isBold={true}>アイコン</Text>
            <AccountIcon name={"icon"} size={"l"} src={"https://placehold.jp/100x100.png"} propStyle={accountIconStyle} />
          </div>
          <div css={buttonWrapStyle}>
            <Button Tag="button" type="quaternary" size="s" propStyle={uploadButtonWrapStyle}>新しいアイコンをアップロード</Button>
            <Button Tag="button" type="tertiary" size="s" propStyle={deleteButtonWrapStyle}>削除</Button>
          </div>
        </div>
        <InputLabel label="社名" name="name_kama" type="text" register={register} propStyle={companyInputStyle} />
        <div css={submitButtonWrapStyle}>
          <Button Tag="button" type="primary" size="l" propStyle={submitButtonStyle}>内容を保存</Button>
        </div>
      </div>
    </>
  );
}

Setting.defaultProps = defaultProps;

const settingWrapStyle = css`
  padding: 40px 32px;
  background-color: ${colors.white};
`

const accountIconWrapStyle = css`
  display: flex;
  align-items: center;
  margin-top: 32px;
`
const accountIconStyle = css`
  margin-top: 5px;
`
const buttonWrapStyle = css`
  margin-left: 24px;
  padding-top: 15px;
`
const uploadButtonWrapStyle = css`
  width: 222px;
  height: 32px;
  padding: 5px;
`
const deleteButtonWrapStyle = css`
  margin-top: 12px;
  width: 70px;
  height: 32px;
  padding: 5px;
`
const companyInputStyle = css`
  margin-top: 32px;
`
const submitButtonWrapStyle = css`
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #DDDDDD;
`
const submitButtonStyle = css`
  width: 180px;
  margin-left: auto;
`