/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../../atoms/Text"
import { colors } from "../../../../../styles/_var"

type Props = {
  propStyle?: SerializedStyles
}
export const CompanyLabel: React.FC<Props> = ({ propStyle }) => {
  return (
    <div css={[labelStyle, propStyle]}>
      <Text Tag="p" color="gray" size="s" isBold propStyle={nameWidthStyle}>社名</Text>
      <Text Tag="p" color="gray" size="s" isBold propStyle={idWidthStyle}>ID</Text>
      <Text Tag="p" color="gray" size="s" isBold propStyle={passwordWidthStyle}> </Text>
      <Text Tag="p" color="gray" size="s" isBold propStyle={optionWidthStyle}>管理</Text>
    </div>
  )
}

const labelStyle = css`
  margin-top: 40px;
  display: flex;
  padding: 14px;
  border-bottom: 1px solid #e3e3e3;
  background-color: ${colors.green100};
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
`
const idWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
`
const passwordWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
`
const optionWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
  text-align: right;
`