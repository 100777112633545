/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form'
import { Text } from "../../../atoms/Text"
import { Textarea } from "../../../atoms/Form/Textarea"


const defaultProps = {
};

export type Props = {
  register: UseFormRegister<any>
  control: any
} & typeof defaultProps;

export default function PrForm(props: Props) {
  // const { } = props;
  return (
    <div css={formCardWrapStyle}>
      <Text Tag="p" size="xxl" color="black">自己PR</Text>

      <div css={textFormWrapStyle}>
        <Textarea name={""} placeholder={""} />
      </div>
    </div>
  );
}
PrForm.defaultProps = defaultProps;

const formCardWrapStyle = css`
`
const textFormWrapStyle = css`
  margin-top: 32px;
`