/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { colors } from "../../../styles/_var"
type Color = "black" | "gray" | "red" | "green" | "orange" | "orange300" | "link"
type Size = "s" | "m" | "l" | "xl" | "xxl" | "xxxl" | "4xl"
type Tags = "p" | "dt" | "dd"
type Props = {
  Tag: Tags
  color: Color
  size: Size
  isAlpha?: boolean
  isBold?: boolean
  children: string
  propStyle?: SerializedStyles
}
export const Text: React.FC<Props> = ({
  Tag,
  color,
  size,
  isAlpha,
  isBold,
  children,
  propStyle,
  ...rest
}) => {
  return <Tag css={[textStyle(color, size, isAlpha, isBold ), propStyle]} {...rest}>{children}</Tag>
}
const textStyle = (
  color: Color,
  size: Size,
  isAlpha?: boolean,
  isBold?: boolean,
) => css`
  color: ${fontColor(color)};
  font-family: ${isAlpha ? "Roboto" : "NotoSans"};
  font-size: ${fontSize(size, isAlpha)};
  font-weight: ${isBold ? "bold" : "normal"};
  letter-spacing: 0.5px;
`
const fontSize = (size: Size, isAlpha?: boolean): string => {
  switch (size) {
    case "s":
      return isAlpha ? "11px" : "12px"
    case "m":
      return isAlpha ? "14px" : "13px"
    case "l":
      return isAlpha ? "16px" : "16px"
    case "xl":
      return isAlpha ? "20px" : "18px"
    case "xxl":
      return isAlpha ? "22px" : "24px"
    case "xxxl":
      return isAlpha ? "32px" : "32px"
    case "4xl":
      return isAlpha ? "40px" : "40px"
    default:
      return "14px"
  }
}
const fontColor = (color: Color): string => {
  switch (color) {
    case "black":
      return colors.black
    case "gray":
      return colors.mono500
    case "red":
      return colors.orange700
    case "green":
      return colors.green500
    case "orange":
      return colors.orange500
      case "orange300":
        return colors.orange300
      case "link":
        return colors.linkDefault
    default:
      return colors.black
  }
}
