/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Text } from "../../../../atoms/Text"
import { colors } from "../../../../../styles/_var";

type Props = {
  item: any
  clickClientData: any
}
export const ClientList: React.FC<Props> = ({ item, clickClientData }) => {
  const LinkToUserDetailPage = (item:any) => {
    clickClientData(item)
  };
  return (
    <div css={userListInnerStyle} onClick={()=>LinkToUserDetailPage(item)}>
      <Text Tag="p" color="black" size="l" propStyle={nameWidthStyle}>{item.name}</Text>
      <Text Tag="p" color="black" size="l" propStyle={idWidthStyle}>{item.sale_topics[0].name}</Text>
      <Text Tag="p" color="black" size="l" propStyle={stateWidthStyle}>{item.topic_count}</Text>
    </div>
  )
}
const userListInnerStyle = css`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  border-bottom: 1px solid ${colors.mono300};
  cursor: pointer;
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 320px;
  min-width: 200px;
  width: 100%;
`
const idWidthStyle = css`
  flex: 1;
  max-width: 424px;
  min-width: 200px;
  width: 100%;
`
const stateWidthStyle = css`
  flex: 1;
  max-width: 72px;
  min-width: 200px;
  width: 100%;
  text-align: right;
`