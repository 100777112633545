/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form';
import { Modal } from "../../../atoms/Modal"
import { InputLabel } from "../../../molecules/Form/InputLabel"
import { Button } from "../../../atoms/Button"
import { Text } from "../../../atoms/Text"
import { colors } from "../../../../styles/_var"
import { ErrorMessage } from "../../../atoms/ErrorMessage"


export type ModalProps = {
  open: { type: string, flag: boolean };
  hundleCreate: any
  handleSubmit: any
  onClose: () => void;
  register: UseFormRegister<any>
  isValid: any
  errors: any
};

export default function CompanyModal(props: ModalProps) {
  const { onClose, open, hundleCreate, register, handleSubmit, isValid, errors } = props;
  const modaiWith = (type: string) => {
    switch (type) {
      case "other":
        return "400"
      default :
        return "400" 
    }
  }
  const modalText = (type:string) => {
    switch(type) {
      case "other":
        return ""
    }
  }

  return (
    <>
      {open.type === "other" ? (
        <Modal width={modaiWith(open.type)} isCenter={false} open={open.flag} onClose={onClose}>
          <div css={modalTitleStyle}>{modalText(open.type)}</div>
          <div css={modalContentStyle(open.type)}>
              <form css={modalContentInnerStyle} onSubmit={handleSubmit(hundleCreate)}>
                <div css={textWrap}>
                  <Text Tag="p" color="black" size="xl" >流入経路にその他が選択されました</Text>
                  <Text Tag="p" color="black" size="l" >流入経路を教えてください</Text>
                  <InputLabel label={""} type={"text"} name={"company_id"} register={register} rules={{required: true}} isError={typeof errors.company_id !== "undefined"} ></InputLabel>
                  {errors.company_id && <ErrorMessage>文字が入力されていません</ErrorMessage>}
                </div>
                <div css={modalBtnWrapStyle}>
                  <Button Tag={"button"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} >送信</Button>
                </div>
              </form>
          </div>
        </Modal>
      ):null}
    </>
  );
}

const modalTitleStyle = css`
  padding: 28px 32px;
  border-bottom: 1px solid ${colors.mono300};
`
const modalContentStyle = (type: string) => css`
  ${setModalProperties(type)};
`
const setModalProperties = (type: string) => {
  const modalProp = {
    padding: "",
    backgroundColor: "",
    color: "",
    border: "",
    boxShadow: ""
  }
  switch (type) {
    case "other":
      modalProp["padding"] = "20px 40px"
    return modalProp
  }
}
const modalContentInnerStyle = css`
  >div {
    margin-top: 32px;
  }
  >:first-of-type{
    margin-top: 0;
  }
`
const modalBtnWrapStyle = css`
  padding-top: 32px;
  border-top: 1px solid ${colors.mono300};;
  display: flex;
  justify-content: space-between;
`
const primaryBtnStyle = css`
  max-width: 121px;
`
const textWrap = css`
  text-align: center;
`