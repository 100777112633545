import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../App/rootReducer";
import { fetchClient,onClickClientData } from "../../client/clientSlice";
import Client from "../../../components/pages/Client";

type Props = {
  children?: React.ReactNode;
};

export default function ClientWithRedux(props: Props) {
  // TODO: as any良くない
  const clients = useSelector((state: RootState) => state.client.clients) as any;
  const auth = useSelector((state: RootState) => state.user.auth) as any
  const dispatch = useDispatch();

  useEffect(() => {
    async function f() {
      // 前処理
      try {
        dispatch(fetchClient(auth));
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch,auth]);

  const clickClientData = (data: any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onClickClientData(data))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
      <Client clients={clients} clickClientData={clickClientData}/>
  );
}
