import React from 'react';
import A from '../../atoms/A';

export default function BackOffice() {
  const styles = {
    container: {
      fontFamily: 'Helvetica, Arial, sans-serif',
      display: 'flex',
      flexDirection: 'column' as const,
      height: '100vh',
      color: '#333',
      backgroundColor: '#f5f5f5',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#fff',
      borderBottom: '1px solid #e0e0e0',
    },
    logo: {
      fontWeight: 'bold',
      fontSize: '20px',
      color: '#4a90e2',
    },
    headerNav: {
      display: 'flex',
      gap: '20px',
    },
    headerNavItem: {
      textDecoration: 'none',
      color: '#333',
      fontSize: '14px',
    },
    mainMenu: {
      display: 'flex',
      maxWidth: "1040px",
      borderBottom: '1px solid #e0e0e0',
    },
    mainMenuItem: {
      padding: '10px 20px',
      fontSize: '14px',
      color: '#333',
      cursor: 'pointer',
    },
    activeMenuItem: {
      borderBottom: '2px solid #4a90e2',
      fontWeight: 'bold',
    },
    content: {
      display: 'flex',
      flex: 1,
    },
    mainContent: {
      flex: 1,
      backgroundColor: '#fff',
      marginTop: '40px',
      marginRight: "40px",
      overflowY: 'auto' as const,
    },
    meetingHeader: {
      display: 'flex',
      padding: '20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      paddingBottom: '20px',
      borderBottom: '1px solid #e0e0e0',
    },
    meetingContent: {
      padding: '0px 20px 20px 20px',
    },
    meetingDate: {
      fontSize: '14px',
      color: '#666',
    },
    attendees: {
      display: 'flex',
    },
    attendeeAvatar: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#e0e0e0',
      marginLeft: '5px',
      fontSize: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#666',
    },
    addAttendee: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      border: '1px solid #e0e0e0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      color: '#666',
      backgroundColor: '#fff',
    },
    sectionTitle: {
      fontSize: '24px',
      marginBottom: '20px',
      marginTop: '20px',
    },
    sectionContent: {
      marginBottom: '20px',
      borderBottom: '1px solid #e0e0e0',
    },
    paragraph: {
      fontSize: '14px',
      lineHeight: '1.5',
      marginBottom: '40px',
    },
    list: {
      paddingLeft: '20px',
      marginBottom: '40px',
      fontSize: '14px',
      lineHeight: '1.5',
    },
    listItem: {
      marginBottom: '5px',
    },
    sidebar: {
      width: '200px',
      marginTop: '40px',
      backgroundColor: '#fff',
    },
    sidebarContent: {
      padding: '20px',
    },
    sidebarTitle: {
      padding: '20px',
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '10px',
      borderBottom: '1px solid #e0e0e0',
    },
    sidebarList: {
      listStyle: 'none',
      padding: '20px',
      margin: 0,
    },
    sidebarListTitle: {
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    sidebarListItem: {
      fontSize: '14px',
      marginBottom: '5px',
    },
  }

  return (
    <div>
      <nav style={styles.mainMenu}>
        <div style={{...styles.mainMenuItem, ...styles.activeMenuItem}}>議事録</div>
        <div style={styles.mainMenuItem}>
          <A to="/backoffice/employees">雇用</A>
        </div>
      </nav>
      <div style={styles.content}>
        <main style={styles.mainContent}>
          <div style={styles.meetingHeader}>
            <span style={styles.meetingDate}>2024/02/15 15:00</span>
            <div style={styles.attendees}>
              {['A', 'B', 'C', 'D', 'E'].map((attendee, index) => (
                <div key={index} style={styles.attendeeAvatar}>{attendee}</div>
              ))}
              <button style={styles.addAttendee}>+</button>
            </div>
          </div>
          <div style={styles.meetingContent}>
            <h2 style={styles.sectionTitle}>目的</h2>
            <p style={styles.paragraph}>離職率を10%以下にする</p>
            <div style={styles.sectionContent}>
              <h2 style={styles.sectionTitle}>アジェンダ</h2>
              <ul style={styles.list}>
                <li style={styles.listItem}>・現状の把握</li>
                <li style={styles.listItem}>・課題 / 対策</li>
                <li style={styles.listItem}>
                  ・タスク
                  <ul style={styles.list}>
                    <li style={styles.listItem}>※必ず期日 / 担当者の記載を。</li>
                  </ul>
                </li>
              </ul>
            </div>
            <h2 style={styles.sectionTitle}>議事録</h2>
            <p style={styles.paragraph}>表現方法や数字のチェックはガントチャートに取り入れて確認できるようにする。</p>
            <p style={styles.paragraph}>内容資料は各事業部担当者が個別に作って柄本さんがマージ。</p>
            <p style={styles.paragraph}>最終チェックは砂川さん、井戸さん。</p>
            <h2 style={styles.sectionTitle}>▼下記ご覧ください</h2>
            <ul style={styles.list}>
              <li style={styles.listItem}>・現状の把握</li>
              <li style={styles.listItem}>・課題 / 対策</li>
              <li style={styles.listItem}>・タスク</li>
            </ul>
            <h2 style={styles.sectionTitle}>▼タスク一覧</h2>
            <ol style={styles.list}>
              <li style={styles.listItem}>
                ・スケジュール出し
                <ul style={styles.list}>
                  <li style={styles.listItem}>・担当：石原</li>
                  <li style={styles.listItem}>・期日：01/10</li>
                </ul>
              </li>
              <li style={styles.listItem}>
                ・メンバーアポ
                <ul style={styles.list}>
                  <li style={styles.listItem}>・担当：柄本</li>
                  <li style={styles.listItem}>・期日：01/13</li>
                </ul>
              </li>
            </ol>
          </div>
        </main>
        <aside style={styles.sidebar}>
          <div >
            <h3 style={styles.sidebarTitle}>議事録一覧</h3>
            <ul style={styles.sidebarList}>
              <li style={styles.sidebarListTitle}>2023/12</li>
              <li style={styles.sidebarListItem}>2023/12/08 16:34</li>
              <li style={styles.sidebarListItem}>2023/12/19 09:45</li>
              <li style={styles.sidebarListItem}>2023/12/21 12:21</li>
              <li style={styles.sidebarListItem}>2023/12/21 10:00</li>
              <li style={styles.sidebarListItem}>2023/12/30 18:00</li>
            </ul>
            <ul style={styles.sidebarList}>
              <li style={styles.sidebarListTitle}>2024/01</li>
              <li style={styles.sidebarListItem}>2024/01/15 15:00</li>
              <li style={styles.sidebarListItem}>2024/01/20 16:00</li>
              <li style={styles.sidebarListItem}>2024/01/27 15:00</li>
            </ul>
            <ul style={styles.sidebarList}>
              <li style={styles.sidebarListTitle}>2024/02</li>
              <li style={styles.sidebarListItem}>2024/02/6 15:00</li>
              <li style={styles.sidebarListItem}>2024/02/8 15:00</li>
              <li style={styles.sidebarListItem}>2024/02/11 15:00</li>
              <li style={styles.sidebarListItem}>2024/02/13 15:00</li>
              <li style={styles.sidebarListItem}>2024/02/15 15:00</li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  )
}