import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "../../App/store";
// import { RecruitModel, RecruitUserDetailModel } from '../../api/model/recruit'
import { getRecruitUsers, getRecruitUserDetail,patchStepForm } from '../../api/recruit'
import { createUser } from '../../api/user'
import { serializeError } from "serialize-error";


// type RecruitUsersLoaded = {
//   recruitUsers: any
// }
// type RecruitUserDetailLoaded = {
//   recruitUserDetail: any,
// }
type recruitState = {
  recruitUsers: any,
  recruitUserDetail: any,
  statusDisplay: any,
  statusNum: any,
  status: any,
  check_items: any,
  modalType: string,
  modalFlag: boolean,
  loading: boolean;
  error: any;
  snackbar: any
};

type ModalState = {
  type: string
  flag: boolean
}

const initialState: recruitState = {
  recruitUsers:[
    {
      id: 1,
      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: ""
      },
      agent: {
        name: "",
      },
      occpation: {
        name: ""
      },
    },
  ],
  recruitUserDetail:{
    id: 1,
    employee_id: 1,
    status: "document",
    profile_url: null,
    document_remarks: null,
    apply_date: null,
    document_recruit_staff: null,
    first_remarks: null,
    first_date: null,
    first_recruit_staff: null,
    second_remarks: null,
    second_date: null,
    second_recruit_staff: null,
    employee: {
      id: 1,
      last_name: "",
      first_name: ""
    }
  },
  statusDisplay: "document",
  statusNum: "1",
  status: [],
  check_items: [],
  modalType: "",
  modalFlag: false,
  loading: false,
  error: null,
  snackbar: null
};

const recruit = createSlice({
  name: "recruit",
  initialState,
  reducers: {
    getRecruitUsersStart(state) {
      state.loading = true;
    },
    getRecruitUsersSuccess(state, action: PayloadAction<any>) {
      const { recruitUsers,status } = action.payload
      state.recruitUsers = recruitUsers;
      state.status = status;
      state.loading = false;
      state.error = null;
    },
    getRecruitUsersFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getRecruitUserDetailStart(state) {
      state.loading = true;
    },
    getRecruitUserDetailSuccess(state, action: PayloadAction<any>) {
      const { recruitUserDetail,status } = action.payload
      state.recruitUserDetail = recruitUserDetail;
      state.statusDisplay = recruitUserDetail.status;
      state.check_items = recruitUserDetail.employee.check_items
      state.status = status
      state.loading = false;
      state.error = null;
    },
    getRecruitUserDetailFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    createUserStart(state) {
      state.loading = true;
    },
    createUserSuccess(state, action: PayloadAction<any>) {
      const { recruitUsers } = action.payload
      state.recruitUsers = recruitUsers;
      state.loading = false;
      state.error = null;
    },
    createUserFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    patchStepFormStart(state) {
      state.loading = true;
    },
    patchStepFormSuccess(state, action: PayloadAction<any>) {
      const { recruitUserDetail,status } = action.payload
      state.recruitUserDetail = recruitUserDetail;
      state.statusDisplay = recruitUserDetail.status;
      state.status = status
      state.loading = false;
      state.error = null;
    },
    patchStepFormFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    changeStateStart(state) {
      state.loading = true;
    },
    changeStateSuccess(state, action: PayloadAction<any>) {
      const { status,statusNum } = action.payload
      state.statusDisplay = status;
      state.statusNum = statusNum
      state.loading = false;
      state.error = null;
    },
    changeStateFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    changeModalStateSuccess(state, action: PayloadAction<ModalState>) {
      const { type, flag } = action.payload
      state.modalType = type
      state.modalFlag = flag
      state.loading = false;
      state.error = null;
    },
    changeModalStateFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    openSnackbarSuccess(state, action: PayloadAction<any>) {
      const { snackbar } = action.payload
      state.snackbar = snackbar;
    },
    openSnackbarFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
    resetSnackbar(state) {
      state.snackbar = null;
    }
  }
});

export const {
  getRecruitUsersSuccess,
  getRecruitUsersStart,
  getRecruitUsersFailure,
  getRecruitUserDetailStart,
  getRecruitUserDetailSuccess,
  getRecruitUserDetailFailure,
  createUserStart,
  createUserSuccess,
  createUserFailure,
  patchStepFormStart,
  patchStepFormSuccess,
  patchStepFormFailure,
  changeStateStart,
  changeStateSuccess,
  changeStateFailure,
  changeModalStateSuccess,
  changeModalStateFailure,
  openSnackbarSuccess,
  openSnackbarFailure,
  resetError,
  resetSnackbar
} = recruit.actions;
export default recruit.reducer;

export const fetchRecruitUsers = ( auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(getRecruitUsersStart());
    const res = await getRecruitUsers("0",false,auth)
    dispatch(
      getRecruitUsersSuccess({
        recruitUsers: res.data,
        status: res.status
      })
    );
  } catch (err: any) {
    dispatch(getRecruitUsersFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const fetchRecruitUserDetail = ( id:any,auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(getRecruitUserDetailStart());
    const res = await getRecruitUserDetail(id,auth)
    dispatch(
      getRecruitUserDetailSuccess({
        recruitUserDetail: res.data.data,
        status: res.data.status,
      })
    );
  } catch (err: any) {
    dispatch(getRecruitUserDetailFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const chengeList = ( tab: any, failure: any, auth: any ): any => async (dispatch:any) => {
  try {
    dispatch(getRecruitUsersStart());
    const res = await getRecruitUsers(tab,failure,auth)
    dispatch(
      getRecruitUsersSuccess({
        recruitUsers: res.data,
        status: res.status
      })
    );
  } catch (err: any) {
    dispatch(getRecruitUsersFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onCreateUser = ( data: any, auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(createUserStart());
    const res = await createUser(data,auth)
    dispatch(
      createUserSuccess({
        recruitUsers: res.data.data
      })
    );
  } catch (err: any) {
    dispatch(createUserFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onPatchStepForm = ( id: any, status: any,  data: any, auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(patchStepFormStart());
    const res = await patchStepForm(id,status,data,auth)
    dispatch(
      patchStepFormSuccess({
        recruitUserDetail: res.data.data,
        status: res.data.status,
      })
    );
  } catch (err: any) {
    dispatch(patchStepFormFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const changeState = (status:string, statusNum:string):any => async (dispatch:any) => {
  try {
    dispatch(changeStateStart());
    dispatch(
      changeStateSuccess({status:status,statusNum:statusNum})
    );
  } catch (err: any) {
    dispatch(changeStateFailure({ message: err.response.data.errors }));
    throw err;
  }
}
export const changeModalState = ( type: string, flag: boolean ): any => async (dispatch:any) => {
  try {
    dispatch(
      changeModalStateSuccess({type, flag})
    );
  } catch (err: any) {
    dispatch(changeModalStateFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const openSnackbar = ( snackbar: any ): any => async (dispatch:any) => {
  try {
    dispatch(
      openSnackbarSuccess({snackbar})
    );
  } catch (err: any) {
    dispatch(openSnackbarFailure({ message: err.response.data.errors }));
    throw err;
  }
};