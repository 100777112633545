/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { formColors } from "../../../../styles/_var"

type Props = {
  placeholder?: string
  isDisabled?: boolean
  propStyle?: SerializedStyles
  register?: any
  name: string
  children?:string
}
export const Textarea: React.FC<Props> = ({ placeholder ,isDisabled, propStyle, register, name, children }) => {
  return (
    <textarea
      css={[textareaStyle,propStyle]}
      disabled={isDisabled}
      placeholder={placeholder}
      defaultValue={children}
      {...register(name)}
    />
  )
}
const textareaStyle = css`
  width: 100%;
  min-height: 200px;
  height: 100%;
  padding: 8px 12px;
  background-color: ${formColors.bgColor};
  border: 1px solid ${formColors.borderColor};
  border-radius: 4px;
  font-size: 15px;
  line-height: 1.8;
  letter-spacing: 0.03em;

  &::placeholder {
    color: ${formColors.placeholderColor};
  }
`