import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom"
import { RootState } from "../../../App/rootReducer";
// import { onAddOccupations } from "../salesSlice";
// import { chengeList,changeModalState,onCreateUser } from "../../user/userSlice";
import { changeModalState } from "../../user/userSlice";


import Skill from "../../../components/pages/Sales/Tech/Skill";

type Props = {
  children?: React.ReactNode;
};

export default function SkillWithRedux(props: Props) {
  // TODO: as any良くない
  // const occupations = useSelector((state: RootState) => state.sales.occupations) as any;
  // const prefecture = useSelector((state: RootState) => state.user.prefecture) as any;
  const modalFlag = useSelector((state: RootState) => state.user.modalFlag);
  const modalType = useSelector((state: RootState) => state.user.modalType);
  const dispatch = useDispatch();

  useEffect(() => {
    async function f() {
      // 前処理
      try {
        // dispatch(fetchSalesUsers());
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch]);

  // const onAddOccupation = () => {
  //   async function f() {
  //     // 前処理
  //     try {
  //       await dispatch(onAddOccupations())
  //     } catch (error) {
  //       console.log('error:', error)
  //     }
  //     // 後処理
  //   }
  //   f()
  // }
  const onChangeModalState = (type: string, flag: boolean) => {
    async function f() {
      // 前処理
      try {
        await dispatch(changeModalState(type, flag))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
      <Skill
        modalState={{type:modalType,flag:modalFlag}}
        setOpenModal={onChangeModalState}
      />
  );
}
