/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useState } from 'react';
import { useForm } from 'react-hook-form'
import { Text } from "../../../atoms/Text"
import Logo from "../../../atoms/Img/logo"
import { Button } from "../../../atoms/Button"
// import { InputLabel } from "../../molecules/Form/InputLabel"
import Input from "../../../atoms/Form/Input"
import { iconStyle } from "../../../atoms/Icon"
import A from "../../../atoms/A";

const defaultProps = { };

export type Props = {
  setOpenModal: (type: string, flag: boolean) => void
} 

export default function AdminHeader(props: Props) {
  const {
    handleSubmit,
    getValues,
    register,
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });
  const [focusFlag, setFocusFlag] = useState(false);

  const onSubmit = () => {
    const data = getValues()
    console.log(data)
  }
  const onFocusFunc = () => {
    // フォーカスが当たったときの処理
    setFocusFlag(true)
  }
  const onBlurFunc = () => {
    // フォーカスが外れたときの処理
    if(getValues("company") === "" ) {
      setFocusFlag(false)
    }
  }

  return (
    <header css={headerWrapStyle}>
      <div css={headerFormStyle}>
        <div css={headerLogoWrapStyle}>
          <A to={"/company"}>
            <Logo type={"service"} propStyle={headerLogoStyle} />
          </A>
          <Text Tag="p" color="black" size="xl" isAlpha isBold={false} propStyle={TextStyle}>管理画面</Text>
        </div>
        <form onKeyUp={handleSubmit(onSubmit)}>
          <div css={headerInputWrapStyle(focusFlag)}>
            <Input type={"text"} name={"company"} register={register} placeholder={"企業名を検索"} onFocus={onFocusFunc} onBlur={onBlurFunc} propStyle={headerInputStyle(focusFlag)} />
          </div>
        </form>
        <Button Tag={"button"} type={"secondary"} size={"s"} propStyle={ButtonStyle} onClick={()=>props.setOpenModal( "new", true )}>新規発行</Button>
      </div>
    </header>
  );
}

AdminHeader.defaultProps = defaultProps;

const headerWrapStyle = css`
  padding: 10px 40px;
`
const headerLogoWrapStyle = css`
  align-items: center;
  width: 98px;

`
const headerLogoStyle = css`
  width: 120px;
`
const TextStyle = css`
  text-align: center;
  margin-top: -10px;
`
const headerFormStyle = css`
  display: grid;
  grid-template-columns: 180px 1fr 230px;
  align-items: center;
  width: 100%;
`
const headerInputWrapStyle = ( isFocus: boolean ) => css`
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
  position: relative;
  ${!isFocus ? setFoucusStyle():null}
`
const setFoucusStyle = () => {
  const inputProp = css`
  ${iconStyle('icon-Search')};
  &::before {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0, -50%);
  }
  `
  return inputProp
}
const headerInputStyle = ( isFocus: boolean ) =>  css`
  padding: ${!isFocus ? "18px 44px": "18px" };
  border: none;
  font-size: 18px;
  height: 56px;
  &::placeholder {
    font-size: 18px;
  }
  &:focus {
    padding: 18px;
  }
`
const ButtonStyle = css`
  padding: 18px 40px;
  max-width: 190px;
  margin-left: auto;
  font-size: 20px;
  ${iconStyle('icon-Plus')};
  &::before {
    font-size: 16px;
    margin-right: 10px;
  }
`