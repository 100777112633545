/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form';
import { formColors,colors } from "../../../../styles/_var"

const defaultProps = {
}

type Props = {
  id?: any
  val?: any
  propStyle?: any
  children?:string
  register: UseFormRegister<any>
  name: string
  rules?: any
} & typeof defaultProps;

export default function Radio(props: Props) {
  const { id, val, propStyle, children, register, name, rules } = props
  // // 選択した値を管理（初期値：ラジオ１）
  // const [value, setVal] = React.useState('アイテム１');
  //  // ラジオボタンの値がチェンジされた時
  //  const handleChange = (e:any) => {
  //   setVal(e.target.value);
  // };
  return (
    <>
      <label htmlFor={id} css={radioStyle}>
        <input
          type="radio"
          id={id}
          css={[inputStyle,propStyle]}
          value={val}
          {...register(name,rules)}
          defaultChecked={val === 1}
        />
        <span>
          {children}
        </span>
      </label>
    </>
  )
}

Radio.defaultProps = defaultProps;

const inputStyle = css`
  /* width: 100%;
  height: 100%;
  border: 1px solid ${formColors.borderColor};
  border-radius: 4px;
  font-size: 15px; */
`
const radioStyle = css`
  cursor: pointer;
  span{
    position: relative;
    display: block;
    padding-left: 24px;
  }
  input + span{
    ::before{
      content: '';
      position: absolute;
      display: block;
      top: 45%;
      left: 0;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-color: ${colors.white};
      border: 1px solid ${colors.mono400};
      border-radius: 12px;
    }
  }

  input:checked + span{
    ::before{
      background-color: ${colors.white};
      border: 1px solid ${colors.mono400};
    }
    ::after{
      content: '';
      position: absolute;
      display: block;
      top: 9px;
      left: 6px;
      width: 4px;
      height: 4px;
      background-color: ${colors.black};
      border-radius: 6px;
    }
  }
`