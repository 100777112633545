/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../atoms/Text"
import { colors } from "../../../../styles/_var";

type Props = {
  children: string
  propStyle?: SerializedStyles
}
export const Heading: React.FC<Props> = ({ children, propStyle }) => {
  return (
    <div css={[headingWrapStyle,propStyle]}>
      <Text Tag="p" color="black" size="4xl" >{children}</Text>
   </div>
  )
}
const headingWrapStyle = css`
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.mono300};
`