import { apiClient } from "./client";

export async function getLoginUsers(data:any) {
  return apiClient.post<any>("/auth/sign_in",data).then(response => {
    let obj:any = {}
    let client = response.headers['client'];
    let token = response.headers['access-token'];
    let uid = response.headers['uid'];
    obj["access-token"] = token
    obj["client"] = client
    obj["uid"] = uid
    return obj
});;
}
export async function getAdminLoginUsers(data:any) {
  return apiClient.post<any>("/auth/sign_in",data).then(response => {
    let obj:any = {}
    let client = response.headers['client'];
    let token = response.headers['access-token'];
    let uid = response.headers['uid'];
    obj["access-token"] = token
    obj["client"] = client
    obj["uid"] = uid
    return obj
});;
}
