// import { apiClient } from "./client";
// import { UserModel } from "./model/user";
import {SalesUsers} from '../consts/sales'
/**
 * API 定義
 * USER
 *
 */

export async function getSalesUsers(tabnum:any,auth:any) {
  console.log(tabnum)
  return SalesUsers
}
