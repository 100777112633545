export const userData = {
  data:[
    {
      last_name: "堀内",
      first_name: "謹二",
      last_name_kana: "ホリウチ",
      first_name_kana: "キンジ",
      id: "1",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "education",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "Lv:2"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "村上",
      first_name: "善二",
      last_name_kana: "ムラカミ",
      first_name_kana: "ゼンジ",
      id: "2",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "sales",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "クライアント返答待ち"
      },
      agent: {
        name: "duda"
      },
      occupation: {
        name: "バックエンド"
      }
    },
    {
      last_name: "新谷",
      first_name: "一樹",
      last_name_kana: "アラヤ",
      first_name_kana: "ヒトキ",
      id: "3",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "work",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: ""
      },
      agent: {
        name: "エン転職"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "広瀬",
      first_name: "泰秀",
      last_name_kana: "ヒロセ",
      first_name_kana: "ヤスヒデ",
      id: "4",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "work",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: ""
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "大島",
      first_name: "隆哉",
      last_name_kana: "オオシマ",
      first_name_kana: "タカヤ",
      id: "5",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "二次選考"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "バックエンド"
      }
    },
    {
      last_name: "大城",
      first_name: "成子",
      last_name_kana: "ダイジョウ",
      first_name_kana: "セイコ",
      id: "6",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "女",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "二次選考"
      },
      agent: {
        name: "エン転職"
      },
      occupation: {
        name: "バックエンド"
      }
    },
    {
      last_name: "荒木",
      first_name: "友良",
      last_name_kana: "アラキ",
      first_name_kana: "トモヨシ",
      id: "7",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "二次選考"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "畠山",
      first_name: "康三",
      last_name_kana: "ハタケヤマ",
      first_name_kana: "ヤスゾウ",
      id: "8",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "sales",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "クライアント返答待ち"
      },
      agent: {
        name: "エン転職"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "大木",
      first_name: "隆次",
      last_name_kana: "オオキ",
      first_name_kana: "タカジ",
      id: "9",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "一次選考"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "バックエンド"
      }
    },
  ],
  status: [
    {
      name: "全て",
      count: 9,
    },
    {
      name: "フロントエンド",
      count: 5,
    },
    {
      name: "バックエンド",
      count: 4,
    },
    {
      name: "インフラ",
      count: 0,
    },
    {
      name: "デザイナー",
      count: 0,
    },
  ],
}

export const userDataFront = {
  data:[
    {
      last_name: "堀内",
      first_name: "謹二",
      last_name_kana: "ホリウチ",
      first_name_kana: "キンジ",
      id: "1",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "education",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "Lv:2"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "新谷",
      first_name: "一樹",
      last_name_kana: "アラヤ",
      first_name_kana: "ヒトキ",
      id: "3",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "work",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: ""
      },
      agent: {
        name: "エン転職"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "広瀬",
      first_name: "泰秀",
      last_name_kana: "ヒロセ",
      first_name_kana: "ヤスヒデ",
      id: "4",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "work",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: ""
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "荒木",
      first_name: "友良",
      last_name_kana: "アラキ",
      first_name_kana: "トモヨシ",
      id: "7",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "二次選考"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
    {
      last_name: "畠山",
      first_name: "康三",
      last_name_kana: "ハタケヤマ",
      first_name_kana: "ヤスゾウ",
      id: "8",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "sales",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "クライアント返答待ち"
      },
      agent: {
        name: "エン転職"
      },
      occupation: {
        name: "フロントエンド"
      }
    },
  ],
  status: [
    {
      name: "全て",
      count: 9,
    },
    {
      name: "フロントエンド",
      count: 5,
    },
    {
      name: "バックエンド",
      count: 4,
    },
    {
      name: "インフラ",
      count: 0,
    },
    {
      name: "デザイナー",
      count: 0,
    },
  ],
}

export const userDataBack = {
  data:[
    {
      last_name: "村上",
      first_name: "善二",
      last_name_kana: "ムラカミ",
      first_name_kana: "ゼンジ",
      id: "2",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "sales",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "クライアント返答待ち"
      },
      agent: {
        name: "duda"
      },
      occupation: {
        name: "バックエンド"
      }
    },
    {
      last_name: "大島",
      first_name: "隆哉",
      last_name_kana: "オオシマ",
      first_name_kana: "タカヤ",
      id: "5",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "二次選考"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "バックエンド"
      }
    },
    {
      last_name: "大城",
      first_name: "成子",
      last_name_kana: "ダイジョウ",
      first_name_kana: "セイコ",
      id: "6",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "女",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "二次選考"
      },
      agent: {
        name: "エン転職"
      },
      occupation: {
        name: "バックエンド"
      }
    },
    {
      last_name: "大木",
      first_name: "隆次",
      last_name_kana: "オオキ",
      first_name_kana: "タカジ",
      id: "9",
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "一次選考"
      },
      agent: {
        name: "Wantedly"
      },
      occupation: {
        name: "バックエンド"
      }
    },
  ],
  status: [
    {
      name: "全て",
      count: 9,
    },
    {
      name: "フロントエンド",
      count: 5,
    },
    {
      name: "バックエンド",
      count: 4,
    },
    {
      name: "インフラ",
      count: 0,
    },
    {
      name: "デザイナー",
      count: 0,
    },
  ],
}

export const userDataKara = {
  data:[
  ],
  status: [
    {
      name: "全て",
      count: 9,
    },
    {
      name: "フロントエンド",
      count: 5,
    },
    {
      name: "バックエンド",
      count: 4,
    },
    {
      name: "インフラ",
      count: 0,
    },
    {
      name: "デザイナー",
      count: 0,
    },
  ],
}

export const usersSideList = [
  {
    id: 1,
    last_name: "堀内",
    first_name: "謹二"
  },
  {
    id: 2,
    last_name: "村上",
    first_name: "善二"
  },
  {
    id: 3,
    last_name: "新谷",
    first_name: "一樹"
  },
  {
    id: 4,
    last_name: "広瀬",
    first_name: "泰秀"
  },
  {
    id: 5,
    last_name: "大島",
    first_name: "隆哉"
  },
  {
    id: 6,
    last_name: "大城",
    first_name: "成子"
  },
  {
    id: 7,
    last_name: "荒木",
    first_name: "友良"
  },
  {
    id: 8,
    last_name: "畠山",
    first_name: "康三"
  },
  {
    id: 9,
    last_name: "大木",
    first_name: "隆次"
  },
]

export const prefecture = {
  data: [
    {
      "id": 1,
      "name": "北海道"
    },
    {
      "id": 2,
      "name": "青森県"
    },
    {
      "id": 3,
      "name": "岩手県"
    },
    {
      "id": 4,
      "name": "宮城県"
    },
    {
      "id": 5,
      "name": "秋田県"
    },
    {
      "id": 6,
      "name": "山形県"
    },
    {
      "id": 7,
      "name": "福島県"
    },
    {
      "id": 8,
      "name": "茨城県"
    },
    {
      "id": 9,
      "name": "栃木県"
    },
    {
      "id": 10,
      "name": "群馬県"
    },
    {
      "id": 11,
      "name": "埼玉県"
    },
    {
      "id": 12,
      "name": "千葉県"
    },
    {
      "id": 13,
      "name": "東京都"
    },
    {
      "id": 14,
      "name": "神奈川県"
    },
    {
      "id": 15,
      "name": "新潟県"
    },
    {
      "id": 16,
      "name": "富山県"
    },
    {
      "id": 17,
      "name": "石川県"
    },
    {
      "id": 18,
      "name": "福井県"
    },
    {
      "id": 19,
      "name": "山梨県"
    },
    {
      "id": 20,
      "name": "長野県"
    },
    {
      "id": 21,
      "name": "岐阜県"
    },
    {
      "id": 22,
      "name": "静岡県"
    },
    {
      "id": 23,
      "name": "愛知県"
    },
    {
      "id": 24,
      "name": "三重県"
    },
    {
      "id": 25,
      "name": "滋賀県"
    },
    {
      "id": 26,
      "name": "京都府"
    },
    {
      "id": 27,
      "name": "大阪府"
    },
    {
      "id": 28,
      "name": "兵庫県"
    },
    {
      "id": 29,
      "name": "奈良県"
    },
    {
      "id": 30,
      "name": "和歌山県"
    },
    {
      "id": 31,
      "name": "鳥取県"
    },
    {
      "id": 32,
      "name": "島根県"
    },
    {
      "id": 33,
      "name": "岡山県"
    },
    {
      "id": 34,
      "name": "広島県"
    },
    {
      "id": 35,
      "name": "山口県"
    },
    {
      "id": 36,
      "name": "徳島県"
    },
    {
      "id": 37,
      "name": "香川県"
    },
    {
      "id": 38,
      "name": "愛媛県"
    },
    {
      "id": 39,
      "name": "高知県"
    },
    {
      "id": 40,
      "name": "福岡県"
    },
    {
      "id": 41,
      "name": "佐賀県"
    },
    {
      "id": 42,
      "name": "長崎県"
    },
    {
      "id": 43,
      "name": "熊本県"
    },
    {
      "id": 44,
      "name": "大分県"
    },
    {
      "id": 45,
      "name": "宮崎県"
    },
    {
      "id": 46,
      "name": "鹿児島県"
    },
    {
      "id": 47,
      "name": "沖縄県"
    }
  ]  
}

export const occupations = {
  data: [
    {
      "id": 1,
      "name": "フロントエンド"
    },
    {
      "id": 2,
      "name": "バックエンド"
    },
    {
      "id": 3,
      "name": "インフラ"
    },
    {
      "id": 4,
      "name": "デザイナー"
    },
  ]  
}

export const agents = {
  data: [
    {
      "id": 1,
      "name": "Wantedly"
    },
    {
      "id": 2,
      "name": "duda"
    },
    {
      "id": 3,
      "name": "エン転職"
    },
  ]  
}
