import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../App/rootReducer";
// import LinearProgress from "@material-ui/core/LinearProgress";
import SidebarLeft from "../../../components/organisms//Sidebar/Left";
import { fetchSideBarUsersList,changeModalState,onGetPrefecture,onOccupations,onAgents } from "../../user/userSlice";

type Props = {
  children?: React.ReactNode;
};

export default function SidebarWithRedux(props: Props) {
  const sideBarUsersList = useSelector((state: RootState) => state.user.sideUsersList) as any;
  const auth = useSelector((state: RootState) => state.user.auth) as any
  // const modalFlag = useSelector((state: RootState) => state.user.modalFlag);
  // const loading = useSelector((state: RootState) => state.user.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    async function f() {
      // 前処理
      try {
        dispatch(fetchSideBarUsersList("0",auth));
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch,auth]);

  const onChegeSidebarList = (toggleNum:string) => {
    async function f() {
      // 前処理
      try {
        dispatch(fetchSideBarUsersList(toggleNum,auth));
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }

  const onChangeModalState = (type: string, flag: boolean) => {
    async function f() {
      // 前処理
      try {
        await dispatch(changeModalState(type, flag))
        await dispatch(onGetPrefecture(auth))
        await dispatch(onOccupations(auth))
        await dispatch(onAgents(auth))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
    <React.Fragment>
      <SidebarLeft hundleHideUserList={onChegeSidebarList} sideBarUsersList={sideBarUsersList} handleOpenModal={onChangeModalState}/>
    </React.Fragment>
  );
}
