/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { useForm } from "react-hook-form";
import { SalesDetailHeading } from "../../../../molecules/Heading/Sales/Detail";
import { InputLabel } from "../../../../molecules/Form/InputLabel";
import { SelectLabel } from "../../../../molecules/Form/SelectLabel";
import { TextareaLabel } from "../../../../molecules/Form/TextareaLabel";
import Input from "../../../../atoms/Form/Input"
import { Text } from "../../../../atoms/Text"
import Radio from "../../../../atoms/Form/radio"
import { iconStyle } from "../../../../atoms/Icon";
import { colors } from "../../../../../styles/_var"


const defaultProps = {};

type Props = {
  addOccupations: any
  prefecture: any
  occupations: any
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Profile(props: Props) {
  const {
    control,
    register
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  const occupationsTtl = (key:any) => {
    if(key === 0) {
      return "最新"
    } else {
      return key + "つ前"
    }
  }

  return (
    <>
      <SalesDetailHeading>プロフィールシート</SalesDetailHeading>
      <div css={profileWrapStyle}>
        <div css={textFormFlexStyle}>
          <InputLabel label="氏名" name="name_kama" type="text" register={register} />
          <InputLabel label=" " name="name_kama" type="text" register={register} />
        </div>
        <div css={textFormFlexStyle}>
          <InputLabel label="カタカナ" name="name_kama" type="text" register={register} />
          <InputLabel label=" " name="name_kama" type="text" register={register} />
        </div>
        <div css={textFormFlexStyle}>
          <InputLabel label="イニシャル" name="name_kama" type="text" register={register} />
          <InputLabel label=" " name="name_kama" type="text" register={register} />
        </div>
        <SelectLabel label="生年月日" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />
        <div css={fromItemStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>性別</Text>
          <div css={radioWrapStyle}>
            <Radio name="sex" register={register}>男性</Radio>
            <Radio name="sex" register={register}>女性</Radio>
          </div>
        </div>
        <InputLabel label="電話番号" name="tel" type="text" register={register} propStyle={fromItemStyle} />
        <div css={placeWrapStyle}>
          <SelectLabel label="地域" name="place" options={[{id:1,name:"22222"}]} control={control} propStyle={placeSelectStyle} />
          <div css={placeInputWrapStyle}>
            <Input type="text" name="tel" register={register} />
            <Input type="text" name="tel" register={register} />
          </div>
        </div>
        <SelectLabel label="案件入職可能日" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />

        <div css={occupationHeadeingStyle}>
          <Text Tag="p" color="black" size="xxl">経験職種</Text>
          <Text Tag="p" color="gray" size="s">直近の経歴を新しい順から作成</Text>
        </div>

        {props.occupations.map((item:any,key:any)=>{
          // if(key <= 3) {
            return (
              <div key={key} css={occupationWrapStyle}>
                <Text Tag="p" color="black" size="xl">{(occupationsTtl(key))+ "の経歴"}</Text>
                <SelectLabel label="経歴" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />
                <div css={dateWrapStyle}>
                  <SelectLabel label="在籍期間" name="date" options={[{id:1,name:"22222"}]} control={control} />
                  <Text Tag="p" color="black" size="l">~</Text>
                  <SelectLabel label="" name="date" options={[{id:1,name:"22222"}]} control={control} />
                </div>
                <Text Tag="p" color="gray" size="s">直近の経歴を新しい順から作成</Text>
                <TextareaLabel label="業務内容" name={"doument_remarks"} register={register} propStyle={fromItemStyle} />
              </div>
            )
          // }
        })}
        {/* {props.occupations.length <= 3 ? ( */}
          <div css={addFormWrapStyle} onClick={() => props.addOccupations()}>
            <Text Tag="p" size="xxl" color="black">経歴を追加する</Text>
          </div>
        {/* ):null} */}

      </div>
    </>
  );
}

Profile.defaultProps = defaultProps;

const profileWrapStyle = css`
  margin-top: 32px;
  padding: 32px 40px;
  background-color: ${colors.white};
`
const textFormFlexStyle = css`
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: end;
  >:last-of-type {
    margin-left: 8px;
  }
`
const fromItemStyle = css`
  margin-top: 32px;
`
const radioWrapStyle = css`
  display: flex;
  margin-top: 32px;
  > :first-of-type {
    margin-left: 0;
  }
  > * {
    margin-left: 12px;
  }
`
const placeWrapStyle = css`
  margin-top: 32px;
  display: flex;
  align-items: end;
`
const placeSelectStyle = css`
  width: 245px;
`
const placeInputWrapStyle = css`
  display: flex;
  max-width: 490px;
  width: 100%;
  height: 48px;
  > * {
    margin-left: 8px;
  }
`
const dateWrapStyle = css`
  margin-top: 32px;
  display: flex;
  align-items: end;
  > :first-child {
    margin-left: 0;
  }
  > p {
    width: 32px;
    text-align: center;
    padding-bottom: 10px;
  }
  > * {
    width: 174px;
    margin-left: 8px;
  }
`
// 個別スタイル
const selectwidthStyle = css`
  margin-top: 32px;
  width: 170px;
`
const occupationHeadeingStyle = css`
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #DDDDDD;
`
const occupationWrapStyle = css`
  margin-top: 32px;
  padding: 32px 24px;
  border: 1px solid #DDDDDD;
`
const addFormWrapStyle = css`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  height: 104px;
  text-align: center;
  background-color: ${colors.orange100};
  border: 1px dashed ${colors.orange300};
  cursor: pointer;
  ${iconStyle("icon-plus_circle")}
  ::before {
    margin: 4px 0 0 8px;
    font-size: 24px;
  }
`