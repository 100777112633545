/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Heading } from "../../molecules/Heading/Default";
import { Text } from "../../atoms/Text"

const defaultProps = {};

export default function Privacypolicy() {
  return (
    <>
      <Heading>プライバシーポリシー</Heading>
      <div css={privacypolicyContentWrapStyle}>
        <Text Tag="p" size="l" color="black" >株式会社エンジンポット（以下、「当社」といいます。）は、本WEBサイト上で提供するサービス（以下、「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。</Text>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第1条（プライバシー情報）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第2条（プライバシー情報の収集方法）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。当社は、ユーザーについて、利用したサービスやソフトウエア、購入した商品、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、ユーザーが当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第3条（個人情報を収集・利用する目的）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>当社が個人情報を収集・利用する目的は、以下のとおりです。</Text>
          <Text Tag="p" size="l" color="black">1. 当社は、お客さまの個人情報を、次の利用目的の達成に必要な範囲内において使用することを、予め当社ホームページにおいて公表いたします。</Text>
          <Text Tag="p" size="l" color="black">（1）当社サービスの利用申し込みの受付のため</Text>
          <Text Tag="p" size="l" color="black">（2）法令に基づく本人確認、あるいは利用者要件の確認のため</Text>
          <Text Tag="p" size="l" color="black">（3）各種記録請求または開示請求、あるいは期日管理等、当社サービスの継続的な取引における管理のため</Text>
          <Text Tag="p" size="l" color="black">（4）お客さまとの契約や法律等に基づく権利の行使や義務の履行のため</Text>
          <Text Tag="p" size="l" color="black">（5）取引の解約や取引解約後の事後管理のため</Text>
          <Text Tag="p" size="l" color="black">（6）サービスに関する照会や連絡、その他お客さまとの取引を適切かつ円滑に履行するため</Text>
          <Text Tag="p" size="l" color="black">（7）市場調査、データ分析等によるサービスの研究や開発のため</Text>
          <Text Tag="p" size="l" color="black">2. ユーザーに代金を請求するために、購入された商品名や数量、利用されたサービスの種類や期間、回数、請求金額、氏名、住所、銀行口座番号やクレジットカード番号などの支払に関する情報などを利用する目的</Text>
          <Text Tag="p" size="l" color="black">（1）ユーザーが簡便にデータを入力できるようにするために、当社に登録されている情報を入力画面に表示させたり、ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的</Text>
          <Text Tag="p" size="l" color="black">（2）代金の支払を遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的</Text>
          <Text Tag="p" size="l" color="black">（3）ユーザーからのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など当社がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的</Text>
          <Text Tag="p" size="l" color="black">（4）上記の利用目的に付随する目的</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第4条（個人情報の第三者提供）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>
          当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
          </Text>
          <Text Tag="p" size="l" color="black">1. 法令に基づく場合</Text>
          <Text Tag="p" size="l" color="black">2. 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</Text>
          <Text Tag="p" size="l" color="black">3. 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</Text>
          <Text Tag="p" size="l" color="black">4. 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</Text>
          <Text Tag="p" size="l" color="black">5. 予め次の事項を告知あるいは公表をしている場合利用目的に第三者への提供を含むこと第三者に提供されるデータの項目第三者への提供の手段または方法本人の求めに応じて個人情報の第三者への提供を停止すること前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。</Text>
          <Text Tag="p" size="l" color="black">（1）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</Text>
          <Text Tag="p" size="l" color="black">（2）合併その他の事由による事業の承継に伴って個人情報が提供される場合</Text>
          <Text Tag="p" size="l" color="black">（3）個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いているとき</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第5条（個人情報の開示）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1，000円の手数料を申し受けます。</Text>
          <Text Tag="p" size="l" color="black">1. 本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</Text>
          <Text Tag="p" size="l" color="black">2. 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</Text>
          <Text Tag="p" size="l" color="black">3. その他法令に違反することとなる場合前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第6条（個人情報の訂正および削除）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第7条（個人情報の利用停止等）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正または削除を請求することができます。当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これをユーザーに通知します。</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第8条（プライバシーポリシーの変更）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本WEBサイトに掲載したときから効力を生じるものとします。</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第9条（お問い合わせ窓口）</Text>
          <Text Tag="p" size="l" color="black" propStyle={privacypolicyTextStyle}>本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。</Text>
          <Text Tag="p" size="l" color="black" >社名:株式会社エンジンポット</Text>
          <Text Tag="p" size="l" color="black" >お問い合わせ先：info@enginepot.jp</Text>
        </div>
        <div css={privacypolicyContentWrapStyle}>
          <Text Tag="p" size="l" color="black" >2023年1月10日 策定</Text>
        </div>
      </div>
    </>
  );
}

Privacypolicy.defaultProps = defaultProps;

const privacypolicyContentWrapStyle = css`
  margin-top: 32px;
`
const privacypolicyTextStyle = css`
  margin-top: 16px;
`