/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
// import { useState } from 'react';
// import { useForm } from 'react-hook-form'
import { Text } from "../../../atoms/Text"
import Logo from "../../../atoms/Img/logo"
// import Input from "../../../atoms/Form/Input"
import { AccountIcon } from "../../../atoms/AccountIcon"
// import { iconStyle } from "../../../atoms/Icon"
import  A  from "../../../atoms/A"
import { colors } from "../../../../styles/_var";

const defaultProps = { };

export type Props = {
} 

export default function Header(props: Props) {
  // const {
  //   handleSubmit,
  //   getValues,
  //   register,
  // } = useForm({
  //   mode:"onChange",
  //   criteriaMode: "all",
  //   shouldFocusError: false,
  // });
  // const [focusFlag, setFocusFlag] = useState(false);

  // const onSubmit = () => {
  //   const data = getValues()
  //   console.log(data)
  // }
  // const onFocusFunc = () => {
  //   // フォーカスが当たったときの処理
  //   setFocusFlag(true)
  // }
  // const onBlurFunc = () => {
  //   // フォーカスが外れたときの処理
  //   if(getValues("company") === "" ) {
  //     setFocusFlag(false)
  //   }
  // }

  return (
    <header css={headerWrapStyle}>
      <div css={headerInnerStyle}>
        <A to="/home">
          <Logo type={"service"} propStyle={headerLogoWrapStyle}/>
        </A>
        <ul css={TextWrapStyle}>
          <li>
            <A to={"/home"}>
              <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>ホーム</Text>
            </A>
          </li>
          <li>
            <A to={"/recruit"}>
              <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>採用</Text>
            </A>
          </li>
          <li>
            <A to={"/education"}>
              <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>教育</Text>
            </A>
          </li>
          <li>
            <A to={"/sales"}>
              <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>営業</Text>
            </A>
          </li>
          <li>
            <A to={"/client"}>
              <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>営業先管理</Text>
            </A>
          </li>
          <li>
            <A to={"/backoffice"}>
              <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>バックオフィス</Text>
            </A>
          </li>
          {/* <li>
            <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>入職管理</Text>
          </li> */}
        </ul>
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)} css={headerFormStyle}> */}
        {/* TODO: 固定値外す */}
        {/* <div css={headerInputWrapStyle(focusFlag)}>
          <Input type={"text"} name={"company"} register={register} placeholder={"サービス内を検索する"} onFocus={onFocusFunc} onBlur={onBlurFunc} propStyle={headerInputStyle(focusFlag)} />
        </div> */}
        <div css={accountIconWrapStyle} >
          <A to={"/setting"}>
            <AccountIcon name={""} size={"m"} src={"https://placehold.jp/30x30.png"} />
          </A>
        </div>
      {/* </form> */}
    </header>
  );
}

Header.defaultProps = defaultProps;

const headerWrapStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 7px 20px;
  border: 1px solid ${colors.mono300};
`
const headerInnerStyle = css`
  display: flex;
  justify-content: space-between;
  max-width: 570px;
  width: 100%;
  align-items: center;
`
const headerLogoWrapStyle = css`
  align-items: center;
  max-width: 101px;
  width: 100%;
  >img{
    width: 101px;
  }
`
const TextWrapStyle = css`
  display: flex;
  justify-content: space-between;
  max-width: 410px;
  width: 100%;
  margin-left: 60px;
`
const TextStyle = css`
  text-align: center;
`
// const headerFormStyle = css`
//   display: flex;
//   align-items: center;
//   width: 100%;
// `
// const headerInputWrapStyle = ( isFocus: boolean ) => css`
//   margin: 0 0 0 auto;
//   max-width: 320px;
//   width: 100%;
//   position: relative;
//   ${!isFocus ? setFoucusStyle():null}
// `
// const setFoucusStyle = () => {
//   const inputProp = css`
//   ${iconStyle('icon-Search')};
//   &::before {
//     position: absolute;
//     left: 24px;
//     top: 50%;
//     transform: translate(0, -50%);
//   }
//   `
//   return inputProp
// }
// const headerInputStyle = ( isFocus: boolean ) =>  css`
//   padding: ${!isFocus ? "18px 44px": "18px" };
//   background-color: ${colors.white};
//   &:focus {
//     padding: 15px;
//   }
// `
const accountIconWrapStyle = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 20px;
  position: relative;
  ::before {
    margin-left: 8px;
    font-size: 13px;
  }
`