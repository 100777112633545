/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import React from "react";
import { formColors } from "../../../styles/_var"

type Props = {
  propStyle?: SerializedStyles
  children?: string
}

export const ErrorMessage: React.FC<Props> = (props) => {
  const { propStyle, children } = props
  return (
    <p css={[ErrorMessageStyle,propStyle]}>{children}</p>
  )
}
const ErrorMessageStyle = css`
  margin-top: 8px;
  font-weight: 400!important;
  font-size: 12px!important;
  line-height: 17px!important;
  letter-spacing: 0.03em!important;
  color: ${formColors.errorColor}!important;
`