/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../atoms/Text"
import { Textarea } from "../../../atoms/Form/Textarea"


type Props = {
  label: string
  name:string
  register?: any
  propStyle?: SerializedStyles
  children?: string
}
export const TextareaLabel: React.FC<Props> = ({label,propStyle,name,register,children}) => {
  return (
    <div css={[propStyle]}>
      <Text Tag="p" color="black" size="m" isBold>{label}</Text>
      <Textarea propStyle={marginStyle} name={name} placeholder={""} register={register}>{children}</Textarea>
    </div>
  )
}
const marginStyle = css`
  margin-top: 8px;
`