import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "../../App/store";
import { UserModel } from '../../api/model/user'
import {
  search,
  searchUsersSideList,
  searchRecruitSidebar,
  searchSalesSidebar,
  createUser,
  getPrefecture,
  getOccupations,
  getAgents
  } from '../../api/user'
import { getLoginUsers } from "../../api/login"
import { serializeError } from "serialize-error";


// type UserLoaded = {
//   users: UserModel[]
//   total: number
// }
type UserState = {
  users: any
  user: UserModel
  sideUsersList: any
  recruitSidebar: any
  salesSidebar: any
  prefecture: any
  occupations: any
  agents: any
  status: {}
  auth: any,
  modalType: string,
  modalFlag: boolean,
  loading: boolean;
  error: any;
};

type ModalState = {
  type: string
  flag: boolean
}

const initialState: UserState = {
  users: [
    {
      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      id: "",
      agent_id: "",
      occupation_id: "",
      parent_status: "sales",
      childState: "",
      initial_kana: "",
      initial_name: "",
      birth: "",
      sex: "",
      place: "",
      employment: "",
      recruit: {
        front_status: ""
      },
      agent: {
        name: ""
      },
      occupation: {
        name: ""
      }
    }
  ],
  user:{
    last_name: "",
    first_name: "",
    last_name_kana: "",
    first_name_kana: "",
    id: "",
    agent_id: "",
    occupation_id: "",
    parent_status: "sales",
    childState: "",
    initial_kana: "",
    initial_name: "",
    birth: "",
    sex: "",
    place: "",
    employment: "",
    recruit: {
      front_status: ""
    },
    agent: {
      name: ""
    },
    occupation: {
      name: ""
    }
  },
  sideUsersList: [
    {
      id: 1,
      last_name: "",
      first_name: ""
    }
  ],
  recruitSidebar: [],
  salesSidebar: [],
  prefecture: [],
  occupations: [],
  agents: [],
  status: [
    {
      name: "全て",
      count: 1,
    },
    {
      name: "全て",
      count: 2,
    },
  ],
  auth: {},
  modalType: "",
  modalFlag: false,
  loading: false,
  error: null
};

const pushNewUsers = (data:any,newData:any) => {
  const createData = []
  let newAgent = ""
  let newOccupation = ""
  switch(newData._json[0].agent_id){
    case 1:
      newAgent = "Wantedly"
    break
    case 2:
      newAgent = "duda"
    break
    case 3:
      newAgent = "エン転職"
    break
  }
  switch(newData._json[0].occupation_id){
    case 1:
      newOccupation = "フロントエンド"
    break
    case 2:
      newOccupation = "バックエンド"
    break
    case 3:
      newOccupation = "インフラ"
    break
    case 4:
      newOccupation = "デザイナー"
    break
  }
  createData.push(
    {
      last_name: newData._json[0].last_name,
      first_name: newData._json[0].first_name,
      last_name_kana: "",
      first_name_kana: "",
      id: data.length+1,
      agent_id: "1",
      occupation_id: "1",
      parent_status: "selection",
      childState: "1",
      initial_kana: "H",
      initial_name: "K",
      birth: "08/23",
      sex: "男",
      place: "愛媛県新居浜市寿町3-6-7",
      employment: "",
      recruit: {
        front_status: "書類選考"
      },
      agent: {
        name: newAgent
      },
      occupation: {
        name: newOccupation
      }
    },
  )
  return Object.assign([...data,...createData])
}
const pushNewSideUsersList = (data:any,newData:any) => {
  const createData = []
  createData.push(
    {
      id: data.length+1,
      last_name: newData._json[0].last_name,
      first_name: newData._json[0].first_name,
    }
  )
  return Object.assign([...data,...createData])
}

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    getLoginUsersStart(state) {
      state.loading = true;
    },
    getLoginUsersSuccess(state, action: PayloadAction<any>) {
      const { auth } = action.payload
      state.auth = auth
      state.loading = false;
      state.error = null;
    },
    getLoginUsersFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getUserStart(state) {
      state.loading = true;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      const { users,status } = action.payload
      state.users = users;
      state.status = status;
      state.loading = false;
      state.error = null;
    },
    getUserFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getSideBarUsersListStart(state) {
      state.loading = true;
    },
    getSideBarUsersListSuccess(state, action: PayloadAction<any>) {
      const { sideUsersList } = action.payload
      state.sideUsersList = sideUsersList;
      state.loading = false;
      state.error = null;
    },
    getSideBarUsersListFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    changeModalStateSuccess(state, action: PayloadAction<ModalState>) {
      const { type, flag } = action.payload
      state.modalType = type
      state.modalFlag = flag
      state.loading = false;
      state.error = null;
    },
    changeModalStateFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getRecruitSidebarStart(state) {
      state.loading = true;
    },
    getRecruitSidebarSuccess(state, action: PayloadAction<any>) {
      const { recruitSidebar } = action.payload
      state.recruitSidebar = recruitSidebar;
      state.loading = false;
      state.error = null;
    },
    getRecruitSidebarFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getSalesSidebarStart(state) {
      state.loading = true;
    },
    getSalesSidebarSuccess(state, action: PayloadAction<any>) {
      const { salesSidebar } = action.payload
      state.salesSidebar = salesSidebar;
      state.loading = false;
      state.error = null;
    },
    getSalesSidebarFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    createUserStart(state) {
      state.loading = true;
    },
    createUserSuccess(state, action: PayloadAction<any>) {
      const { users } = action.payload
      state.users = pushNewUsers(state.users,users)
      state.sideUsersList = pushNewSideUsersList(state.sideUsersList,users);
      state.loading = false;
      state.error = null;
    },
    createUserFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getPrefectureStart(state) {
      state.loading = true;
    },
    getPrefectureSuccess(state, action: PayloadAction<any>) {
      const { prefecture } = action.payload
      state.prefecture = prefecture;
      state.loading = false;
      state.error = null;
    },
    getPrefectureFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getOccupationsStart(state) {
      state.loading = true;
    },
    getOccupationsSuccess(state, action: PayloadAction<any>) {
      const { occupations } = action.payload
      state.occupations = occupations;
      state.loading = false;
      state.error = null;
    },
    getOccupationsFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getAgentsStart(state) {
      state.loading = true;
    },
    getAgentsSuccess(state, action: PayloadAction<any>) {
      const { agents } = action.payload
      state.agents = agents;
      state.loading = false;
      state.error = null;
    },
    getAgentsFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    }
  }
});

export const {
  getLoginUsersStart,
  getLoginUsersSuccess,
  getLoginUsersFailure,
  getUserStart,
  getUserSuccess,
  getUserFailure,
  getSideBarUsersListStart,
  getSideBarUsersListSuccess,
  getSideBarUsersListFailure,
  changeModalStateSuccess,
  changeModalStateFailure,
  getRecruitSidebarStart,
  getRecruitSidebarSuccess,
  getRecruitSidebarFailure,
  getSalesSidebarStart,
  getSalesSidebarSuccess,
  getSalesSidebarFailure,
  createUserStart,
  createUserSuccess,
  createUserFailure,
  getPrefectureStart,
  getPrefectureSuccess,
  getPrefectureFailure,
  getOccupationsStart,
  getOccupationsSuccess,
  getOccupationsFailure,
  getAgentsStart,
  getAgentsSuccess,
  getAgentsFailure,
  resetError
} = user.actions;
export default user.reducer;

export const submitLogin = ( data: any ): any => async (dispatch:any) => {
  try {
    dispatch(getLoginUsersStart());
    const res = await getLoginUsers(data)
    dispatch(
      getLoginUsersSuccess({
        auth: res,
      })
    );
  } catch (err: any) {
    dispatch(getLoginUsersFailure({ message: err.response.data.errors }));
    throw err;
  }
};

export const fetchUser = ( auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(getUserStart());
    const res = await search("0",auth)
    dispatch(
      getUserSuccess({
        users: res.data,
        status: res.status
      })
    );
  } catch (err: any) {
    dispatch(getUserFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const fetchSideBarUsersList = ( flag: any, auth: any ): any => async (dispatch:any) => {
  try {
    dispatch(getSideBarUsersListStart());
    const res = await searchUsersSideList(flag, auth)
    dispatch(
      getSideBarUsersListSuccess({
        sideUsersList: res,
      })
    );
  } catch (err: any) {
    dispatch(getSideBarUsersListFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const chengeList = ( tab: any, auth: any ): any => async (dispatch:any) => {
  try {
    dispatch(getUserStart());
    const res = await search(tab,auth)
    dispatch(
      getUserSuccess({
        users: res.data,
        status: res.status
      })
    );
  } catch (err: any) {
    dispatch(getUserFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const changeModalState = ( type: string, flag: boolean ): any => async (dispatch:any) => {
  try {
    dispatch(
      changeModalStateSuccess({type, flag})
    );
  } catch (err: any) {
    dispatch(changeModalStateFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const fetchRecruitSidebar = (auth:any): any => async (dispatch:any) => {
  try {
    dispatch(getRecruitSidebarStart());
    const res = await searchRecruitSidebar(auth)
    dispatch(
      getRecruitSidebarSuccess({
        recruitSidebar: res.data.data,
      })
    );
  } catch (err: any) {
    dispatch(getRecruitSidebarFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const fetchSalesSidebar = (auth:any): any => async (dispatch:any) => {
  try {
    dispatch(getSalesSidebarStart());
    const res = await searchSalesSidebar(auth)
    dispatch(
      getSalesSidebarSuccess({
        salesSidebar: res.data.data,
      })
    );
  } catch (err: any) {
    dispatch(getSalesSidebarFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onCreateUser = ( data: any, auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(createUserStart());
    // const res = await createUser(data,auth)
    dispatch(
      createUserSuccess({
        users: data
      })
    );
  } catch (err: any) {
    dispatch(createUserFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onGetPrefecture = ( auth: any ): any => async (dispatch:any) => {
  try {
    dispatch(getPrefectureStart());
    const res = await getPrefecture(auth)
    dispatch(
      getPrefectureSuccess({
        prefecture: res.data
      })
    );
  } catch (err: any) {
    dispatch(getPrefectureFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onOccupations = ( auth: any ): any => async (dispatch:any) => {
  try {
    dispatch(getOccupationsStart());
    const res = await getOccupations(auth)
    dispatch(
      getOccupationsSuccess({
        occupations: res.data
      })
    );
  } catch (err: any) {
    dispatch(getOccupationsFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onAgents = ( auth: any ): any => async (dispatch:any) => {
  try {
    dispatch(getAgentsStart());
    const res = await getAgents(auth)
    dispatch(
      getAgentsSuccess({
        agents: res.data
      })
    );
  } catch (err: any) {
    dispatch(getAgentsFailure({ message: err.response.data.errors }));
    throw err;
  }
};