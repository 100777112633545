import React from "react";
import { useLocation } from "react-router-dom";

import Root from "../../../components/templates/Root/index";

const defaultProps = {};

type Props = { children: React.ReactNode } & typeof defaultProps

// TODO: PageName を変更
function MyRoot(props: Props) {
  const { children } = props;
  const location = useLocation();

  return <Root path={location.pathname}>{children}</Root>;
}

export default MyRoot;
