/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form';
import { Modal } from "../../../atoms/Modal"
import { InputLabel } from "../../../molecules/Form/InputLabel"
import { Button } from "../../../atoms/Button"
import { Text } from "../../../atoms/Text"
import { colors } from "../../../../styles/_var"
import { ErrorMessage } from "../../../atoms/ErrorMessage"
import { useState } from "react";


export type ModalProps = {
  open: { type: string, flag: boolean };
  company: any
  handleChenge: any
  hundleCreate: any
  handleDelete: any
  handleSubmit: any
  onClose: () => void;
  register: UseFormRegister<any>
  isValid: any
  errors: any
  companyData: any
  getValues: any
};

export default function CompanyModal(props: ModalProps) {
  const { onClose, open, register, handleChenge, handleDelete, hundleCreate, handleSubmit, isValid, errors, companyData } = props;
  const modaiWith = (type: string) => {
    switch (type) {
      case "new":
        return "800"
      case "edit":
        return "1200"
      case "delete":
        return "480"
      case "done":
        return "640"
      default :
        return "800" 
    }
  }
  const modalText = (type:string) => {
    switch(type) {
      case "new":
        return "新規登録"
      case "edit":
        return "情報の編集"
      case "delete":
        return "企業の削除"
      case "create":
        return "編集内容を送信"
      case "done":
        return "編集内容を送信"
    }
  }
  const [companyName,setCompanyName] = useState({company_id: "",loginid: "",password: ""})
  const copyText = () => {
    navigator.clipboard.writeText(
      "cloudpotのログイン情報を更新しました。\n以下の情報を入力し、ログインしてください。\nID：" + companyName.loginid + "\nパスワード：" + companyName.password
    )
  }
  const onChengeSubmit = (data:any) => {
    data.id = companyData.id
    handleChenge(data)
  }

  return (
    <Modal width={modaiWith(open.type)} isCenter={false} open={open.flag} onClose={onClose}>
      <div css={modalTitleStyle}>{modalText(open.type)}</div>
      <div css={modalContentStyle(open.type)}>
        {open.type === "new" ? (
          <form css={modalContentInnerStyle} onSubmit={handleSubmit(hundleCreate)}>
            <InputLabel label={"社名"} type={"text"} name={"company_id"} register={register} rules={{required: true}} isError={typeof errors.company_id !== "undefined"} ></InputLabel>
            {errors.companyName && <ErrorMessage>文字が入力されていません</ErrorMessage>}
            <InputLabel label={"ID"} type={"text"} name={"loginid"} register={register} rules={{required: true}} isError={typeof errors.loginid !== "undefined"} ></InputLabel>
            {errors.companyId && <ErrorMessage>文字が入力されていません</ErrorMessage>}
            <InputLabel label={"パスワード"} type={"text"} name={"password"} register={register} rules={{required: true}} isError={typeof errors.password !== "undefined"} ></InputLabel>
            {errors.password && <ErrorMessage>文字が入力されていません</ErrorMessage>}
            <div css={modalBtnWrapStyle}>
              <Button Tag={"button"} type={"tertiary"} size={"l"} onClick={onClose} propStyle={tertiaryBtnStyle} >キャンセル</Button>
              <Button Tag={"button"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} onClick={()=>setCompanyName(props.getValues())} isDisabled={!isValid}>保存</Button>
            </div>
          </form>
        ):null}
        {open.type === "edit" ? (
          <form css={modalContentInnerStyle} onSubmit={handleSubmit(onChengeSubmit)}>
            <div css={modalInputWrapStyle}>
              <InputLabel label={"社名"} type={"text"} name={"company_id"} register={register} propStyle={editCompanyNameInputStyle} rules={{required: true}} isError={typeof errors.company_id !== "undefined"} >{companyData.name}</InputLabel>
              {errors.companyName && <ErrorMessage>文字が入力されていません</ErrorMessage>}
              <InputLabel label={"ID"} type={"text"} name={"loginid"} register={register} propStyle={editcompanyIdInputStyle} rules={{required: true}} isError={typeof errors.loginid !== "undefined"} >{companyData.id}</InputLabel>
              {errors.companyId && <ErrorMessage>文字が入力されていません</ErrorMessage>}
              <InputLabel label={"パスワード"} type={"text"} name={"password"} register={register} propStyle={editcompanyPassWordInputStyle} rules={{required: true}}isError={typeof errors.password !== "undefined"} >{companyData.password}</InputLabel>
              {errors.password && <ErrorMessage>文字が入力されていません</ErrorMessage>}
            </div>
            <div css={modalBtnWrapStyle}>
              <Button Tag={"button"} type={"tertiary"} size={"l"} onClick={onClose} propStyle={tertiaryBtnStyle} >キャンセル</Button>
              <Button Tag={"button"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} onClick={()=>setCompanyName(props.getValues())} isDisabled={!isValid} >保存</Button>
            </div>
          </form>
        ):null}
        {open.type === "delete" ? (
          <form css={modalContentInnerStyle} onSubmit={handleSubmit(handleDelete)}>
            <div css={textWrap}>
              <Text Tag="p" color="black" size="l" >株式会社MAISON MARCのIDとパスワードを削除しますか？</Text>
              <Text Tag="p" color="black" size="l" >削除ボタンを押すと元に戻せません。</Text>
            </div>
            <div css={modalBtnWrapStyle}>
              <Button Tag={"button"} type={"tertiary"} size={"l"} onClick={onClose} propStyle={tertiaryBtnStyle} >戻る</Button>
              <Button Tag={"button"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} >削除</Button>
            </div>
          </form>
        ):null}
        {open.type === "done" ? (
          <div css={modalContentInnerStyle}>
            <div css={copyTextWrap}>
              <Text Tag="p" color="black" size="l" >cloudpotのログイン情報を更新しました。</Text>
              <Text Tag="p" color="black" size="l" >以下の情報を入力し、ログインしてください。</Text>
              <br></br>
              <Text Tag="p" color="black" size="l" >{"ID：" + companyName.loginid}</Text>
              <Text Tag="p" color="black" size="l" >{"パスワード：" + companyName.password}</Text>
            </div>
            <div css={modalBtnWrapStyle}>
              <Button Tag={"button"} type={"quaternary"} size={"l"} onClick={()=>copyText()} propStyle={tertiaryBtnStyle} >文面をコピー</Button>
              <Button Tag={"button"} type={"primary"} size={"l"} onClick={onClose} propStyle={primaryBtnStyle} >完了</Button>
            </div>
          </div>
        ):null}
      </div>
    </Modal>
  );
}

const modalTitleStyle = css`
  padding: 28px 32px;
  border-bottom: 1px solid ${colors.mono300};
`
const modalContentStyle = (type: string) => css`
  ${setModalProperties(type)};
`
const setModalProperties = (type: string) => {
  const modalProp = {
    padding: "",
    backgroundColor: "",
    color: "",
    border: "",
    boxShadow: ""
  }
  switch (type) {
    case "new":
      modalProp["padding"] = "64px 160px"
    return modalProp
    case "edit":
      modalProp["padding"] = "64px 160px"
    return modalProp
    case "delete":
      modalProp["padding"] = "64px 80px"
    return modalProp
    case "done":
      modalProp["padding"] = "64px 80px"
    return modalProp
  }
}
const modalContentInnerStyle = css`
  >div {
    margin-top: 32px;
  }
  >:first-of-type{
    margin-top: 0;
  }
`
const modalInputWrapStyle = css`
  display: flex;
  justify-content: space-between;
`
const editCompanyNameInputStyle = css`
  width: 320px;
  >input {
    height: 48px;
  }
`
const editcompanyIdInputStyle = css`
  width: 240px;
  >input {
    height: 48px;
  }
`
const editcompanyPassWordInputStyle = css`
  width: 240px;
  >input {
    height: 48px;
  }
`
const modalBtnWrapStyle = css`
  padding-top: 32px;
  border-top: 1px solid ${colors.mono300};;
  display: flex;
  justify-content: space-between;
`
const tertiaryBtnStyle = css`
  max-width: 173px;
`
const primaryBtnStyle = css`
  max-width: 121px;
`
const textWrap = css`
  text-align: center;
`
const copyTextWrap = css`
  padding: 16px 12px;
  background-color: ${colors.green100};
  border: 1px solid ${colors.mono300};
`