import { apiClient } from "./client";
// import { UserModel } from "./model/user";

/**
 * API 定義
 * USER
 *
 */

// export type SearchRes = {
//   data: {
//     users: UserModel[];
//     total: number;
//   };
// };

export async function getCompanys(auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.get<any>("/companies",config);
}
export async function createCompany(data:any,auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.post<any>("/auth", data,config);
}

export async function deleteCompany(id:any,auth:any) {
  // return apiClient.get<SearchRes>("/");
  console.log(id,auth)
  return {
    data:{
      data:[
          {
            id:1,
            name:"株式会社MAISON MARC",
            user:{loginid:"maisonmarc"},
          },
        ]
    }
  }
}
export async function chengeCompany(data:any,auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.patch<any>(`/companies/${data.id}`,data,config);
}