import axios from "axios";

export const apiClient = axios.create({
  // TODO: ENV ファイル
  baseURL: `${process.env.REACT_APP_API_HOST}/v1`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json"
  }
});
