// import { apiClient } from "./client";
// import { UserModel } from "./model/user";
import {clients} from '../consts/client'

/**
 * API 定義
 * USER
 *
 */

export async function getGetClient(auth:any) {
  return clients
}