/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom";
import { Text } from "../../../../atoms/Text"
import { colors } from "../../../../../styles/_var";
import { low_salesModel } from "../../../../../api/model/sales";

type Props = {
  item: {
    first_name: string;
    first_name_kana: string,
    last_name: string,
    last_name_kana: string,
    id: string;
    low_sales: low_salesModel[],
  }
}
export const SalesList: React.FC<Props> = ({ item }) => {
  let navigate = useNavigate();
  const LinkToUserDetailPage = (id:string) => {
    // ボタンクリックによるページ遷移
    navigate("/sales/admin",{state:id});
  };
  return (
    <div css={userListInnerStyle} onClick={()=>LinkToUserDetailPage(item.id)}>
      <div css={nameWidthStyle}>
        <Text Tag="p" color="gray" size="s" >{"No."+ item.id}</Text>
        <Text Tag="p" color="black" size="xl" >{item.last_name + " " + item.first_name}</Text>
        <Text Tag="p" color="black" size="s" >{item.last_name_kana + " " + item.first_name_kana}</Text>
      </div>
      <ul css={caseWrapStyle}>
        {item.low_sales.map((item,key) => {
          return (
            <li key={key} css={caseStyle}>
              <Text Tag="p" color="black" size="m" propStyle={caseNameStyle}>{item.sale_topic.name}</Text>
              <Text Tag="p" color="gray" size="m" propStyle={dateStyle}>{item.start_date}</Text>
              <Text Tag="p" color="green" size="m" propStyle={stateStyle}>{item.status}</Text>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
const userListInnerStyle = css`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  border-bottom: 1px solid ${colors.mono400};
  cursor: pointer;
`
const caseWrapStyle = css`
  >:first-of-type{
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
`
const caseStyle = css`
  display: flex;
  margin-top: 16px;
  padding-top: 16px;
  align-items: center;
  border-top: 1px solid ${colors.mono200};
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 160px;
  min-width: 150px;
  width: 100%;
`
const caseNameStyle = css`
  flex: 1;
  max-width: 392px;
  min-width: 392px;
  width: 100%;
`
const dateStyle = css`
  flex: 1;
  max-width: 72px;
  min-width: 72px;
  width: 100%;
`
const stateStyle = css`
  flex: 1;
  max-width: 160px;
  min-width: 160px;
  width: 100%;
  text-align: right;
`