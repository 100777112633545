export const clients = {
  data:[
    {
      company_id: 1,
      id: 1,
      name: "システムソリューションズ株式会社",
      sale_topics: [
        {
          id: 1,
          name: "ECサイト開発プロジェクト",
          low_sales: [
            {
              sale_topic: {
                name: "ウェブアプリ保守案件"
              }
            },
            {
              sale_topic: {
                name: "業務効率化ツール開発"
              }
            },
            {
              sale_topic: {
                name: "画像認識システム開発"
              }
            }
          ]
        }
      ],
      topic_count: 3
    },
    {
      company_id: 2,
      id: 2,
      name: "テクノロジーパートナーズ",
      sale_topics: [
        {
          id: 2,
          name: "モバイルアプリ開発案件",
          low_sales: [
            {
              sale_topic: {
                name: "バックエンド最適化"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 3,
      id: 3,
      name: "クラウドエンジニアリング株式会社",
      sale_topics: [
        {
          id: 3,
          name: "クラウド移行プロジェクト",
          low_sales: [
            {
              sale_topic: {
                name: "オンプレミスからクラウドへの移行"
              }
            },
            {
              sale_topic: {
                name: "業務プロセス自動化"
              }
            }
          ]
        }
      ],
      topic_count: 2
    },
    {
      company_id: 4,
      id: 4,
      name: "フューチャーテクノロジーズ",
      sale_topics: [
        {
          id: 4,
          name: "AIモデル開発案件",
          low_sales: [
            {
              sale_topic: {
                name: "機械学習の最適化"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 5,
      id: 5,
      name: "デジタルソリューションズ",
      sale_topics: [
        {
          id: 5,
          name: "IoTデバイス開発",
          low_sales: [
            {
              sale_topic: {
                name: "センサー連携システム構築"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 6,
      id: 6,
      name: "インテグレーションサービス株式会社",
      sale_topics: [
        {
          id: 6,
          name: "RPA導入支援プロジェクト",
          low_sales: [
            {
              sale_topic: {
                name: "業務自動化ツールの改善"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 7,
      id: 7,
      name: "ソフトウェアデザイン株式会社",
      sale_topics: [
        {
          id: 7,
          name: "ERPシステム導入案件",
          low_sales: [
            {
              sale_topic: {
                name: "データ移行プロジェクト"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 8,
      id: 8,
      name: "エンタープライズテクノロジー株式会社",
      sale_topics: [
        {
          id: 8,
          name: "業務システムリプレース案件",
          low_sales: [
            {
              sale_topic: {
                name: "レガシーシステムからの移行"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 9,
      id: 9,
      name: "データサイエンス株式会社",
      sale_topics: [
        {
          id: 9,
          name: "ビッグデータ解析プロジェクト",
          low_sales: [
            {
              sale_topic: {
                name: "データクレンジング"
              }
            },
            {
              sale_topic: {
                name: "業務プロセス再構築"
              }
            },
            {
              sale_topic: {
                name: "UI/UXデザイン改善"
              }
            }
          ]
        }
      ],
      topic_count: 6
    },
    {
      company_id: 10,
      id: 10,
      name: "インフォメーションテクノロジーズ",
      sale_topics: [
        {
          id: 10,
          name: "クラウドセキュリティ構築案件",
          low_sales: [
            {
              sale_topic: {
                name: "認証基盤強化"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 11,
      id: 11,
      name: "グローバルネットワーク株式会社",
      sale_topics: [
        {
          id: 11,
          name: "ネットワークインフラ構築",
          low_sales: [
            {
              sale_topic: {
                name: "VPN構築プロジェクト"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
    {
      company_id: 12,
      id: 12,
      name: "ソリッドシステムズ",
      sale_topics: [
        {
          id: 12,
          name: "データベースリファクタリング",
          low_sales: [
            {
              sale_topic: {
                name: "SQL最適化"
              }
            }
          ]
        }
      ],
      topic_count: 2
    },
    {
      company_id: 13,
      id: 13,
      name: "アプリケーションサービス株式会社",
      sale_topics: [
        {
          id: 13,
          name: "マイクロサービス構築案件",
          low_sales: [
            {
              sale_topic: {
                name: "コンテナ化プロジェクト"
              }
            }
          ]
        }
      ],
      topic_count: 1
    },
  ]
  ,
}