import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminLogin from "../../../components/pages/AdminLogin";
import { submitLogin } from "../../user/userSlice";

type Props = {
  children?: React.ReactNode;
};

export default function AdminLoginWithRedux(props: Props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const onSubmitAdminLogin = (data: any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(submitLogin(data))
        navigate("/company");
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
    <React.Fragment>
      <AdminLogin onSubmit={data => onSubmitAdminLogin(data)}/>
    </React.Fragment>
  );
}
