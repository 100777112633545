/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form';
import { formColors } from "../../../../styles/_var"
import { InputType } from "../../../../types/"

const defaultProps = {
  type: "text",
  name: "hoge"
}

type Props = {
  type: InputType
  propStyle?: any
  isDisabled?: boolean
  placeholder?: string
  children?:string
  register: UseFormRegister<any>
  name: string
  rules?: any
  onFocus?: () => void;
  onBlur?: () => void;
} & typeof defaultProps;

export default function Input(props: Props) {
  const { type, propStyle, isDisabled, placeholder, children, register, name, rules, onFocus, onBlur } = props
  const inputRegister = register(name, rules)
  return (
    <input
      type={type}
      css={[inputStyle,propStyle]}
      disabled={isDisabled}
      placeholder={placeholder}
      defaultValue={children}
      onFocus={onFocus}
      {...inputRegister }
      onBlur={onBlur}
    />
  )
}

const inputStyle = css`
  width: 100%;
  height: 48px;
  padding: 12px;
  background-color: ${formColors.bgColor};
  border: 1px solid ${formColors.borderColor};
  border-radius: 4px;
  font-size: 15px;
  line-height: 1.8;
  letter-spacing: 0.03em;
  &::placeholder {
    color: ${formColors.placeholderColor};
  }
  &:focus::placeholder {
    color: transparent;
  }
`

Input.defaultProps = defaultProps;