/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../../atoms/Text"
import { CompanyProps } from "../../../../../types"
import { iconStyle } from "../../../../atoms/Icon"
import { colors } from "../../../../../styles/_var"

type Props = {
  company: CompanyProps
  openToolTip: {flag:boolean,id:number}
  openModal: { type:string, flag: boolean }
  handleShowToolTip: (id:number) => void
  handleOpenModal: any
  setCompanyData: any
  propStyle?: SerializedStyles
}
export const CompanyList: React.FC<Props> = ({ company,openToolTip,openModal,handleShowToolTip,handleOpenModal,setCompanyData,propStyle }) => {

  const onClickEdit = (company:string,name:any,id:any) => {
    handleOpenModal("edit",true)
    setCompanyData({name:company,loginid:name,id:id})
  }

  return (
    <li css={ [listStyle, propStyle] }>
      <Text Tag="p" color="black" size="m" propStyle={nameWidthStyle}>{company.name}</Text>
      <Text Tag="p" color="black" size="m" isAlpha propStyle={idWidthStyle}>{company.user.loginid}</Text>
      <Text Tag="p" color="black" size="m" isAlpha propStyle={passwordWidthStyle}> </Text>
      <div onClick={()=>handleShowToolTip(company.id)} css={[iconWidthStyle,listMenuStyle(true)]} >
        {openToolTip.flag === true && openToolTip.id === company.id && (
          <div>
            <p css={listMenuEditStyle} onClick={() => onClickEdit(company.name,company.user.loginid,company.id)}>編集</p>
            <p css={listMenuDeleteStyle} onClick={() => {handleOpenModal("delete",true)}}>削除</p>
          </div>
        )}
      </div>
    </li>
  )
}
const listStyle = css`
  position: relative;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  padding: 30px 15px;
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
`
const idWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
`
const passwordWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
`
const iconWidthStyle = css`
  flex: 1;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
  text-align: right;
  ${iconStyle('icon-Dots')};
  &::before {
    font-size: 16px;
    cursor: pointer;
  }
`
const listMenuStyle = (displayMenu? :boolean) => css`
  position: relative;
  >div{
    width: 120px;
    text-align: left;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 9999;
  }
`
const listMenuEditStyle = css`
  cursor: pointer;
  padding: 12px;
  border-radius: 2px 2px 0 0;
  background-color: ${colors.green100};
  border-top: 1px solid ${colors.mono300};
  border-left: 1px solid ${colors.mono300};
  border-right: 1px solid ${colors.mono300};
`
const listMenuDeleteStyle = css`
  color: ${colors.pink600};
  cursor: pointer;
  padding: 12px;
  border-radius: 2px 2px 0 0;
  background-color: ${colors.green100};
  border-bottom: 1px solid ${colors.mono300};
  border-left: 1px solid ${colors.mono300};
  border-right: 1px solid ${colors.mono300};
`