/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
// import { Button } from "../../../../atoms/Button"
import { Text } from "../../atoms/Text"
import { iconStyle } from "../../atoms/Icon"
import { colors } from "../../../styles/_var";
// import A from "../../../../atoms/A"
// import { useForm } from 'react-hook-form'

const defaultProps = {
};

type Props = {
  currentStep: number
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Formstepbar(props: Props) {
  const { currentStep } = props

  return (
    <>
      <ul css={tabListWrapStyle}>
        <li css={[stateStepTextStyle, stateCompleteStyle(currentStep,0)]}>
          <Text Tag="p" size="l" color="black">基本情報</Text>
        </li>
        <li css={[stateStepTextStyle, stateCompleteStyle(currentStep,1)]}>
          <Text Tag="p" size="l" color="black">スキル・資格</Text>
        </li>
        <li css={[stateStepTextStyle, stateCompleteStyle(currentStep,2)]}>
          <Text Tag="p" size="l" color="black">自己PR</Text>
        </li>
        <li css={[stateStepTextStyle, stateCompleteStyle(currentStep,3)]}>
          <Text Tag="p" size="l" color="black">職務経歴</Text>
        </li>
      </ul>
    </>
  );
}

Formstepbar.defaultProps = defaultProps;

const tabListWrapStyle = css`
  width: 600px;
  margin: 16px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  >:last-of-type {
    >p {
      &::before {
        content: "";
      }
    }
  }
`

const stateStepTextStyle = css`
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  >p {
    position: relative;
    ${iconStyle("icon-arrowtriangle_tin_Right")}
    &::before {
      color: ${colors.mono500};
      position: absolute;
      top: 50%;
      right: -29px;
      transform: translateY(-50%);
    }
  }
`
const stateCompleteStyle = (currentStep:number,thisStep:number) => css`
  >p{
    font-weight: ${currentStep === thisStep ? "bold" :null};
    color:  ${currentStep > thisStep ? colors.orange300 :null};
  }

  /* background-color: ${currentStep === 0 ? colors.green100 :null}; */
`