import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Login from "../../../components/pages/Login";
import { submitLogin } from "../../user/userSlice";

type Props = {
  children?: React.ReactNode;
};

export default function LoginWithRedux(props: Props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const onSubmitLogin = (data: any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(submitLogin(data))
        navigate("/home");
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
    <React.Fragment>
      <Login onSubmit={data => onSubmitLogin(data)}/>
    </React.Fragment>
  );
}
