import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// import { BrowserRouter, Route, Routes } from "react-router-dom";

// import Auth from "../features/auth/Auth";

// ページを 作成したら import すること。
import Login from "../features/login/Login";
import Company from "../features/company/Company";
import AdminLogin from "../features/adminLogin/AdminLogin";
import Home from "../features/user/Home";
import Talent from "../features/talent/Talent"
import Recruit from "../features/recruit/Recruit";
import RecruitDetail from "../features/recruit/Recruit/Detail";
import Sales from "../features/sales/Sales";

import SalesDetail from "../components/pages/Sales/Admin/Detail";
import Profile from "../features/sales/Profile"
import Operation from "../components/pages/Sales/Admin/Operation"

import SalesTechDetail from "../components/pages/Sales/Tech/Detail";
import TechSkill from "../features/sales/Skill"
import TechOperation from "../components/pages/Sales/Tech/Operation"

import Client from "../features/client/Client";
import Setting from "../components/pages/Setting"

import Privacypolicy from "../components/pages/Privacypolicy";
import Tos from "../components/pages/Tos";
import Notfound from "../components/pages/Notfound";

import Education from "../features/education/Education";
import EducationDetail from "../features/education/Education/Detail";
import BackOffice from "../components/pages/BackOffice";
import Employees from "../components/pages/BackOffice/Employees";

export default function AppRouter() {
  return (
      <Routes>
        {/* <Auth> */}
          <Route path="/" element={<Login />} />
          <Route path="/company" element={<Company />} />
          <Route path="/adminLogin" element={<AdminLogin />} />
          <Route path="/home" element={<Home />} />
          <Route path="/talent" element={<Talent />} />
          <Route path="/recruit" element={<Recruit />} />
          <Route path="/recruit/detail" element={<RecruitDetail />} />
          <Route path="/sales" element={<Sales />} />
          
          <Route path="/sales/admin" element={<SalesDetail />} />
          <Route path="/sales/admin/profile" element={<Profile />} />
          <Route path="/sales/admin/operation" element={<Operation />} />

          <Route path="/sales/admin/tech" element={<SalesTechDetail />} />
          <Route path="/sales/admin/tech/skill" element={<TechSkill />} />
          <Route path="/sales/admin/tech/operation" element={<TechOperation />} />

          <Route path="/education" element={<Education />} />
          <Route path="/education/detail" element={<EducationDetail />} />
          <Route path="/backoffice" element={<BackOffice />} />
          <Route path="/backoffice/employees" element={<Employees />} />

          <Route path="/client" element={<Client />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/tos" element={<Tos />} />
          <Route path="/404" element={ <Notfound /> } />
          <Route path="*" element={<Navigate to="/404" replace />} />
        {/* </Auth> */}
      </Routes>
  );
}
