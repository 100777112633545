/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import UserCreateModal from "../../organisms/Modal/UserCreateModal"
import { SalesHeading } from "../../molecules/Heading/Sales";
import { TabList } from "../../molecules/TabList"
import { SalesLabel } from "../../molecules/DataList/Sales/Label"
import { SalesList } from "../../molecules/DataList/Sales/List";
// import { Text } from "../../atoms/Text"
// import { iconStyle } from "../../atoms/Icon";
import { low_salesModel } from "../../../api/model/sales";

const defaultProps = {};

type Props = {
  users:[
    {
      first_name: string;
      first_name_kana: string,
      last_name: string,
      last_name_kana: string,
      id: string;
      low_sales: low_salesModel[];
    }
  ],
  status:any
  prefecture: any,
  occupations: any,
  agents: any,
  hundleChengeList: any
  onCreate: any
  modalState: any
  setOpenModal: (type: string, flag: boolean) => void
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Sales(props: Props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors,isValid },
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  // const onSubmit = () => {
  //   const data = getValues()
    // console.log(props)
  // }
  const [ openTab, setOpenTab ] = useState(0)
  const submit = (number:any) => {
    setOpenTab(number)
    props.hundleChengeList(number)
  }
  const [openToolTip, setOpenToolTip] = useState({ flag:false });
  const handleShowToolTip = () => {
    setOpenToolTip((prevState)=>({flag:!prevState.flag}))
  }
  
  return (
    <>
      <UserCreateModal
        prefecture={props.prefecture}
        occupations={props.occupations}
        agents={props.agents}
        open={props.modalState}
        onClose={()=>{props.setOpenModal("", false )}}
        hundleCreate={props.onCreate}
        handleSubmit={handleSubmit}
        register={register}
        control={control}
        isValid={isValid}
        errors={errors}
        handleOpenModal={props.setOpenModal}
      />
      <SalesHeading openToolTip={openToolTip} handleShowToolTip={handleShowToolTip}>営業</SalesHeading>
      <div css={optionWrapStyle}>
        <TabList openTab={openTab} setOpenTab={submit} list={props.status} />
      </div>
      <SalesLabel />
      <ul>
        {props.users.map((item,key) => {
          return (
            <li key={key}>
              <SalesList item={item} />
            </li>
          )
        })}
      </ul>
    </>
  );
}

Sales.defaultProps = defaultProps;

const optionWrapStyle = css`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`