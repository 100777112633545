/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom";
import { Text } from "../../../atoms/Text"
import { StateBar } from "../../../atoms/StateBar";
import { colors } from "../../../../styles/_var";
import { iconStyle } from "../../../atoms/Icon";

const defaultProps = {};

type Props = {
  talentDetail:any
  id: any
} & typeof defaultProps;

export const CardRecruit: React.FC<Props> = ({
  talentDetail,
  id,
}) => {
  let navigate = useNavigate();
  const onLinkPage = (id:number) => {
    // ボタンクリックによるページ遷移
    navigate("/recruit/detail",{state:id});
  };
  return (
    <div css={cardStyle} onClick={()=>onLinkPage(id)}>
      <div css={cardTtlWrapStyle}>
        <Text Tag="p" size="xxl" color="black">採用</Text>
      </div>
      <div css={cardContentsWrapStyle}>
        <div css={cardContentsStyle}>
          <Text Tag="p" size="l" color="gray">媒体</Text>
          <Text Tag="p" size="l" color="black">{talentDetail.agent.name}</Text>
        </div>
        <div css={cardContentsStyle}>
          <Text Tag="p" size="l" color="gray">ステータス</Text>
          <StateBar state={talentDetail.recruit.front_status} />
        </div>
      </div>
    </div>
  )
}
const cardStyle = css`
  width: 404px;
  min-height: 208px;
  height: 100%;
  padding: 32px 24px;
  background-color: ${colors.white};
  border: 1px solid ${colors.mono300};
  border-radius: 4px;
`
const cardTtlWrapStyle = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  ${iconStyle("icon-Pencil")}
  &::before {
    color: ${colors.mono350};
    font-size: 22px;
    margin-left: 5px;
    cursor: pointer;
  }
`
const cardContentsWrapStyle = css`
  margin-top: 33px;
  >* {
    margin-top: 20px;
  }
  &:first-of-type {
    margin-top: 0;
  }
`
const cardContentsStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  >:first-of-type {
    min-width: 112px;
  }
`