/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form';
import { Text } from "../../../atoms/Text"
import Input from "../../../atoms/Form/Input"
import { InputType } from "../../../../types"
import { colors } from "../../../../styles/_var"

type Props = {
  label: string
  notice?: string
  type: InputType
  name: string
  register: UseFormRegister<any>
  isError?: boolean
  children?: string
  rules?: any
  propStyle?: SerializedStyles
  placeholder?: any
}
export const InputLabel: React.FC<Props> = ({ label, notice, type, name, register, propStyle , isError, children, rules, placeholder }) => {
  return (
    <div css={[InputLabelWrapStyle,propStyle]}>
      <Text Tag="p" color="black" size="m" isBold={true}>{label}</Text>
      <Input type={type} name={name} propStyle={[marginInputStyle,isError && errorStyle]} rules={rules} register={register} placeholder={placeholder}>{children}</Input>
      {notice && <Text Tag="p" color="gray" size="s" propStyle={marginNoticeStyle}>{ notice }</Text>}
    </div>
  )
}
const InputLabelWrapStyle = css`
  width: 100%;
`
const marginInputStyle = css`
  margin-top: 8px;
`
const marginNoticeStyle = css`
  margin-top: 4px;
`
const errorStyle = css`
  border: 1px solid ${colors.pink600};
`