/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
type Size = "s" | "m" | "l"
type Props = {
  name: string
  size: Size
  src: string
  propStyle?: SerializedStyles
}
export const AccountIcon: React.FC<Props> = ({ name, size ,src, propStyle}) => {
  return (
    <p css={ [accountIconStyle(size), propStyle ] }>
      <img src={src} alt={name} />
    </p>
  )
}
const accountIconStyle = (size: Size) => css`
  box-sizing: border-box;
  width: ${bordersizeSet(size)};
  height: ${bordersizeSet(size)};
  border-radius: 100%;
  border: 1px solid #BBBBBB;
  > img {
    width: ${sizeSet(size)};
    height: ${sizeSet(size)};
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
  }
`

const sizeSet = (size: Size) => {
  switch (size) {
    case "s":
      return "24px"
    case "m":
      return "40px"
    case "l":
      return "100px"
  }
}
const bordersizeSet = (size: Size) => {
  switch (size) {
    case "s":
      return "26px"
    case "m":
      return "42px"
    case "l":
      return "100px"
  }
}