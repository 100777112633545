/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { useLocation } from "react-router-dom";
// import { useState } from 'react'
// import { useForm } from 'react-hook-form'
import { Text } from "../../../../atoms/Text"
// import Input from "../../../atoms/Form/Input"
import { Button } from "../../../../atoms/Button"
// import { iconStyle } from "../../../../atoms/Icon";
import { colors } from "../../../../../styles/_var";

type Props = {
  children: string
  propStyle?: SerializedStyles
}
export const SalesDetailHeading: React.FC<Props> = ({ children, propStyle }) => {
  // const {
  //   handleSubmit,
  //   getValues,
  //   register,
  // } = useForm({
  //   mode:"onChange",
  //   criteriaMode: "all",
  //   shouldFocusError: false,
  // });
  // const onSubmit = () => {
  //   const data = getValues()
  //   console.log(data)
  // }
  const location = useLocation();

  return (
    <div css={[headingWrapStyle,propStyle]}>
      <div css={headingInnerStyle(location.pathname)}>
        <div css={TtlWrapStyle}>
          <Text Tag="p" color="black" size="4xl" >{children}</Text>
        </div>
        { location.pathname === "/sales/admin/profile" ||
          location.pathname === "/sales/admin/tech/skill" ? (
            <>
              <span css={copyTextStyle}>
                <img src="/assets/img/copy.svg" alt="copy" />
              </span>
              <span css={printStyle}>
                <img src="/assets/img/print.svg" alt="print" />
              </span>
              <Text Tag="p" color="black" size="m" propStyle={previewStyle}>プレビュー</Text>
            </>
          ):null
        }
      </div>
      <Button Tag="button" type="primary" size="s" onClick={()=>console.log("chengeUser")} propStyle={buttonStyle}>内容を保存</Button>
   </div>
  )
}
const headingWrapStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.mono300};
`
const headingInnerStyle = (path: string) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 520px;
  width: ${path === "/sales/admin/profile" ? "520px" : "412px"};
`
const TtlWrapStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
`
const copyTextStyle = css`
  width: 20px;
  cursor: pointer;
`
const printStyle = css`
  width: 30px;
  height: 30px;
  cursor: pointer;
`
const previewStyle = css`
  cursor: pointer;
`
const buttonStyle = css`
  width: 129px;
  height: 40px;
  padding: 11px;
  font-size: 16px;
`