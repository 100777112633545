/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form'
import { InputLabel } from "../../../molecules/Form/InputLabel"
import { SelectLabel } from "../../../molecules/Form/SelectLabel";

import { Text } from "../../../atoms/Text"
import Input from "../../../atoms/Form/Input"
import Radio from "../../../atoms/Form/radio"


const defaultProps = {
};

export type Props = {
  register: UseFormRegister<any>
  control: any
} & typeof defaultProps;

export default function InfoForm(props: Props) {
  const { register, control } = props;
  return (
    <div css={formCardWrapStyle}>
      <Text Tag="p" size="xxl" color="black">基本情報</Text>
      <div css={textFormFlexStyle}>
        <InputLabel label="氏名" name="name_kama" type="text" register={register} ></InputLabel>
        <InputLabel label=" " name="name_kama" type="text" register={register} />
      </div>
      <div css={textFormFlexStyle}>
        <InputLabel label="カタカナ" name="name_kama" type="text" register={register} />
        <InputLabel label=" " name="name_kama" type="text" register={register} />
      </div>
      <div css={textFormFlexStyle}>
        <InputLabel label="イニシャル" name="name_kama" type="text" register={register} />
        <InputLabel label=" " name="name_kama" type="text" register={register} />
      </div>
      <SelectLabel label="生年月日" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />
      <div css={fromItemStyle}>
        <Text Tag="p" color="black" size="m" isBold={true}>性別</Text>
        <div css={radioWrapStyle}>
          <Radio name="sex" register={register}>男性</Radio>
          <Radio name="sex" register={register}>女性</Radio>
        </div>
      </div>
      <InputLabel label="電話番号" name="tel" type="text" register={register} propStyle={fromItemStyle} />
      <div css={placeWrapStyle}>
        <SelectLabel label="地域" name="place" options={[{id:1,name:"22222"}]} control={control} propStyle={placeSelectStyle} />
        <div css={placeInputWrapStyle}>
          <Input type="text" name="tel" register={register} />
          <Input type="text" name="tel" register={register} />
        </div>
      </div>
      <div css={fromItemStyle}>
        <Text Tag="p" color="black" size="m" isBold={true}>配偶者</Text>
        <div css={radioWrapStyle}>
          <Radio name="sex" register={register}>あり</Radio>
          <Radio name="sex" register={register}>なし</Radio>
        </div>
      </div>
      <InputLabel label="最終学歴" name="tel" type="text" register={register} propStyle={fromItemStyle} />
      <div css={borderStyle}>
        <SelectLabel label="稼働開始" name="date" options={[{id:1,name:"22222"}]} control={control} propStyle={selectwidthStyle} />
        <div css={fromItemStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>配偶者</Text>
          <div css={radioWrapStyle}>
            <Radio name="sex" register={register}>1時間半以内</Radio>
            <Radio name="sex" register={register}>1時間以内</Radio>
            <Radio name="sex" register={register}>45分</Radio>
            <Radio name="sex" register={register}>30分以内</Radio>
          </div>
        </div>
        <div css={fromItemStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>休日出勤</Text>
          <div css={radioWrapStyle}>
            <Radio name="sex" register={register}>可</Radio>
            <Radio name="sex" register={register}>不可</Radio>
          </div>
        </div>
        <div css={fromItemStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>出張</Text>
          <div css={radioWrapStyle}>
            <Radio name="sex" register={register}>可</Radio>
            <Radio name="sex" register={register}>不可</Radio>
          </div>
        </div>
      </div>
    </div>
  );
}
InfoForm.defaultProps = defaultProps;

const formCardWrapStyle = css`
`
const textFormFlexStyle = css`
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: end;
  >:last-of-type {
    margin-left: 8px;
  }
`
const fromItemStyle = css`
  margin-top: 32px;
`
const radioWrapStyle = css`
  display: flex;
  margin-top: 32px;
  > :first-of-type {
    margin-left: 0;
  }
  > * {
    margin-left: 12px;
  }
`
const placeWrapStyle = css`
  margin-top: 32px;
  display: flex;
  align-items: end;
`
const placeSelectStyle = css`
  width: 245px;
`
const placeInputWrapStyle = css`
  display: flex;
  max-width: 490px;
  width: 100%;
  height: 48px;
  > * {
    margin-left: 8px;
  }
`
const selectwidthStyle = css`
  margin-top: 32px;
  width: 170px;
`
const borderStyle = css`
  margin-top: 32px;
  border-top: 1px solid #DDDDDD;
`