import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "../../App/store";
// import { UserModel } from '../../api/model/user'
import { getGetClient } from '../../api/clients'
import { serializeError } from "serialize-error";

type CompanyState = {
  clients: any
  client: any
  isShow: boolean
  loading: boolean;
  error: any;
};

const initialState: CompanyState = {
  clients: [
    {
      company_id: 1,
      id: 1,
      name: "",
      sale_topics: [
        {
          id: 1,
          name: "",
          low_sales: []
        }
      ],
      topic_count: 1
    }
],
  client: {
    id: "aaa",
    pass: "aaa"
  },
  isShow: false,
  loading: false,
  error: null
};

const client = createSlice({
  name: "client",
  initialState,
  reducers: {
    getGetClientStart(state) {
      state.loading = true;
    },
    getGetClientSuccess(state, action: PayloadAction<any>) {
      const { clients } = action.payload
      state.clients = clients;
      state.loading = false;
      state.error = null;
    },
    getGetClientFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    clickClientDataSuccess(state, action: PayloadAction<any>) {
      const { client } = action.payload
      state.client = client;
      state.isShow = true
      state.loading = false;
      state.error = null;
    },
    clickClientDataFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    }
  }
});

export const {
  getGetClientStart,
  getGetClientSuccess,
  getGetClientFailure,
  clickClientDataSuccess,
  clickClientDataFailure,
  resetError
} = client.actions;

export default client.reducer;

export const fetchClient = ( auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(getGetClientStart());
    const res = await getGetClient(auth)
    console.log(res)
    dispatch(
      getGetClientSuccess({
        clients: res.data,
      })
    );
  } catch (err: any) {
    dispatch(getGetClientFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onClickClientData = ( data:any ): any => async (dispatch:any) => {
  try {
    dispatch(
      clickClientDataSuccess({ client: data })
    );
  } catch (err: any) {
    dispatch(clickClientDataFailure({ message: err.response.data.errors }));
    throw err;
  }
};