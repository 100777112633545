/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { useForm } from "react-hook-form";
import { SalesDetailHeading } from "../../../../molecules/Heading/Sales/Detail";
import { InputLabel } from "../../../../molecules/Form/InputLabel";
import Input from "../../../../atoms/Form/Input"
import { Text } from "../../../../atoms/Text"
import Radio from "../../../../atoms/Form/radio"
import { Button } from "../../../../atoms/Button"
// import { iconStyle } from "../../atoms/Icon";
import { colors } from "../../../../../styles/_var"


const defaultProps = {};

type Props = {
  // users:[
  //   {
  //     name_kana: string;
  //     name_name: string,
  //     kana_kana: string,
  //     kana_name: string,
  //     no: string;
  //     case: []
  //   }
  // ]
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Operation(props: Props) {
  const {
    register
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  return (
    <>
      <SalesDetailHeading>稼働管理</SalesDetailHeading>
      <div css={salesContentWrapStyle}>
        <div>
          <Text Tag="p" color="black" size="m" isBold={true}>案件名</Text>
          <Text Tag="p" color="black" size="xxl" propStyle={matterStyle}>インフラ株式会社 インフラ案件</Text>
        </div>
        <div css={salesFromItemStyle}>
          <InputLabel label={"契約日"} type={"date"} name={"ContractDate"} register={register} propStyle={ContractDateStyle}/>
        </div>
        <div css={[salesFromItemStyle,InputWrapStyle]}>
          <div>
            <Text Tag="p" color="black" size="m" isBold={true}>更新頻度</Text>
            <Input type={"text"} name={"mounth"} propStyle={mounthInputStyle} register={register} placeholder={"2"}></Input>
          </div>
          <Text Tag="p" color="black" size="m" propStyle={formTextStyle}>ヶ月ごと</Text>
          <InputLabel label={"初回更新月"} type={"date"} name={"ContractDate"} register={register} propStyle={ContractDateStyle}/>
        </div>
        <div css={salesFromItemStyle}>
          <div css={feeWrapStyle}>
            <InputLabel label={"単価"} type={"number"} name={"fee"} register={register} propStyle={feeStyle}/>
            <Text Tag="p" color="black" size="m" propStyle={formTextStyle}>円</Text>
          </div>
        </div>
        <div css={[salesFromItemStyle,InputWrapStyle]}>
          <div css={SellingWrapStyle}>
            <div css={feeWrapStyle}>
              <InputLabel label={"給与"} type={"number"} name={"fee"} register={register} propStyle={feeStyle}/>
              <Text Tag="p" color="black" size="m" propStyle={formTextStyle}>円</Text>
            </div>
            <div css={feeWrapStyle}>
              <InputLabel label={"交通費"} type={"number"} name={"travelCosts"} register={register} propStyle={travelCostsStyle}/>
              <Text Tag="p" color="black" size="m" propStyle={formTextStyle}>円</Text>
            </div>
          </div>
        </div>
        <div css={salesFromItemStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>粗利</Text>
          <Text Tag="p" color="orange" size="xxl" isBold={true}>¥71,700-</Text>
        </div>
        <div css={salesFromItemStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>締め日</Text>
          <div css={radioWrapStyle}>
            <Radio val={"1"} name={"label"} register={register}>末締め</Radio>
            <Radio val={"2"} name={"label"} register={register}>その他</Radio>
          </div>
        </div>
        <div css={salesFromItemStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>支払いサイト</Text>
          <div css={radioWrapStyle}>
            <Radio val={"1"} name={"label"} register={register}>30日</Radio>
            <Radio val={"2"} name={"label"} register={register}>45日</Radio>
            <Radio val={"2"} name={"label"} register={register}>60日</Radio>
            <Radio val={"2"} name={"label"} register={register}>その他</Radio>
          </div>
        </div>
        <div css={buttonWrapStyle}>
          <Button Tag={"button"} type={"tertiary"} size={"l"} propStyle={declineButtonStyle}>案件終了</Button>
          <Button Tag={"button"} type={"primary"} size={"l"} propStyle={nextButtonStyle}>案件切り替え</Button>
        </div>
      </div>
    </>
  );
}

Operation.defaultProps = defaultProps;

const salesContentWrapStyle = css`
  margin-top: 32px;
  padding: 32px 40px;
  background-color: ${colors.white};
`
const salesFromItemStyle = css`
  margin-top: 32px;
`
const InputWrapStyle = css`
  display: flex;
  align-items: center;
`
const radioWrapStyle = css`
  display: flex;
  margin-top: 8px;
  > :first-of-type {
    margin-left: 0;
  }
  > * {
    margin-left: 12px;
  }
`
const buttonWrapStyle = css`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DDDDDD;
  margin-top: 32px;
  padding-top: 32px;
`
const declineButtonStyle = css`
  max-width: 120px;
  width: 100%;
`
const nextButtonStyle = css`
  max-width: 186px;
  width: 100%;
`

// 個別スタイル
const matterStyle = css`
  margin-top: 32px;
`
const ContractDateStyle = css`
  width: 170px;
`
const mounthInputStyle = css`
  margin-top: 8px;
  width: 56px;
`
const formTextStyle = css`
  margin-left: 8px;
  padding-top: 27px;
`
const feeWrapStyle = css`
  display: flex;
  align-items: center;
`
const feeStyle = css`
  width: 120px;
`
const SellingWrapStyle = css`
  display: flex;
  align-items: center;
`
const travelCostsStyle = css`
  width: 120px;
  margin-left: 8px;
`