/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form'
import SkilltModal from "../../Modal/SkillModal"
import { Text } from "../../../atoms/Text"
import { colors } from "../../../../styles/_var";
import { iconStyle } from "../../../atoms/Icon";


const defaultProps = {
};

export type Props = {
  modalState: any
  onCreate: any
  setOpenModal: any
  handleSubmit: any
  register: UseFormRegister<any>
  control: any
} & typeof defaultProps;

export default function CareerForm(props: Props) {
  const { register, control } = props;
  return (
    <>
    <SkilltModal
      open={props.modalState}
      onClose={()=>{props.setOpenModal("", false )}}
      hundleCreate={props.onCreate}
      handleSubmit={props.handleSubmit}
      control={control}
      register={register}
    ></SkilltModal>
      <div css={formCardWrapStyle}>
        <Text Tag="p" size="xxl" color="black">職務経歴</Text>
        <Text Tag="p" size="s" color="gray">直近の経歴を新しい順から作成</Text>
        <div css={addFormWrapStyle} onClick={()=>props.setOpenModal( "newSkill", true )}>
          <Text Tag="p" size="xxl" color="black">経歴を追加する</Text>
        </div>
      </div>
    </>
  );
}
CareerForm.defaultProps = defaultProps;

const formCardWrapStyle = css`
`
const addFormWrapStyle = css`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  height: 104px;
  text-align: center;
  background-color: ${colors.orange100};
  border: 1px dashed ${colors.orange300};
  cursor: pointer;
  ${iconStyle("icon-plus_circle")}
  ::before {
    margin: 4px 0 0 8px;
    font-size: 24px;
  }
`