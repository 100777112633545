/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { Text } from "../../../../../atoms/Text"
import { colors } from "../../../../../../styles/_var";
import { Button } from "../../../../../atoms/Button"

// import { useForm } from 'react-hook-form'

const defaultProps = {};

type Props = {
  path: string
  children?: React.ReactNode;
} & typeof defaultProps;

export default function SalesProfileSidebar(props: Props) {
  // const {  } = props
  // const {
  // } = useForm({
  //   mode:"onChange",
  //   criteriaMode: "all",
  //   shouldFocusError: false,
  // });

  // const onSubmit = () => {
  //   const data = getValues()
  //   console.log(data)
  // }
 
  return (
  <>
    <div css={sidebarWrapStyle}>
      <div css={ttlStyle}>
        <Text Tag="p" color="black" size="s" >少ない回数で面談を終わらせるために、記入例を見ながら書いてみましょう。</Text>
      </div>
      <div css={buttonWrapStyle}>
        <Button Tag="button" type="quinary" size="s" >記入例</Button>
      </div>
    </div>
  </>
  );
}

SalesProfileSidebar.defaultProps = defaultProps;

const sidebarWrapStyle = css`
  width: 200px;
  padding: 14px 16px;
  border-left: 1px solid ${colors.mono300};
  min-height:100%;
`
const ttlStyle = css`

`
const buttonWrapStyle = css`
  margin-top: 12px;
`