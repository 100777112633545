/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { buttonColors } from "../../../styles/_var"
type Type = "primary" | "secondary" | "tertiary" | "quaternary" | "quinary" | "senary"
type Props = {
  Tag: "button" | "a"
  type: Type
  size: "s" | "l"
  href?: string
  isDisabled?: boolean
  children?: string
  propStyle?: SerializedStyles
  onClick?: () => void;
}
export const Button: React.FC<Props> = ({ Tag, type, size, href, isDisabled, children, propStyle, ...rest }) => {
  return (
    <Tag css={[buttonStyle(type, size, isDisabled), propStyle]} disabled={isDisabled} href={href} {...rest} >{children}</Tag>
  )
}
const buttonStyle = (type: Type, size: string, isDisabled?: boolean) => css`
  display: block;
  width: 100%;
  border-radius: 4px;
  font-size: ${size === "s"? "12px": "20px"};
  line-height: 10px;
  text-align: center;
  opacity: ${isDisabled ? ".3": "1"};
  cursor: pointer;
  ${setBtnProperties(type, isDisabled)};
  &:disabled {
    cursor: default;
    box-shadow: none;
  }
`
const setBtnProperties = (type: Type, isDisabled?: boolean) => {
  const btnProp = {
    backgroundColor: "",
    color: "",
    border: "",
    boxShadow: "",
    padding: ""
  }
  switch (type) {
    case "primary":
      btnProp["backgroundColor"] = buttonColors.primaryColor
      btnProp["color"] = buttonColors.white
      btnProp["border"] = isDisabled? "1px solid" + buttonColors.white : "1px solid" + buttonColors.borderColor;
      btnProp["padding"] = "18px 0"
      return btnProp
    case "secondary":
      btnProp["backgroundColor"] = buttonColors.secondaryColor
      btnProp["color"] = buttonColors.white
      btnProp["boxShadow"] = "none"
      btnProp["padding"] = "18px 0"
      return btnProp
    case "tertiary":
      btnProp["backgroundColor"] = buttonColors.tertiaryColor
      btnProp["color"] = buttonColors.tertiaryFontColor
      btnProp["boxShadow"] = "none"
      btnProp["padding"] = "18px 0"
      return btnProp
    case "quaternary":
      btnProp["backgroundColor"] = buttonColors.white
      btnProp["color"] = buttonColors.quaternaryFontColor
      btnProp["border"] = "1px solid" + buttonColors.quaternaryBorderColor;
      btnProp["boxShadow"] = "none"
      btnProp["padding"] = "18px 0"
      return btnProp
    case "quinary":
      btnProp["backgroundColor"] = buttonColors.transparent
      btnProp["color"] = buttonColors.quaternaryFontColor
      btnProp["border"] = "1px solid" + buttonColors.quinaryBorderColor;
      btnProp["boxShadow"] = "none";
      btnProp["padding"] = "9px 0";
      return btnProp
    case "senary":
      btnProp["backgroundColor"] = buttonColors.senaryColor
      btnProp["color"] = buttonColors.senaryFontColor
      btnProp["border"] = "1px solid" + buttonColors.senaryBorderColor;
      btnProp["boxShadow"] = "none";
      btnProp["padding"] = "9px 0";
      return btnProp
  }
}