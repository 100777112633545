import { apiClient } from "./client";
import {
  userData,
  userDataFront,
  userDataBack,
  userDataKara,
  usersSideList,
  prefecture,
  occupations,
  agents
} from "../consts/user"
export async function search(tabnum:any,auth:any) {
  switch(tabnum){
    case 0:
      return userData
    case 1:
      return userDataFront
    case 2:
      return userDataBack
    case 3:
      return userDataKara
    case 4:
      return userDataKara
  }
  return userData
}
export async function searchFront(tabnum:any,auth:any) {
  return userDataFront
}

export async function searchUsersSideList(flag:any,auth:any) {
  return usersSideList
}

export async function searchRecruitSidebar(auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.get<any>("/recruits/sidebar",config);
}
export async function searchSalesSidebar(auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.get<any>("/low_sales/sidebar",config);
}
export async function createUser(data:any,auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.post<any>("/employees",data,config);
}
export async function getPrefecture(auth:any) {
  return prefecture
}
export async function getOccupations(auth:any) {
  return occupations
}
export async function getAgents(auth:any) {
  return agents
}