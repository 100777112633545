/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import UserCreateModal from "../../organisms/Modal/UserCreateModal"
import OtherSelectModal from "../../organisms/Modal/OtherSelectModal"
import { TabList } from "../../molecules/TabList"
import { HomeLabel } from "../../molecules/DataList/Home/Label"
import { HomeList } from "../../molecules/DataList/Home/List"
import { Heading } from "../../molecules/Heading/Default";
// import { colors } from "../../../styles/_var";
// import { iconStyle } from "../../atoms/Icon";
import { Status } from "../../../types"

const defaultProps = {};

type Props = {
  users:[
    {
      last_name: string;
      first_name: string,
      last_name_kana: string,
      first_name_kana: string,
      id: string;
      agent_id: string;
      occupation_id: string;
      parent_status: Status;
      childState: string;
      initial_kana: string,
      initial_name: string,
      birth: string;
      sex: string;
      place: string;
      employment: string;
      recruit: {
        front_status: string
      },
      agent: {
        name: string
      },
      occupation: {
        name: string
      }
    }
  ],
  prefecture: any,
  occupations: any,
  agents: any,
  status: any
  hundleChengeList: any
  onCreate: any
  modalState: any
  setOpenModal: (type: string, flag: boolean) => void
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Home(props: Props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors,isValid },
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  const [ openTab, setOpenTab ] = useState(0)
  const submit = (number:any) => {
    setOpenTab(number)
    props.hundleChengeList(number)
  }
  return (
    <>
      <UserCreateModal
        prefecture={props.prefecture}
        occupations={props.occupations}
        agents={props.agents}
        open={props.modalState}
        onClose={()=>{props.setOpenModal("", false )}}
        hundleCreate={props.onCreate}
        handleSubmit={handleSubmit}
        register={register}
        control={control}
        isValid={isValid}
        errors={errors}
        handleOpenModal={props.setOpenModal}
      />
      <OtherSelectModal
        open={props.modalState}
        onClose={()=>{props.setOpenModal("", false )}}
        //ここ投げ先変更
        hundleCreate={props.onCreate}
        handleSubmit={handleSubmit}
        register={register}
        isValid={isValid}
        errors={errors}
      /> 
      <Heading>ホーム</Heading>
      <div css={optionWrapStyle}>
        <TabList openTab={openTab} setOpenTab={submit} list={props.status} />
        {/* <div css={ButtonWrapStyle}>
          <Text Tag="p" color="gray" size="m" propStyle={ButtonStyle}>絞り込み</Text>
        </div> */}
      </div>
      <HomeLabel />
      <ul>
        { props.users.map((item,key) => {
          return (
            <li key={key}>
              <HomeList item={item} />
            </li>
          )
        })}
      </ul>
    </>
  );
}

Home.defaultProps = defaultProps;

const optionWrapStyle = css`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`
// const ButtonWrapStyle = css`
//   display: flex;
//   flex-direction: row-reverse;
//   align-items: center;
//   max-width: 103px;
//   width: 100%;
//   height: 36px;
//   padding: 5px 12px;
//   border: 1px solid ${colors.mono300};
//   border-radius: 4px;
//   ${iconStyle("icon-filter")}
//   &::before {
//     font-size: 16px;
//     margin-left: 5px;
//     cursor: pointer;
//   }
// `
// const ButtonStyle = css`
//   max-width: 103px;
// `