/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { InputLabel } from "../../molecules/Form/InputLabel";
import { Text } from "../../atoms/Text"
// import { RecruitUserDetailModel } from "../../../api/model/recruit"

const defaultProps = {};

type Props = {
  userDetail: any
  statusDisplay: any
  statusNum: any
  register: any
} & typeof defaultProps;

export default function SideMenu(props: Props) {
  const { userDetail, statusDisplay, statusNum, register } = props

  const stepMenu = (state:string,statusDisplay:any) => {
    switch(statusDisplay) {
      case "document":
        return(
          <ul css={sideMenuStyle}>
            { statusNum === "0" ||
              state === "failure" ? (
              <>
                <li>
                  <Text Tag="p" color="black" size="m" isBold>応募媒体</Text>
                  <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.mudium}</Text>
                </li>
                <li>
                  <Text Tag="p" color="black" size="m" isBold>応募日</Text>
                  <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.apply_date}</Text>
                </li>
                <li>
                  <Text Tag="p" color="black" size="m" isBold>担当者</Text>
                  <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.document_recruit_staff}</Text>
                </li>
              </>
            ):(
              <>
                <li>
                  <Text Tag="p" color="black" size="m" isBold>応募媒体</Text>
                  <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.mudium}</Text>
                </li>
                <li>
                  <InputLabel label="応募日" name="apply_date" type="date" register={register} >{userDetail.apply_date}</InputLabel>
                </li>
                <li>
                  <InputLabel label="担当者" name="document_recruit_staff" type="text" register={register} >{userDetail.document_recruit_staff}</InputLabel>
                </li>
              </>
            )
            }
          </ul>
        )
        case "first":
          return(
            <ul css={sideMenuStyle}>
              { statusNum === "0" ||
                state === "failure" ? (
                <>
                  <li>
                    <Text Tag="p" color="black" size="m" isBold>応募日</Text>
                    <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.first_date}</Text>
                  </li>
                  <li>
                    <Text Tag="p" color="black" size="m" isBold>担当者</Text>
                    <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.first_recruit_staff}</Text>
                  </li>
                </>
              ):(
                <>
                  <li>
                    <InputLabel label="面設日" name="first_date" type="date" register={register} >{userDetail.first_date}</InputLabel>
                  </li>
                  <li>
                    <InputLabel label="担当者" name="first_recruit_staff" type="text" register={register} >{userDetail.first_recruit_staff}</InputLabel>
                  </li>
                </>
              )}
            </ul>
          )
        case "second":
          return(
            <ul css={sideMenuStyle}>
              { statusNum === "0" ||
                state === "failure" ? (
                <>
                  <li>
                    <Text Tag="p" color="black" size="m" isBold>応募日</Text>
                    <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.second_date}</Text>
                  </li>
                  <li>
                    <Text Tag="p" color="black" size="m" isBold>担当者</Text>
                    <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.second_recruit_staff}</Text>
                  </li>
                </>
              ):(
                <>
                  <li>
                    <InputLabel label="面設日" name="second_date" type="date" register={register} >{userDetail.second_date}</InputLabel>
                  </li>
                  <li>
                    <InputLabel label="担当者" name="second_recruit_staff" type="text" register={register} >{userDetail.second_recruit_staff}</InputLabel>
                  </li>
                </>
              )}
            </ul>
          )
        case "offer":
          return(
            <ul css={sideMenuStyle}>
              { statusNum === "0" ||
                state === "failure" ? (
                <>
                  <li>
                    <Text Tag="p" color="black" size="m" isBold>応募日</Text>
                    <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.offer_date}</Text>
                  </li>
                  <li>
                    <Text Tag="p" color="black" size="m" isBold>担当者</Text>
                    <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.offer_recruit_staff}</Text>
                  </li>
                </>
              ):(
                <>
                <li>
                  <InputLabel label="面設日" name="offer_date" type="date" register={register} >{userDetail.offer_date}</InputLabel>
                </li>
                <li>
                  <InputLabel label="担当者" name="offer_recruit_staff" type="text" register={register} >{userDetail.offer_recruit_staff}</InputLabel>
                </li>
                </>
              )}
            </ul>
          )
        case "failure":
          return(
            <ul css={sideMenuStyle}>
              <li>
                <Text Tag="p" color="black" size="m" isBold>応募媒体</Text>
                <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.mudium}</Text>
              </li>
              <li>
                <Text Tag="p" color="black" size="m" isBold>応募日</Text>
                <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.apply_date}</Text>
              </li>
              <li>
                <Text Tag="p" color="black" size="m" isBold>担当者</Text>
                <Text Tag="p" color="black" size="l" propStyle={sideMenuContentStyle}>{userDetail.document_recruit_staff}</Text>
              </li>
            </ul>
          )
    }
  }

  return (
    <div>
      {stepMenu(userDetail.status,statusDisplay)}
    </div>
  );
}

SideMenu.defaultProps = defaultProps;

const sideMenuStyle = css`
  /* max-width: 280px; */
  width: 280px;
  padding: 40px;
  background-color: #fff;
  >li {
    margin-top: 32px;
  }
  >:first-of-type{
    margin-top: 0;
  }
`
const sideMenuContentStyle = css`
  padding: 16px 4px;
`