/** @jsxImportSource @emotion/react */
import React,{ useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import SideMenu from "../../../organisms/SideMenu"
import RecruitContent from "../../../organisms/RecruitContent"
import RecruitDetailModal from "../../../organisms/Modal/RecruitDetailModal"
// import { SelectLabel } from "../../../molecules/Form/SelectLabel";
// import { Text } from "../../../atoms/Text"
// import { iconStyle } from "../../../atoms/Icon";
import { RecruitUserDetailModel } from "../../../../api/model/recruit"

const defaultProps = {};

type Props = {
  userDetail: RecruitUserDetailModel
  status: any
  statusDisplay: any
  statusNum: any
  check_items: any
  onSendStepForm: any
  modalState: any
  setOpenModal: any
  children?: React.ReactNode;
  setOpenSnackbar: any
} & typeof defaultProps;

export default function RecruitDetail(props: Props) {
  const {
    control,
    register,
    handleSubmit,
    getValues
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  // const onSubmit = () => {
  //   const data = getValues()
    // console.log(props)
  // }
  const [statuses_attributes,setCheckBox] = useState<any[]>([]);
  const submit = () => {
    const formData = new FormData()
    const data = getValues()
    const item_statuses_attributes_str = JSON.stringify(statuses_attributes)
    formData.append("item_statuses_attributes", item_statuses_attributes_str);
    // form内容ををformDataに追加
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // arrayデータを分割して入れ直す
        value.forEach((v) => {
          formData.append(key + '[]', v)
        })
      } else {
        formData.append(key, value)
      }
    })
    console.log(data)
    console.log(formData)
    props.onSendStepForm(formData)
    if(props.modalState.type === "offer") {
      props.setOpenModal("offerDone", true )
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit(submit)} css={recruitDetailStyle}>
        <RecruitDetailModal
          userDetail={props.userDetail}
          open={props.modalState}
          setOpenModal={props.setOpenModal}
          onClose={()=>{props.setOpenModal("", false )}}
          setOpenSnackbar={props.setOpenSnackbar}
        ></RecruitDetailModal>
        <SideMenu userDetail={props.userDetail} statusDisplay={props.statusDisplay} statusNum={props.statusNum} register={register} />
        <RecruitContent
          userDetail={props.userDetail}
          status={props.status}
          statusDisplay={props.statusDisplay}
          statusNum={props.statusNum}
          check_items={props.check_items}
          statuses_attributes={statuses_attributes}
          setCheckBox={setCheckBox}
          control={control}
          register={register}
          modalOpen={()=>{props.setOpenModal("offer", true )}}
        />
      </form>
    </>
  );
}

RecruitDetail.defaultProps = defaultProps;

const recruitDetailStyle = css`
  display: flex;
  column-gap: 20px;
`