/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../../atoms/Text"
import { colors } from "../../../../../styles/_var"

type Props = {
  propStyle?: SerializedStyles
}
export const RecruitLabel: React.FC<Props> = ({ propStyle }) => {
  return (
    <div css={labelStyle}>
      <Text Tag="p" color="gray" size="s" propStyle={nameWidthStyle}>氏名</Text>
      <Text Tag="p" color="gray" size="s" propStyle={mediumWidthStyle}>媒体</Text>
      <Text Tag="p" color="gray" size="s" propStyle={stateWidthStyle}>ステータス</Text>
    </div>
  )
}

const labelStyle = css`
  display: flex;
  padding: 14px;
  border-bottom: 1px solid #e3e3e3;
  background-color: ${colors.green100};
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 260px;
  min-width: 200px;
  width: 100%;
`
const mediumWidthStyle = css`
  flex: 1;
  max-width: 260px;
  min-width: 200px;
  width: 100%;
`
const stateWidthStyle = css`
  flex: 1;
  max-width: 296px;
  min-width: 200px;
  width: 100%;
  text-align: right;
`