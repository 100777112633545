/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import { InputLabel } from "../../molecules/Form/InputLabel"
// import { Button } from "../../atoms/Button"
import Logo from "../../atoms/Img/logo"
import { Text } from "../../atoms/Text"
import { ErrorMessage } from "../../atoms/ErrorMessage"
import { iconStyle } from "../../atoms/Icon";
import  A  from "../../atoms/A";
import { buttonColors } from "../../../styles/_var"
type Type = "primary" | "secondary" | "tertiary" | "quaternary" | "quinary" | "senary"

const defaultProps = {};

type Props = {
  onSubmit: (data: any) => void
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Login(props: Props) {
  const {
    handleSubmit,
    register,
    formState:{errors}
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  }

  return (
    <>
      <div css={LoginInnerStyle}>
        <Logo type="service" />
        <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>事業を立ち上げる</Text>
        <form onSubmit={handleSubmit(props.onSubmit)} css={formInnerStyle}>
          <InputLabel label={"ログインID"} type={"text"} name={"loginid"} register={register} rules={{required: true}} isError={typeof errors.loginid !== "undefined"} />
          {errors.loginid && <ErrorMessage>文字が入力されていません</ErrorMessage>}
          <div css={passWordStyle}>
            <InputLabel label={"パスワード"} type={isRevealPassword ? 'text' : 'password'} name={"password"} register={register} rules={{required: true}} isError={typeof errors.password !== "undefined"} />
            <span css={passwordDisplayStyle(isRevealPassword)} onClick={togglePassword}></span>
          </div>
          {errors.password && <ErrorMessage>文字が入力されていません</ErrorMessage>}
          {/* <Button Tag={"a"} href="/home" type={"primary"} size={"l"} propStyle={ButtonStyle} isDisabled={!isValid}>ログイン</Button> */}
          <div css={buttonStyle('primary',"l")}>
            <A to="/home">ログイン</A>
          </div>
        </form>
        <div css={LogoWrapStyle}>
          <Logo type="company" />
        </div>
      </div>
      <div css={bgLeftImgStyle}>
        <img src="/assets/img/login_bg_left.png" alt="bg_left" />
      </div>
      <div css={bgRightImgStyle}>
        <img src="/assets/img/login_bg_right.png" alt="bg_right" />
      </div>
    </>
  );
}

Login.defaultProps = defaultProps;

const bgLeftImgStyle = css`
  position: absolute;
  width: 378px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
`
const bgRightImgStyle = css`
  position: absolute;
  width: 318px;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
`
const LoginInnerStyle = css`
  margin: 0 auto;
  padding: 130px 0 30px;
  max-width: 320px;
  z-index: 9;
`
const TextStyle = css`
  margin-top: 30px;
  text-align: center;
`
const formInnerStyle = css`
 > div {
  margin-top: 30px;
  :first-of-type {
    margin-top: 0;
  }
 }
`
// const ButtonStyle = css`
//   margin: 30px auto 0;
//   max-width: 155px;
// `
const LogoWrapStyle = css`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`
const passWordStyle = css`
  position: relative;
`
const passwordDisplayStyle = (show:any) => css`
  position: absolute;
  bottom: 16px;
  right: 15px;
  ${show ? iconStyle("icon-eye"): iconStyle("icon-eye_none")}
`


const buttonStyle = (type: Type, size: string, isDisabled?: boolean) => css`
  display: block;
  width: 100%;
  border-radius: 4px;
  font-size: ${size === "s"? "12px": "20px"};
  line-height: 10px;
  text-align: center;
  opacity: ${isDisabled ? ".3": "1"};
  cursor: pointer;
  ${setBtnProperties(type, isDisabled)};
  &:disabled {
    cursor: default;
    box-shadow: none;
  }
`
const setBtnProperties = (type: Type, isDisabled?: boolean) => {
  const btnProp = {
    backgroundColor: "",
    color: "",
    border: "",
    boxShadow: "",
    padding: ""
  }
  switch (type) {
    case "primary":
      btnProp["backgroundColor"] = buttonColors.primaryColor
      btnProp["color"] = buttonColors.white
      btnProp["border"] = isDisabled? "1px solid" + buttonColors.white : "1px solid" + buttonColors.borderColor;
      btnProp["padding"] = "18px 0"
      return btnProp
    case "secondary":
      btnProp["backgroundColor"] = buttonColors.secondaryColor
      btnProp["color"] = buttonColors.white
      btnProp["boxShadow"] = "none"
      btnProp["padding"] = "18px 0"
      return btnProp
    case "tertiary":
      btnProp["backgroundColor"] = buttonColors.tertiaryColor
      btnProp["color"] = buttonColors.tertiaryFontColor
      btnProp["boxShadow"] = "none"
      btnProp["padding"] = "18px 0"
      return btnProp
    case "quaternary":
      btnProp["backgroundColor"] = buttonColors.white
      btnProp["color"] = buttonColors.quaternaryFontColor
      btnProp["border"] = "1px solid" + buttonColors.quaternaryBorderColor;
      btnProp["boxShadow"] = "none"
      btnProp["padding"] = "18px 0"
      return btnProp
    case "quinary":
      btnProp["backgroundColor"] = buttonColors.transparent
      btnProp["color"] = buttonColors.quaternaryFontColor
      btnProp["border"] = "1px solid" + buttonColors.quinaryBorderColor;
      btnProp["boxShadow"] = "none";
      btnProp["padding"] = "9px 0";
      return btnProp
    case "senary":
      btnProp["backgroundColor"] = buttonColors.senaryColor
      btnProp["color"] = buttonColors.senaryFontColor
      btnProp["border"] = "1px solid" + buttonColors.senaryBorderColor;
      btnProp["boxShadow"] = "none";
      btnProp["padding"] = "9px 0";
      return btnProp
  }
}