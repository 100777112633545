/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../atoms/Text"
import { SelectBox } from "../../../atoms/Form/Select"

export type SelectOptionProps = {
  id: number
  name: string
}
type Props = {
  label: string
  options: SelectOptionProps[]
  propStyle?: SerializedStyles
  name:string
  control:any
  rules?: any
  placeholder?: any
  defaultValue?: any
}
export const SelectLabel: React.FC<Props> = ({label,options,propStyle,name,control,rules,placeholder,defaultValue}) => {
  return (
    <div css={[propStyle]}>
      <Text Tag="p" color="black" size="m" isBold>{label}</Text>
      <SelectBox options={options} name={name} control={control} propStyle={marginStyle} placeholder={placeholder} rules={rules} defaultValue={defaultValue}/>
    </div>
  )
}
const marginStyle = css`
  margin-top: 8px;
`