import { apiClient } from "./client";
import {
  recruitUsers
} from "../consts/recruit"

/**
 * API 定義
 * USER
 *
 */

export async function getRecruitUsers(tabnum:any,failure:any,auth:any) {
  return recruitUsers
}
export async function getRecruitUserDetail(id:any,auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.get<any>(`/recruits/${id}/edit`,config);
}
export async function patchStepForm(id:any,status:any,data:any,auth:any) {
  const config = {
    headers: {
      ...auth
    }
  }
  return apiClient.patch<any>(`/recruits/${id}?status=${status}`,data,config);
}