/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom";
import { Text } from "../../../atoms/Text"
import { colors } from "../../../../styles/_var";
import { iconStyle } from "../../../atoms/Icon";

const defaultProps = {};

type Props = {
  talentDetail:any
  id: any
} & typeof defaultProps;

export const CardOperationManagement: React.FC<Props> = ({
  talentDetail,
  id,
}) => {
  let navigate = useNavigate();
  const onLinkPage = (id:number) => {
    if(!isEmpty){
      // ボタンクリックによるページ遷移
      navigate("/sales/admin/operation",{state:id});
    }
  };
  const isEmpty = (obj:any) => {
    return !Object.keys(obj).length;
  }
  return (
    <div css={cardStyle(isEmpty(talentDetail))} onClick={()=>onLinkPage(id)}>
      <div css={cardTtlWrapStyle}>
        <Text Tag="p" size="xxl" color="black">稼働管理</Text>
      </div>
      <div css={cardContentsWrapStyle}>
        <div css={cardContentsStyle}>
          <Text Tag="p" size="l" color="gray">ステータス</Text>
          <Text Tag="p" size="l" color="black">{talentDetail.state}</Text>
        </div>
        <div css={cardContentsStyle}>
          <Text Tag="p" size="l" color="gray">金額</Text>
          <Text Tag="p" size="l" color="black">{talentDetail.fee}</Text>
        </div>
        <div css={cardContentsStyle}>
          <Text Tag="p" size="l" color="gray">次回更新</Text>
          <Text Tag="p" size="l" color="black">{talentDetail.update}</Text>
        </div>
      </div>
    </div>
  )
}
const cardStyle = (array:any) => css`
  min-height: 208px;
  height: 100%;
  padding: 32px 24px;
  background-color: ${colors.white};
  border: 1px solid ${colors.mono300};
  border-radius: 4px;
  opacity: ${array ? ".3" :null};
`
const cardTtlWrapStyle = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  ${iconStyle("icon-Pencil")}
  &::before {
    color: ${colors.mono350};
    font-size: 22px;
    margin-left: 5px;
    cursor: pointer;
  }
`
const cardContentsWrapStyle = css`
  margin-top: 33px;
  >* {
    margin-top: 20px;
  }
  &:first-of-type {
    margin-top: 0;
  }
`
const cardContentsStyle = css`
  display: flex;
  justify-content: space-between;
  >:first-of-type {
    min-width: 112px;
  }
  >:last-of-type{
    text-align: right;
  }
`