/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Modal } from "../../../atoms/Modal"
import { InputLabel } from "../../../molecules/Form/InputLabel"
import { SelectLabel } from "../../../molecules/Form/SelectLabel";
import { Button } from "../../../atoms/Button"
import { Text } from "../../../atoms/Text"
import Input from "../../../atoms/Form/Input"
import Radio from "../../../atoms/Form/radio"
import { colors } from "../../../../styles/_var"
import { UseFormRegister } from 'react-hook-form';
import { SelectBox } from "../../../atoms/Form/Select";
// import { ErrorMessage } from "../../../atoms/ErrorMessage"


export type ModalProps = {
  prefecture?: any
  occupations?: any,
  agents?: any,
  open: { type: string, flag: boolean };
  hundleCreate?: any
  handleSubmit: any
  onClose: () => void;
  control: any
  register: UseFormRegister<any>
  isValid: any
  errors: any
  handleOpenModal: any
};

export default function UserCreateModal(props: ModalProps) {
  const { prefecture, occupations, agents, onClose, open, register, control, hundleCreate, handleSubmit, isValid, handleOpenModal } = props;
  const modaiWith = (type: string) => {
    switch (type) {
      case "createUser":
        return "1200"
      default :
        return "800" 
    }
  }
  const modalText = (type:string) => {
    switch(type) {
      case "createUser":
        return "応募者登録"
      default :
        return "" 
    }
  }
  const agentOtherSelected = ( data:any ) => {
    console.log(data)
    if( data._json[0].agent_id === 10 ||
      data._json[1].agent_id === 10 ||
      data._json[2].agent_id === 10 ||
      data._json[3].agent_id === 10) {
        hundleCreate(data)
        handleOpenModal("other",true)
    } else{
      hundleCreate(data)
      onClose()
    }
  }

  return (
    <>
      {open.type === "createUser" ? (
        <Modal width={modaiWith(open.type)} isCenter={false} open={open.flag} onClose={onClose}>
          <div css={modalTitleStyle}>{modalText(open.type)}</div>
          <div css={modalContentStyle(open.type)}>
            <form css={modalContentInnerStyle} onSubmit={handleSubmit(agentOtherSelected)}>
              <div css={modalInputWrapStyle}>
                <InputLabel label="氏名" name="_json[0].first_name" type="text" register={register} propStyle={inputNameWithStyle} rules={{required:true}} />
                <InputLabel label="" name="[_json][0][last_name]" type="text" register={register} propStyle={inputNameWithStyle} rules={{required:true}} />
                <InputLabel label={"年齢"} type={"number"} name={"[_json][0][age]"} register={register} propStyle={inputOldWithStyle} rules={{required:true}} />
                <div>
                  <Text Tag="p" color="black" size="m" isBold={true}>性別</Text>
                  <div css={radioWrapStyle}>
                    <Radio name="[_json][0][gender_id]" id={"gender_id_00"} val={1} register={register} rules={{required:true}} >男性</Radio>
                    <Radio name="[_json][0][gender_id]" id={"gender_id_01"} val={2} register={register} rules={{required:true}} >女性</Radio>
                  </div>
                </div>
                <SelectLabel label="地域" name="[_json][0][prefecture_id]" options={prefecture} control={control} propStyle={selectPlaceWidthStyle} rules={{required:true}} />
                <SelectLabel label="志望職種" name="[_json][0][occupation_id]" options={occupations} control={control} propStyle={selectOccupationWidthStyle} rules={{required:true}} />
                <SelectLabel label="流入経路" name="[_json][0][agent_id]" options={agents} control={control} propStyle={selectMudiumWidthStyle} rules={{required:true}} />
              </div>
              <div css={modalInputWrapStyle}>
                <Input name="[_json][1][first_name]" type="text" register={register} propStyle={inputNameWithStyle}/>
                <Input name="[_json][1][last_name]" type="text" register={register} propStyle={inputNameWithStyle}/>
                <Input type={"number"} name={"[_json][1][age]"} register={register} propStyle={inputOldWithStyle} />
                <div css={radioWrapStyle}>
                  <Radio name="[_json][1][gender_id]" id={"gender_id_02"} val={1} register={register} >男性</Radio>
                  <Radio name="[_json][1][gender_id]" id={"gender_id_03"} val={2} register={register} >女性</Radio>
                </div>
                <SelectBox name="[_json][1][prefecture_id]" options={prefecture} control={control} propStyle={selectPlaceWidthStyle} />
                <SelectBox name="[_json][1][occupation_id]" options={occupations} control={control} propStyle={selectOccupationWidthStyle} />
                <SelectBox name="[_json][1][agent_id]" options={agents} control={control} propStyle={selectMudiumWidthStyle} />
              </div>
              <div css={modalInputWrapStyle}>
                <Input name="[_json][2][first_name]" type="text" register={register} propStyle={inputNameWithStyle}/>
                <Input name="[_json][2][last_name]" type="text" register={register} propStyle={inputNameWithStyle}/>
                <Input type={"number"} name={"[_json][2][age[]"} register={register} propStyle={inputOldWithStyle} />
                <div css={radioWrapStyle}>
                  <Radio name="[_json][2][gender_id]" id={"gender_id_04"} val={1} register={register}>男性</Radio>
                  <Radio name="[_json][2][gender_id]" id={"gender_id_05"} val={2} register={register}>女性</Radio>
                </div>
                <SelectBox name="[_json][2][prefecture_id]" options={prefecture} control={control} propStyle={selectPlaceWidthStyle} />
                <SelectBox name="[_json][2][occupation_id]" options={occupations} control={control} propStyle={selectOccupationWidthStyle} />
                <SelectBox name="[_json][2][agent_id]" options={agents} control={control} propStyle={selectMudiumWidthStyle} />
              </div>
              <div css={modalInputWrapStyle}>
                <Input name="[_json][3][first_name]" type="text" register={register} propStyle={inputNameWithStyle}/>
                <Input name="[_json][3][last_name]" type="text" register={register} propStyle={inputNameWithStyle}/>
                <Input type={"number"} name={"[_json][3][age]"} register={register} propStyle={inputOldWithStyle} />
                <div css={radioWrapStyle}>
                  <Radio name="[_json][3][gender_id]" id={"gender_id_06"} val={1} register={register}>男性</Radio>
                  <Radio name="[_json][3][gender_id]" id={"gender_id_07"} val={2} register={register}>女性</Radio>
                </div>
                <SelectBox name="[_json][3][prefecture_id]" options={prefecture} control={control} propStyle={selectPlaceWidthStyle} />
                <SelectBox name="[_json][3][occupation_id]" options={occupations} control={control} propStyle={selectOccupationWidthStyle} />
                <SelectBox name="[_json][3][agent_id]" options={agents} control={control} propStyle={selectMudiumWidthStyle} />
              </div>
              <div css={modalBtnWrapStyle}>
                <Button Tag={"button"} type={"tertiary"} size={"l"} onClick={onClose} propStyle={tertiaryBtnStyle} >キャンセル</Button>
                <Button Tag={"button"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} >登録</Button>
              </div>
            </form>
          </div>
        </Modal>
      ):null}
    </>
  );
}

const modalTitleStyle = css`
  padding: 28px 32px;
  border-bottom: 1px solid ${colors.mono300};
`
const modalContentStyle = (type: string) => css`
  ${setModalProperties(type)};
`
const setModalProperties = (type: string) => {
  const modalProp = {
    padding: "",
    backgroundColor: "",
    color: "",
    border: "",
    boxShadow: ""
  }
  switch (type) {
    case "createUser":
      modalProp["padding"] = "64px"
    return modalProp
  }
}
const modalContentInnerStyle = css`
  >div {
    margin-top: 32px;
  }
  >:first-of-type{
    margin-top: 0;
  }
`
// button
const modalBtnWrapStyle = css`
  padding-top: 32px;
  border-top: 1px solid ${colors.mono300};;
  display: flex;
  justify-content: space-between;
`
const tertiaryBtnStyle = css`
  max-width: 173px;
`
const primaryBtnStyle = css`
  max-width: 121px;
`

const modalInputWrapStyle = css`
  display: flex;
  align-items: end;
  justify-content: space-between;
`

const inputNameWithStyle = css`
  width: 120px;
`
const inputOldWithStyle = css`
  width: 80px;
`
const radioWrapStyle = css`
  display: flex;
  width: 140px;
  margin-top: 32px;
  > :first-of-type {
    margin-left: 0;
  }
  > * {
    margin-left: 12px;
  }
`
const selectPlaceWidthStyle = css`
  width: 130px;
`
const selectOccupationWidthStyle = css`
  width: 160px;
`
const selectMudiumWidthStyle = css`
  width: 200px;
`