/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../atoms/Text"
import { Tag } from "../../../atoms/Tag"
import { Button } from "../../../atoms/Button"

type Props = {
  talentDetail: any
  handleOpenModal :any
  propStyle?: SerializedStyles
}
export const Heading: React.FC<Props> = ({ talentDetail, handleOpenModal, propStyle }) => {
  return (
    <div css={[propStyle,headingStyle]}>
      <div>
        <div css={topWrapStyle}>
          <Text Tag="p" color="gray" size="l" >{"No."+talentDetail.id}</Text>
        </div>
        <div css={middleWrapStyle}>
          <div css={nameStyle}>
            <div>
              <Text Tag="p" color="black" size="xxxl" isBold={true} >{talentDetail.last_name}</Text>
              <Text Tag="p" color="gray" size="l" >{talentDetail.last_name_kana}</Text>
            </div>
            <div>
              <Text Tag="p" color="black" size="xxxl" isBold={true} >{talentDetail.first_name}</Text>
              <Text Tag="p" color="gray" size="l" >{talentDetail.first_name_kana}</Text>
            </div>
          </div>
          <div css={stateWrapStyle}>
            <Tag status={talentDetail.parent_status} size={"l"} />
            <Text Tag="p" color="black" size="xxl" propStyle={childStateStyle}>{talentDetail.recruit.front_status}</Text>
          </div>
        </div>
        <div css={bottomState}>
          <Text Tag="p" color="black" size="l" >{talentDetail.age + "歳"}</Text>
          <Text Tag="p" color="black" size="l" >{talentDetail.gender.name}</Text>
          <Text Tag="p" color="black" size="l" >{talentDetail.prefecture.name}</Text>
          <Text Tag="p" color="black" size="l" >{talentDetail.agent.name}</Text>
        </div>
      </div>
      <Button Tag="button" type="quinary" size="s" propStyle={buttonStyle} onClick={()=>handleOpenModal("chengeUser",true)}>要員情報の編集</Button>
    </div>
  )
}
const headingStyle = css`
  display: flex;
  justify-content: space-between;
`
const topWrapStyle = css`
  margin-bottom: 20px;
`
const middleWrapStyle = css`
  display: flex;
  margin-bottom: 20px;
`
const nameStyle = css`
  display: flex;
  >:first-of-type{
    margin-right: 12px;
  }
`
const stateWrapStyle = css`
  display: flex;
  align-items: center;
  margin-left: 40px;
`
const childStateStyle = css`
  margin-left: 12px;
`
const bottomState = css`
  display: flex;
  justify-content: space-between;
  max-width: 270px;
  width: 100%;
  > * {
    position: relative;
    &::before {
      content: "・";
      position: absolute;
      top: -6px;
      left: -25px;
      font-size: 24px;
      color: #999;
    }
  }
  > :first-of-type {
    &::before {
      content: "";
    }
  }
`
const buttonStyle = css`
  max-width: 140px;
  height: 32px;
`
