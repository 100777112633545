import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "../../App/store";
// import { TalentModel } from '../../api/model/talent'
import { getTalentDetail,chengeUserDetail } from '../../api/talent'
import { serializeError } from "serialize-error";


type TalentLoaded = {
  talentDetail: any
}
type talentState = {
  talentDetail: any
  modalType: string,
  modalFlag: boolean,
  loading: boolean;
  error: any;
};

type ModalState = {
  type: string
  flag: boolean
}

const initialState: talentState = {
  talentDetail:{
    age: 1,
    agent: {name: ""},
    first_name: "",
    first_name_kana: null,
    gender: {name: "男性"},
    id: 1,
    last_name: "",
    last_name_kana: null,
    parent_status: "selection",
    prefecture: {name: ""},
    recruit: {front_status: ""},
  },
  modalType: "",
  modalFlag: false,
  loading: false,
  error: null
};

const talent = createSlice({
  name: "talent",
  initialState,
  reducers: {
    getTalentDetailStart(state) {
      state.loading = true;
    },
    getTalentDetailSuccess(state, action: PayloadAction<TalentLoaded>) {
      const { talentDetail } = action.payload
      state.talentDetail = {...talentDetail};
      state.loading = false;
      state.error = null;
    },
    getTalentDetailFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    chengeUserDetailStart(state) {
      state.loading = true;
    },
    chengeUserDetailSuccess(state, action: PayloadAction<TalentLoaded>) {
      const { talentDetail } = action.payload
      state.talentDetail = talentDetail;
      state.loading = false;
      state.error = null;
    },
    chengeUserDetailFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    changeModalStateSuccess(state, action: PayloadAction<ModalState>) {
      const { type, flag } = action.payload
      state.modalType = type
      state.modalFlag = flag
      state.loading = false;
      state.error = null;
    },
    changeModalStateFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    }
  }
});

export const {
  getTalentDetailSuccess,
  getTalentDetailStart,
  getTalentDetailFailure,
  changeModalStateSuccess,
  changeModalStateFailure,
  chengeUserDetailStart,
  chengeUserDetailSuccess,
  chengeUserDetailFailure,
  resetError
} = talent.actions;
export default talent.reducer;

export const fetchTalentDetail = ( id: string, auth:any, users:any ): any => async (dispatch:any) => {
  try {
    dispatch(getTalentDetailStart());
    const res = await getTalentDetail(id,auth,users)
    dispatch(
      getTalentDetailSuccess({
        talentDetail: res,
      })
    );
  } catch (err: any) {
    dispatch(getTalentDetailFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const changeModalState = ( type: string, flag: boolean ): any => async (dispatch:any) => {
  try {
    dispatch(
      changeModalStateSuccess({type, flag})
    );
  } catch (err: any) {
    dispatch(changeModalStateFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onChengeUserDetail = ( id:any, data:any, auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(chengeUserDetailStart());
    const res = await chengeUserDetail(id,data,auth)
    dispatch(
      chengeUserDetailSuccess({
        talentDetail: res.data.data,
      })
    );
  } catch (err: any) {
    dispatch(chengeUserDetailFailure({ message: err.response.data.errors }));
    throw err;
  }
};