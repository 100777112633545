/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import CompanyModal from "../../organisms/Modal/CompanyModal"
import { CompanyLabel } from "../../molecules/DataList/Company/Label"
import { CompanyList } from "../../molecules/DataList/Company/List"

const defaultProps = {};

type Props = {
  campanys: any
  company: any
  onCreate: any
  onDelete: any
  onChenge: any
  children?: React.ReactNode;
  modalState: {type: string, flag: boolean},
  setOpenModal: (type: string, flag: boolean) => void
} & typeof defaultProps;

export default function Company(props: Props) {
  const {
    handleSubmit,
    register,
    getValues,
    formState:{errors,isValid}
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });
  
  const [openToolTip, setOpenToolTip] = useState({ flag:false,id:1 });
  const [companyData, setCompanyData] = useState({ name:"",loginid:"",id:"" });
  const handleShowToolTip = (id:number) => {
    setOpenToolTip((prevState)=>({flag:!prevState.flag,id:id}))
  }

  return (
    <div css={companyWrapStyle}>
      <CompanyModal
        open={props.modalState}
        company={props.company}
        onClose={()=>(props.setOpenModal("", false ))}
        handleChenge={props.onChenge}
        hundleCreate={()=>props.onCreate}
        handleDelete={()=>props.onDelete(companyData.id)}
        handleSubmit={handleSubmit}
        register={register}
        isValid={isValid}
        errors={errors}
        companyData={companyData}
        getValues={getValues}
      />
      <CompanyLabel/>
      <ul>
        {props.campanys.map((item:any,key:any)=>{
          return (
            <CompanyList
              key={key}
              company={item}
              openToolTip={openToolTip}
              openModal={props.modalState}
              handleOpenModal={props.setOpenModal}
              handleShowToolTip={handleShowToolTip}
              setCompanyData={setCompanyData}
            />
          )
        })}
      </ul>
    </div>
  );
}

Company.defaultProps = defaultProps;

const companyWrapStyle = css`
  width: 100%;
`