/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"

const defaultProps = {
  type: "service",
}

type Props = {
  type: string
  propStyle?: SerializedStyles
} & typeof defaultProps;

export default function Logo(props: Props) {
  const { type,propStyle } = props
  return (
  <div css={[imgWrapper,propStyle]}>
    { type === "service" && (
      <img src="/assets/img/CP_logo.svg" width={200} height={48} alt="logo" />
    )}
    { type === "company" && (
      <img src="/assets/img/EP_logo.svg" width={103} height={17} alt="logo" />
    )}
  </div>
  )
}

Logo.defaultProps = defaultProps;

const imgWrapper = css`
  width: 100%;
`