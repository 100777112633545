export const SalesUsers = {
  data:[
    {
      id: 1,
      first_name: "翔太",
      first_name_kana: "ショウタ",
      last_name: "佐藤",
      last_name_kana: "サトウ",
      low_sales: [
        {
          "sale_topic": {
            "name": "Webシステム開発プロジェクト"
          },
          "status": "面接",
          "start_date": "2022/04/01"
        },
        {
          "sale_topic": {
            "name": "ECサイト構築案件"
          },
          "status": "スキルシート未回収",
          "start_date": "2022/05/10"
        },
      ],
    },
    {
      id: 2,
      first_name: "花子",
      first_name_kana: "ハナコ",
      last_name: "鈴木",
      last_name_kana: "スズキ",
      low_sales: [
        {
          "sale_topic": {
            "name": "モバイルアプリ開発案件"
          },
          "status": "内定：クライアント待ち",
          "start_date": "2022/06/15"
        },
      ],
    },
    {
      id: 3,
      first_name: "大輝",
      first_name_kana: "ダイキ",
      last_name: "高橋",
      last_name_kana: "タカハシ",
      low_sales: [
        {
          "sale_topic": {
            "name": "業務システムリプレース"
          },
          "status": "面接",
          "start_date": "2022/07/20"
        },
        {
          "sale_topic": {
            "name": "IoTデバイス開発"
          },
          "status": "スキルシート未回収",
          "start_date": "2022/08/05"
        },
        {
          "sale_topic": {
            "name": "AIソリューション提案"
          },
          "status": "内定：クライアント待ち",
          "start_date": "2022/09/01"
        },
      ],
    },
    {
      id: 4,
      first_name: "美咲",
      first_name_kana: "ミサキ",
      last_name: "田中",
      last_name_kana: "タナカ",
      low_sales: [
        {
          "sale_topic": {
            "name": "クラウド移行プロジェクト"
          },
          "status": "面接",
          "start_date": "2022/10/18"
        },
      ],
    },
    {
      id: 5,
      first_name: "一樹",
      first_name_kana: "カズキ",
      last_name: "伊藤",
      last_name_kana: "イトウ",
      low_sales: [
        {
          "sale_topic": {
            "name": "ERPシステム導入支援"
          },
          "status": "スキルシート未回収",
          "start_date": "2022/11/22"
        },
      ],
    },
    {
      id: 6,
      first_name: "菜々子",
      first_name_kana: "ナナコ",
      last_name: "山本",
      last_name_kana: "ヤマモト",
      low_sales: [
        {
          "sale_topic": {
            "name": "ビッグデータ解析"
          },
          "status": "内定：クライアント待ち",
          "start_date": "2022/12/13"
        },
        {
          "sale_topic": {
            "name": "インフラ基盤構築"
          },
          "status": "面接",
          "start_date": "2023/01/05"
        },
      ],
    },
    {
      id: 7,
      first_name: "健太",
      first_name_kana: "ケンタ",
      last_name: "中村",
      last_name_kana: "ナカムラ",
      low_sales: [
        {
          "sale_topic": {
            "name": "RPA導入案件"
          },
          "status": "スキルシート未回収",
          "start_date": "2023/02/17"
        },
      ]
    },
    {
      id: 8,
      first_name: "結衣",
      first_name_kana: "ユイ",
      last_name: "小林",
      last_name_kana: "コバヤシ",
      low_sales: [
        {
          "sale_topic": {
            "name": "セキュリティ強化プロジェクト"
          },
          "status": "内定：クライアント待ち",
          "start_date": "2023/03/30"
        },
        {
          "sale_topic": {
            "name": "データベース最適化"
          },
          "status": "面接",
          "start_date": "2023/04/14"
        },
      ],
    },
    {
      id: 9,
      first_name: "彩花",
      first_name_kana: "アヤカ",
      last_name: "加藤",
      last_name_kana: "カトウ",
      low_sales: [
        {
          "sale_topic": {
            "name": "API開発・連携案件"
          },
          "status": "スキルシート未回収",
          "start_date": "2023/05/22"
        },
        {
          "sale_topic": {
            "name": "システム保守・運用案件"
          },
          "status": "内定：クライアント待ち",
          "start_date": "2023/06/08"
        },
        {
          "sale_topic": {
            "name": "新規サービス立ち上げ"
          },
          "status": "面接",
          "start_date": "2023/07/21"
        },
      ]
    },
    {
      id: 10,
      first_name: "翔",
      first_name_kana: "ショウ",
      last_name: "渡辺",
      last_name_kana: "ワタナベ",
      low_sales: [
        {
          "sale_topic": {
            "name": "マイクロサービス構築"
          },
          "status": "スキルシート未回収",
          "start_date": "2023/08/11"
        },
      ],
    }
  ],
  status: [
  ],
}