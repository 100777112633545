import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom"
import { RootState } from "../../../App/rootReducer";
import { fetchSalesUsers,chengeList } from "../salesSlice";
import { changeModalState,onCreateUser,fetchSideBarUsersList } from "../../user/userSlice";

import Sales from "../../../components/pages/Sales";

type Props = {
  children?: React.ReactNode;
};

export default function SalesWithRedux(props: Props) {
  // TODO: as any良くない
  const users = useSelector((state: RootState) => state.sales.salesUsers) as any;
  const status = useSelector((state: RootState) => state.sales.status) as any
  const prefecture = useSelector((state: RootState) => state.user.prefecture) as any;
  const occupations = useSelector((state: RootState) => state.user.occupations) as any;
  const agents = useSelector((state: RootState) => state.user.agents) as any;
  const auth = useSelector((state: RootState) => state.user.auth) as any
  const modalFlag = useSelector((state: RootState) => state.user.modalFlag);
  const modalType = useSelector((state: RootState) => state.user.modalType);
  const dispatch = useDispatch();

  useEffect(() => {
    async function f() {
      // 前処理
      try {
        dispatch(fetchSalesUsers(auth));
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch,auth]);

  const onChengeList = (tab: any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(chengeList(tab,auth))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  const hundleCreateUsers = (data:any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onCreateUser(data,auth))
        await dispatch(fetchSideBarUsersList("0",auth));
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  const onChangeModalState = (type: string, flag: boolean) => {
    async function f() {
      // 前処理
      try {
        await dispatch(changeModalState(type, flag))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
      <Sales
        users={users}
        status={status}
        prefecture={prefecture}
        occupations={occupations}
        agents={agents}
        hundleChengeList={onChengeList}
        onCreate={(data:any) => hundleCreateUsers(data)}
        modalState={{type:modalType,flag:modalFlag}}
        setOpenModal={onChangeModalState}
      />
  );
}
