/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../atoms/Modal"
import { Button } from "../../../atoms/Button"
import { Text } from "../../../atoms/Text"
import { colors } from "../../../../styles/_var"

export type ModalProps = {
  userDetail: any
  open: { type: string, flag: boolean };
  setOpenModal: any
  onClose: () => void;
  setOpenSnackbar: (string:any) => void
};

export default function RecruitDetailModal(props: ModalProps) {
  const { userDetail, onClose, setOpenModal, open, setOpenSnackbar } = props;
  const modaiWith = (type: string) => {
    switch (type) {
      case "offer":
        return "640"
      case "offerDone":
        return "640"
      default :
        return "400" 
    }
  }

  const copyText = () => {
    navigator.clipboard.writeText(
      `@channel 【採用のお知らせ】\n私たちの新しい仲間が加入しましたのでお知らせします。\n━━━━━━━━━━━━━━━━\n名前：${ userDetail.employee.last_name + userDetail.employee.first_name } さん\n志望職種：フロントエンドエンジニア\n━━━━━━━━━━━━━━━━`
    )
    const text = {message:"リンクをコピーしました"}
    setOpenSnackbar(text)
  }
  let navigate = useNavigate();
  const onLinkPage = () => {
    // ボタンクリックによるページ遷移
    navigate("/recruit");
    setOpenModal("", false )
  };

  return (
    <>
      {open.type === "offer" ? (
        <Modal width={modaiWith(open.type)} isCenter={false} open={open.flag} onClose={onClose}>
          <div css={modalTitleStyle}>内定の確認</div>
          <div css={modalContentStyle(open.type)}>
            <div css={modalContentInnerStyle}>
              <div>
                <Text Tag="p" color="black" size="xxl" isBold={false} propStyle={ttlStyle}>{ userDetail.employee.last_name + userDetail.employee.first_name + "を採用しますか？"}</Text>
                <Text Tag="p" color="black" size="l" propStyle={textStyle}>内定を選ぶと採用リストから営業管理へと移動します。</Text>
              </div>
              <div css={modalBtnWrapStyle}>
                <Button Tag={"a"} type={"tertiary"} size={"l"} propStyle={tertiaryBtnStyle} onClick={onClose} >閉じる</Button>
                <Button Tag={"button"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} >採用を確定する</Button>
              </div>
            </div>
          </div>
        </Modal>
      ):null}
      {open.type === "offerDone" ? (
        <Modal width={modaiWith(open.type)} isCenter={false} open={open.flag} onClose={onClose}>
          <div css={modalContentStyle(open.type)}>
            <div css={modalContentInnerStyle}>
              <div>
                <img src="/assets/img/noto_potted-plant.svg" width={84} height={99} alt="pot" />
              </div>
              <div>
                <Text Tag="p" color="black" size="xxl" isBold={false} propStyle={ttlStyle}>{ userDetail.employee.last_name + userDetail.employee.first_name + "さんが"}</Text>
                <Text Tag="p" color="black" size="xxl" isBold={false} propStyle={ttlStyle}>メンバーになりました😘</Text>
                <Text Tag="p" color="black" size="l" propStyle={textStyle}>この喜びを、会社のみんなに知ってもらいましょう！</Text>
                <div css={copyTextWrapStyle}>
                  <Text Tag="p" color="black" size="l" >
                    {"@channel 【採用のお知らせ】"}
                  </Text>
                  <Text Tag="p" color="black" size="l" >
                    {"私たちの新しい仲間が加入しましたのでお知らせします。"}
                  </Text>
                  <Text Tag="p" color="black" size="l" >
                    {"━━━━━━━━━━━━━━━━"}
                  </Text>
                  <Text Tag="p" color="black" size="l" >
                    {"名前：" + userDetail.employee.last_name + userDetail.employee.first_name + "さん"}
                  </Text>
                  <Text Tag="p" color="black" size="l" >
                    {"志望職種：フロントエンドエンジニア"}
                  </Text>
                  <Text Tag="p" color="black" size="l" >
                    {"━━━━━━━━━━━━━━━━"}
                  </Text>
                </div>
              </div>
              <div css={modalBtnWrapStyle}>
                <Button Tag={"a"} type={"quaternary"} size={"l"} onClick={()=>copyText()} propStyle={tertiaryBtnStyle} >文面をコピー</Button>
                <Button Tag={"a"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} onClick={()=>onLinkPage()}>完了</Button>
              </div>
            </div>
          </div>
        </Modal>
      ):null}
    </>
  );
}

const modalTitleStyle = css`
  padding: 28px 32px;
  border-bottom: 1px solid ${colors.mono300};
`
const modalContentStyle = (type: string) => css`
  ${setModalProperties(type)};
`
const setModalProperties = (type: string) => {
  const modalProp = {
    padding: "",
    backgroundColor: "",
    color: "",
    border: "",
    boxShadow: ""
  }
  switch (type) {
    case "offer":
      modalProp["padding"] = "64px 32px"
    return modalProp
    case "offerDone":
      modalProp["padding"] = "73px 80px"
    return modalProp
  }
}
const modalContentInnerStyle = css`
  >div {
    margin-top: 32px;
  }
  >:first-of-type{
    margin-top: 0;
  }
`
const modalBtnWrapStyle = css`
  padding-top: 32px;
  border-top: 1px solid ${colors.mono300};;
  display: flex;
  justify-content: space-between;
`
const ttlStyle = css`
  text-align: center;
`
const textStyle = css`
  margin-top: 40px;
  text-align: center;
`
const primaryBtnStyle = css`
  max-width: 217px;
`
const tertiaryBtnStyle = css`
  max-width: 137px;
`
const copyTextWrapStyle = css`
  margin-top: 40px;
  padding: 16px 12px;
  background-color: ${colors.green100};
  border: 1px solid ${colors.mono300};
  border-radius: 4px;
`