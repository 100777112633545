import { combineReducers } from "@reduxjs/toolkit";
import user from "../features/user/userSlice";
import talent from "../features/talent/talentSlice";
import recruit from "../features/recruit/recruitSlice";
import sales from "../features/sales/salesSlice";
import adminLogin from "../features/adminLogin/adminLoginSlice"
import company from "../features/company/companySlice"
import client from "../features/client/clientSlice"


const rootReducer = combineReducers(
  {
  user,
  talent,
  recruit,
  sales,
  adminLogin,
  company,
  client,
 }
);

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
