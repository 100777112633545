/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { Text } from "../../../../atoms/Text"
// import { iconStyle } from "../../../../atoms/Icon"
import { colors } from "../../../../../styles/_var";
// import { useForm } from 'react-hook-form'

const defaultProps = {};

type Props = {
  recruitSidebar?: any
  children?: React.ReactNode;
} & typeof defaultProps;

export default function RecruitSidebar(props: Props) {
  // const {  } = props
  // const {
  // } = useForm({
  //   mode:"onChange",
  //   criteriaMode: "all",
  //   shouldFocusError: false,
  // });

  // const onSubmit = () => {
  //   const data = getValues()
  //   console.log(data)
  // }
 
  return (
  <>
    <div css={sidebarWrapStyle}>
      <div>
        <Text Tag="p" color="black" size="l" isBold={true}>今月の採用件数</Text>
      </div>
      <ul css={contentWrapStyle}>
        { props.recruitSidebar !== null ? props.recruitSidebar.map((item:any,key:any)=>{
          return(
            <li css={contentStyle} key={key}>
              <Text Tag="p" color="black" size="m" >{item.name}</Text>
              <Text Tag="p" color="black" size="m" >{item.count}</Text>
            </li>
          )
        }):null}
      </ul>
    </div>
  </>
  );
}

RecruitSidebar.defaultProps = defaultProps;

const sidebarWrapStyle = css`
  width: 200px;
  padding: 14px 16px;
  border-left: 1px solid ${colors.mono300};
  min-height:100%;
`
// const ttlStyle = css`
//   display: flex;
//   align-items: center;
//   /* flex-direction: row-reverse; */
//   max-width: 132px;
//   width: 100%;
//   /* ${iconStyle("icon-triangle_bottom")}
//   cursor: pointer; */
// `
const contentWrapStyle = css`
  margin-top: 12px;
  >:first-of-type {
    margin-top: 0;
  }
`
const contentStyle = css`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`