/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from "@emotion/react"
import { Modal } from "../../../atoms/Modal"
import { InputLabel } from "../../../molecules/Form/InputLabel"
import { SelectLabel } from "../../../molecules/Form/SelectLabel";
import { TextareaLabel } from "../../../molecules/Form/TextareaLabel"
import { Button } from "../../../atoms/Button"
import Input from "../../../atoms/Form/Input"
import Checkbox from "../../../atoms/Form/CheckBox"
import { Text } from "../../../atoms/Text"
import { colors } from "../../../../styles/_var"
import { UseFormRegister } from 'react-hook-form';


export type ModalProps = {
  open: { type: string, flag: boolean };
  hundleCreate: any
  handleSubmit: any
  onClose: () => void;
  control: any
  register: UseFormRegister<any>
};

export default function SkilltModal(props: ModalProps) {
  const { onClose, open, register, control, hundleCreate, handleSubmit } = props;
  const modaiWith = (type: string) => {
    switch (type) {
      case "newSkill":
        return "640"
      default :
        return "640" 
    }
  }
  interface checkBoxs {
    id: string;
    name: string;
    checked: boolean;
    disabled: boolean;
  }
  const [tertiaryCheckBox] = useState<checkBoxs[]>([
    {
      id: "1",
      name: "企画",
      checked: true,
      disabled: false,
    },
    {
      id: "2",
      name: "基本設計",
      checked: false,
      disabled: false,
    },
    {
      id: "3",
      name: "詳細設計",
      checked: false,
      disabled: false,
    },
    {
      id: "4",
      name: "開発",
      checked: false,
      disabled: false,
    },
    {
      id: "5",
      name: "テスト",
      checked: false,
      disabled: false,
    },
    {
      id: "6",
      name: "保守運用",
      checked: false,
      disabled: false,
    },
  ]);

  return (
    <>
      {open.type === "newSkill" ? (
        <Modal width={modaiWith(open.type)} isCenter={false} open={open.flag} onClose={onClose}>
          <div css={modalContentStyle(open.type)}>
            <form onSubmit={handleSubmit(hundleCreate)}>

              <div css={modalContentInnerStyle}>
                <div css={textFormFlexStyle}>
                  {/* <SelectLabel label="参画期間" options={[{id:1,name:""}]} control={control} name={"date"} propStyle={dateSelectStyle} /> */}
                  {/* <input type="date" css={dateSelectStyle} {...register("hire_date",{required:true})}/> */}
                  <InputLabel label="参画期間" name="date" type="date" register={register} propStyle={dateSelectStyle} />
                  <p>~</p>
                  <InputLabel label="" name="date" type="date" register={register} propStyle={dateSelectStyle} />
                </div>
               <TextareaLabel label="備考・理由等" name={"doument_remarks"} register={register} />
                <div>
                <Text Tag="p" color="black" size="m" isBold={true}>担当工程</Text>
                  {tertiaryCheckBox.map((item) => {
                    return (
                      <div key={item.id}>
                        <Checkbox
                          id={item.id}
                          value={item.id}
                          defaultChecked={item.checked}
                          label={item.name}
                          name={"myFavoriteFoods"}
                          register={register}
                        />
                      </div>
                    );
                  })}
                </div>
                <SelectLabel label="役割" options={[{id:1,name:""}]} control={control} name={"date"} propStyle={dateSelectStyle} />
                <div css={textFormFlexStyle}>
                  <InputLabel label="言語 / FW ツール / OS" name="name_kama" type="text" register={register} propStyle={skillTtlStyle} />
                  <InputLabel label="用途" name="name_kama" type="text" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>

                <div css={textFormFlexStyle}>
                  <InputLabel label="環境" name="name_kama" type="text" register={register} propStyle={skillTtlStyle} />
                  <InputLabel label="用途" name="name_kama" type="text" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>
                <div css={textFormFlexStyle}>
                  <Input type="text" name="tel" register={register} propStyle={skillTtlStyle} />
                  <Input type="text" name="tel" register={register} propStyle={skillDetailStyle} />
                </div>

              </div>

              <div css={modalBtnWrapStyle}>
                <Button Tag={"button"} type={"tertiary"} size={"l"} onClick={onClose} propStyle={tertiaryBtnStyle} >戻る</Button>
                <Button Tag={"button"} type={"primary"} size={"l"} propStyle={primaryBtnStyle} >保存</Button>
              </div>
            </form>
          </div>
        </Modal>
      ):null}
    </>
  );
}

const modalContentStyle = (type: string) => css`
  ${setModalProperties(type)};
`
const setModalProperties = (type: string) => {
  const modalProp = {
    padding: "",
    backgroundColor: "",
    color: "",
    border: "",
    boxShadow: ""
  }
  switch (type) {
    case "newSkill":
      modalProp["padding"] = "40px"
    return modalProp
  }
}
const modalContentInnerStyle = css`
  >div {
    margin-top: 32px;
  }
  >:first-of-type{
    margin-top: 0;
  }
`
// button
const modalBtnWrapStyle = css`
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid ${colors.mono300};;
  display: flex;
  justify-content: space-between;
`
const tertiaryBtnStyle = css`
  max-width: 173px;
`
const primaryBtnStyle = css`
  max-width: 121px;
`

const textFormFlexStyle = css`
  width: 100%;
  display: flex;
  align-items: end;
  >:last-of-type {
    margin-left: 8px;
  }
`
const dateSelectStyle = css`
  width: 170px;
`
const skillTtlStyle = css`
  width: 200px;
`
const skillDetailStyle = css`
  width: 420px;
`
