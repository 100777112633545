/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { Button } from "../../atoms/Button"
import { colors } from "../../../styles/_var";

const defaultProps = {
};

export type Props = {
  children: React.ReactNode;
  currentStep: number
  prevFormStep: () => void
  nextFormStep: () => void
} & typeof defaultProps;

export default function FormCard(props: Props) {
  const { children, currentStep, prevFormStep, nextFormStep } = props;
  return (
    <div css={formCardWrapStyle}>
      {children}
      <>
        {currentStep === 0 && (
          <div css={buttonWrapStyle}>
            <Button
              Tag={"button"}
              type={"quaternary"}
              size={"s"}
              onClick={nextFormStep}
              propStyle={buttonStyle(currentStep)}
            >
              スキル・資格の入力へ
            </Button>
          </div>
        )}
        {currentStep === 1 && (
          <div css={buttonWrapStyle}>
            <Button
              Tag={"button"}
              type={"tertiary"}
              size={"s"}
              onClick={prevFormStep}
              propStyle={buttonStyle(currentStep)}
            >
              基本情報へ戻る
            </Button>
            <Button
              Tag={"button"}
              type={"quaternary"}
              size={"s"}
              onClick={nextFormStep}
              propStyle={buttonStyle(currentStep)}
            >
              自己PRの入力へ
            </Button>
          </div>
        )}
        {currentStep === 2 && (
          <div css={buttonWrapStyle}>
            <Button
              Tag={"button"}
              type={"tertiary"}
              size={"s"}
              onClick={prevFormStep}
              propStyle={buttonStyle(currentStep)}
            >
              スキル・資格へ戻る
            </Button>
            <Button
                Tag={"button"}
                type={"quaternary"}
                size={"s"}
                onClick={nextFormStep}
                propStyle={buttonStyle(currentStep)}
              >
                職務経歴の入力へ
            </Button>
          </div>
        )}
        {currentStep === 3 && (
          <div css={buttonWrapStyle}>
            <Button
              Tag={"button"}
              type={"tertiary"}
              size={"s"}
              onClick={prevFormStep}
              propStyle={buttonStyle(currentStep)}
            >
              自己PRへ戻る
            </Button>
          </div>
        )}
      </>
    </div>
  );
}
FormCard.defaultProps = defaultProps;

const formCardWrapStyle = css`
  margin-top: 16px;
  padding: 40px 32px;
  background-color: ${colors.white};
`
const buttonWrapStyle = css`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DDDDDD;
  padding-top: 40px;
`
const buttonStyle = (currentStep:number) => css`
  width: 230px;
  color: ${colors.mono900};
  margin: ${currentStep === 0 ? "0 0 0 auto" : null};
`