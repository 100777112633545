/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom";
import { Text } from "../../../../atoms/Text"
import { Tag } from "../../../../atoms/Tag"
import { colors } from "../../../../../styles/_var";
import { Status } from "../../../../../types"

type Props = {
  item: {
    last_name: string;
    first_name: string,
    last_name_kana: string,
    first_name_kana: string,
    id: string;
    agent_id: string;
    occupation_id: string;
    parent_status: Status;
    childState: string;
    initial_kana: string,
    initial_name: string,
    birth: string;
    sex: string;
    place: string;
    employment: string;
    recruit: {
      front_status: string
    },
    agent: {
      name: string
    },
    occupation: {
      name: string
    }
  }
}
export const HomeList: React.FC<Props> = ({ item }) => {
  let navigate = useNavigate();
  const LinkToUserDetailPage = (id:string) => {
    // ボタンクリックによるページ遷移
    navigate("/talent",{state:id});
  };
  return (
    <div css={userListInnerStyle} onClick={()=>LinkToUserDetailPage(item.id)}>
      <div css={nameWidthStyle}>
        <Text Tag="p" color="gray" size="s" >{"No."+ item.id}</Text>
        <Text Tag="p" color="black" size="xl" >{item.last_name + " " + item.first_name}</Text>
        <Text Tag="p" color="black" size="s" >{(item.last_name_kana) + " " + (item.first_name_kana)}</Text>
      </div>
      <Text Tag="p" color="black" size="l" propStyle={idWidthStyle}>{item.agent.name}</Text>
      <Text Tag="p" color="black" size="l" propStyle={idWidthStyle}>{item.occupation.name}</Text>
      <div css={passwordWidthStyle}>
        <Tag status={item.parent_status} size={"s"} />
        <Text Tag="p" color="black" size="l" propStyle={passwordWidthStyle}>{item.recruit.front_status}</Text>
      </div>
    </div>
  )
}
const userListInnerStyle = css`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  border-bottom: 1px solid ${colors.mono300};
  cursor: pointer;
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 272px;
  min-width: 200px;
  width: 100%;
`
const idWidthStyle = css`
  flex: 1;
  max-width: 272px;
  min-width: 200px;
  width: 100%;
`
const passwordWidthStyle = css`
  flex: 1;
  max-width: 272px;
  min-width: 200px;
  width: 100%;
  text-align: right;
`