import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom"
import { RootState } from "../../../App/rootReducer";
import { fetchTalentDetail,onChengeUserDetail } from "../../talent/talentSlice";
import Talent from "../../../components/pages/Talent";
import { changeModalState,onCreateUser,onGetPrefecture,onAgents,fetchSideBarUsersList } from "../../user/userSlice";

type Props = {
  children?: React.ReactNode;
};

export default function TalentWithRedux(props: Props) {
  // TODO: as any良くない
  const users = useSelector((state: RootState) => state.user.users) as any;
  const talentDetail = useSelector((state: RootState) => state.talent.talentDetail) as any;
  const prefecture = useSelector((state: RootState) => state.user.prefecture) as any;
  const occupations = useSelector((state: RootState) => state.user.occupations) as any;
  const agents = useSelector((state: RootState) => state.user.agents) as any;
  const auth = useSelector((state: RootState) => state.user.auth) as any
  const modalFlag = useSelector((state: RootState) => state.user.modalFlag);
  const modalType = useSelector((state: RootState) => state.user.modalType);
  const dispatch = useDispatch();
  const location = useLocation()
  const selectId: string = location.state as string

  useEffect(() => {
    async function f() {
      // 前処理
      try {
        dispatch(fetchTalentDetail(selectId,auth,users));
        // await dispatch(onGetPrefecture(auth))
        // await dispatch(onAgents(auth))
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch,selectId,auth]);

  const hundleChengeUserDetail = (data:any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onChengeUserDetail(talentDetail.id,data,auth))
        await dispatch(changeModalState("", false))
        await dispatch(fetchSideBarUsersList("0",auth));
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  const hundleCreateUsers = (data:any) => {
    async function f() {
      // 前処理
      try {
        await dispatch(onCreateUser(data,auth))
        await dispatch(fetchSideBarUsersList("0",auth));
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }
  const onChangeModalState = (type: string, flag: boolean) => {
    async function f() {
      // 前処理
      try {
        await dispatch(changeModalState(type, flag))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }


  return (
      <Talent
        talentDetail={talentDetail}
        prefecture={prefecture}
        occupations={occupations}
        agents={agents}
        onCreate={(data:any) => hundleCreateUsers(data)}
        onChenge={(data:any) => hundleChengeUserDetail(data)}
        modalState={{type:modalType,flag:modalFlag}}
        setOpenModal={onChangeModalState}
      />
  );
}
