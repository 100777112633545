/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Heading } from "../../molecules/Heading/Default";
import { Text } from "../../atoms/Text"

const defaultProps = {};


export default function Tos() {
  return (
    <>
      <Heading>利用規約</Heading>
      <div css={tosContentWrapStyle}>
        <Text Tag="p" size="l" color="black" >本規約には、本サービスの提供条件ならびに当社とユーザーの皆様の間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。</Text>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第1条（総則）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. この利用規約（以下、「本規約」といいます。）は、株式会社エンジンポット（以下、「当社」といいます。）が提供する本サービスの利用条件を、当社と本サービスを利用する登録ユーザーとの間で定めるものです。</Text>
          <Text Tag="p" size="l" color="black">2. 本サービスの登録ユーザーは、本規約の各条項をすべて同意したうえで本サービスを利用するものとします。</Text>
          <Text Tag="p" size="l" color="black">3. 当社は、合理的理由がある場合、本規約を変更することができるものとします。</Text>
          <Text Tag="p" size="l" color="black">4. 当社が、本規約を変更するときは、効力発生時期を定め、かつ、本規約を変更すること、変更後の本規約の内容及び効力発生時期を通知又はインターネットにより開示するものとします。</Text>
          <Text Tag="p" size="l" color="black">5. 登録ユーザーは、当社が本規約の変更を開示し、かつ、その変更の開示後に本サービスを利用した時点で、本規約の変更に承諾したものとみなすものとします。</Text>
          <Text Tag="p" size="l" color="black">6. 当社は、本条の変更により生じた登録ユーザーに生じた損害について一切の責任を負いません。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第2条（定義）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>本規約では以下の用語を使用します。</Text>
          <Text Tag="p" size="l" color="black">1. 本サービス: 当社が提供する「cloudpot」を意味します。</Text>
          <Text Tag="p" size="l" color="black">2. 本契約: 本規約を含む、当社と登録ユーザーとの間の本サービスの提供に関する契約を意味します。</Text>
          <Text Tag="p" size="l" color="black">3. 登録ユーザー: 本サービスのアカウントを保有し、本サービスを利用されているユーザーを意味します。</Text>
          <Text Tag="p" size="l" color="black">4. 知的財産権：著作権、特許権、実用新案権、意匠権、商標権その他一切の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第3条（本サービスの範囲）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 本サービスでは、ログイン後の機能として、人材管理や承認、採用情報・登録、請求データの生成・登録などを行うことができます。ただし、その全てが提供されることを保証するものではありません。</Text>
          <Text Tag="p" size="l" color="black">2. 日本国内の利用者に対してのみ本サービスを提供します。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第4条（利用期間）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 本サービスの利用は、本サービスのWEBサイトから申込みをした当日より利用できるものとします。</Text>
          <Text Tag="p" size="l" color="black">2. 有料ユーザーの本契約上の利用期間は、サービス利用申込日の翌月1日からとします。</Text>
          <Text Tag="p" size="l" color="black">3. 本契約の解約（退会）を希望する場合は、1ヶ月以上前までにその旨を当社に通知するものとします。本契約の解約発効日（終了日）は、解約申込みが当社に通知された日が属する月の翌月末日とし、その日までは本サービスの利用を可能とし、その日までの利用料金を支払うものとします。</Text>
          <Text Tag="p" size="l" color="black">4. 本サービスの停止とともに登録ユーザーの利用環境で入力および生成されたデータは自動的に削除されるものとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第5条（料金・支払いについて）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 本サービスの利用料金は、別途定める料金表を適用します。詳細は、本サービスのWEBサイトをご確認ください。</Text>
          <Text Tag="p" size="l" color="black">2. 当社は、いつでも料金を改定（変更）することができるものとします。</Text>
          <Text Tag="p" size="l" color="black">3. 利用料金の改定（変更）がある場合は、本サービス内に記載することにより発効し、登録ユーザーは発効後も本サービスを継続して利用することにより改定（変更）に同意したものとします。</Text>
          <Text Tag="p" size="l" color="black">4. 改定（変更）後の料金体系への変更は、次回決済より適用されるものとします。</Text>
          <Text Tag="p" size="l" color="black">5. 登録ユーザーから支払われた本サービスの料金はいかなる理由でも返還しないものとします。</Text>
          <Text Tag="p" size="l" color="black">6. 利用料金以外に支払いに関わる費用が発生する場合は、登録ユーザーの負担とします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第6条（解除等）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 登録ユーザーが次の各号のいずれかに該当したときは、当社は何らの催告を要することなく、直ちに本契約の全部または一部を解除できるものとします。</Text>
          <Text Tag="p" size="l" color="black">（1）本契約の各条項のいずれかに違反したとき。</Text>
          <Text Tag="p" size="l" color="black">（2）料金を期限までにお支払されないとき。</Text>
          <Text Tag="p" size="l" color="black">（3）差押え、仮差押えもしくは競売の申し立てがあったとき、または租税滞納処分を受けたとき。</Text>
          <Text Tag="p" size="l" color="black">（4）破産手続開始、会社更生手続開始もしくは民事再生手続開始の申し立てがあったとき。</Text>
          <Text Tag="p" size="l" color="black">（5）解散したとき、または営業の全部もしくは重要な一部を第三者に譲渡したとき。反社会的勢力との関係があると認められたとき、またはそのおそれがあると当社が合理的に判断したとき。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第7条（登録情報の抹消）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>本サービスのアクセスの有無にかかわらず、データは保管されます。ただし、当社が破産申立をする、または第三者に買収される等、当社の判断でデータ管理ができない状況となった場合には、この限りではありません。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第8条（ユーザーの遵守事項等）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 登録ユーザーは、本サービスの利用にあたり、必要な機器、ソフトウェア、および通信にかかる費用を自ら負担し、必要な環境を自ら構築、運用しなければなりません。</Text>
          <Text Tag="p" size="l" color="black">2. 当社は、運営におけるご連絡は、本サービスのユーザー登録情報であるメールアドレスにて行います。登録ユーザーは、本サービスのユーザー登録情報に変更が生じた場合は、速やかに当該情報の変更手続を行ってください。</Text>
          <Text Tag="p" size="l" color="black">3. 登録ユーザーは、本サービスの利用にあたり、登録情報および一切のデータを、本サービスのサイト上のみに配置するのではなく、常にバックアップしてください。</Text>
          <Text Tag="p" size="l" color="black">4. 登録ユーザーは、登録情報および一切のデータについて、各自の責任において管理してください。万が一、登録ユーザーの登録情報および一切のデータの消失、改変が生じた場合等について、当社は責任を負いません。</Text>
          <Text Tag="p" size="l" color="black">5. 登録ユーザーが本契約に違反して当社に損害を与えた場合、本契約の解約の有無にかかわらず、当該損害について賠償する責任を負うものとします。ただし、登録ユーザーの責めに帰すことができない事由により生じた損害についてはこの限りではないものとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第9条（データバックアップについて）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>当社ではシステム障害時の復旧のために定期的にデータのバックアップを行なっております。個別の登録ユーザーからのご要望に対応するためではございません。またバックアップデータに障害が発生し全てのデータが復旧できない場合もございます。こちらをあらかじめご了承いただき、本サービスをご利用ください。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第10条（利用情報の取扱い）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}> 1. 当社は、本サービスの利用につき登録ユーザーが提供し、または本サービスの利用に関し入力、記録され、蓄積された情報（以下、「利用情報」といいます。）につき、別途当社プライバシーポリシーの定めにより取り扱います。登録ユーザーは、当該プライバシーポリシーに従って当社が登録ユーザーの利用情報を取扱うことについてあらかじめ同意するものとします。</Text>
          <Text Tag="p" size="l" color="black">2. 登録ユーザーは、当社が登録ユーザーの本サービス利用状況（ログイン状況、閲覧ページ、手続き履歴などを含みますが、これらに限られません。）を本サービスのシステムを利用して閲覧・確認することがあることについて、予め承諾するものとします。</Text>
          <Text Tag="p" size="l" color="black">3. 契約期間中に提供され、蓄積された利用情報については、登録ユーザー退会後も本条項の定めに従い、当社は保持をし続けることとさせて頂きます。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第11条（ユーザーの禁止事項及び登録抹消）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかの事由に該当する場合、当社は、当該ユーザーに通知することなく、本サービスの停止および抹消、ならびに今後の本サービスの利用停止をすることができるものとします。</Text>
          <Text Tag="p" size="l" color="black">（1）当社に対して虚偽の通知または報告を行った場合</Text>
          <Text Tag="p" size="l" color="black">（2）第三者のユーザーアカウントを無断で使用した場合</Text>
          <Text Tag="p" size="l" color="black">（3）本サービスのサーバやネットワークシステムに支障を与えた場合</Text>
          <Text Tag="p" size="l" color="black">（4）本サービスと同一または類似するサービスを自らまたは第三者を介して行った場合</Text>
          <Text Tag="p" size="l" color="black">（5）その他当社による本サービスの円滑な提供を妨げ、またはそのおそれのある一切の行為を行った場合</Text>
          <Text Tag="p" size="l" color="black">（6）本契約のいずれかの条項に違反した場合</Text>
          <Text Tag="p" size="l" color="black">（7）当社からの問い合わせその他回答を求める連絡に対し、14日以上応答がない場合</Text>
          <Text Tag="p" size="l" color="black">（8）支払い停止もしくは支払不能となり、または破産手続、民事再生、会社更生の手続きの開始、特別清算開始もしくはこれらに対する手続きの開始の申立てがあった場合</Text>
          <Text Tag="p" size="l" color="black">（9）その他当社が本サービスの利用、登録ユーザーとしての登録、またはサービス利用契約の継続を適当でないと判断した場合</Text>
          <Text Tag="p" size="l" color="black">2. 前項各号のいずれかの事由に該当した場合、登録ユーザーは当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対してすべての債務を支払わなければなりません。</Text>
          <Text Tag="p" size="l" color="black">3. 当社は、本条に基づき、当社が行った行為につき、登録ユーザーに生じた損害について一切の責任を負いません。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第12条（情報保護とサービス運営）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 当社は、本サービスに登録された情報を、プライバシーポリシーに従い、最大限の注意を払い管理します。</Text>
          <Text Tag="p" size="l" color="black">2. 当社は、本サービスの提供が存続することに努力いたします。</Text>
          <Text Tag="p" size="l" color="black">3. 当社は、天変事変その他の事由により本サービスの提供が行えない事態が生じた場合でも、登録ユーザーが本サービスを使用することができない時間を最小にするよう努力いたします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第13条（免責事項）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 当社は、本サービスの提供において、以下の内容を保証するものではありません。</Text>
          <Text Tag="p" size="l" color="black">（1）本サービスの提供が中断、中止および廃止されることがないこと</Text>
          <Text Tag="p" size="l" color="black">（2）本サービスの利用に際してエラーやバグが発生しないこと</Text>
          <Text Tag="p" size="l" color="black">（3）本サービスの利用にあたり保存および管理される登録情報その他のデータが消失しないこと</Text>
          <Text Tag="p" size="l" color="black">（4）本サービスの提供においてセキュリティなどに関する欠陥がないこと</Text>
          <Text Tag="p" size="l" color="black">2. 本サービスの利用にあたり、登録ユーザーの機器、ネットワーク、データなどに損害や故障が発生した場合、登録ユーザー自身に一切の責任があるものとし、当社は責任を負いません。</Text>
          <Text Tag="p" size="l" color="black">3. 当社は、本サービスの提供にあたり、登録ユーザーまたは第三者への損害に対し、一切の責任を負いません。</Text>
          <Text Tag="p" size="l" color="black">4. 当社の故意または重過失による債務不履行または不法行為により登録ユーザーに生じた損害については、当該損害が発生した月の月額利用料を上限として賠償いたします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第14条（知的財産権）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 本サービスに関する知的財産権はすべて当社または当社にライセンスを許諾している者に帰属しており、登録ユーザーには帰属しません。</Text>
          <Text Tag="p" size="l" color="black">2. 当社は、登録ユーザーに対し、本契約記載の条件に従い、日本国内において、本サービスを使用し、ログイン後の機能によって可能な行為のみを行うための非独占的かつ譲渡不可能な権利（再使用許諾権は含みません）を許諾します。</Text>
          <Text Tag="p" size="l" color="black">3. 本契約で明確に許諾されたものを除き、登録ユーザーが本サービスを利用することによって、本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用が許諾されるものではありません。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第15条（データの権利について）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>当社は、利用情報につき、個人を特定できない方法によりこれを匿名加工のうえ、当該情報に関する総合・集団的な分析、編集、複製、配布、分析結果などの作成、派生著作物の作成を自らの裁量にて、登録ユーザーから個別に事前の告知・承認を得ることなく利用・公開・提供することができるものとし、登録ユーザーはこれをあらかじめ承諾することとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第16条（サービスの中断･中止･廃止）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 当社は、当社の判断により、本サービスの全部または一部を中断、停止または内容の変更を行うことができるものとします。</Text>
          <Text Tag="p" size="l" color="black">  a. なお、本サービスの中断または停止を行う場合は、30日以上前にWEBサイトの掲載またはメールの送信によって登録ユーザーに対して告知を行います。</Text>
          <Text Tag="p" size="l" color="black">2. 当社は、登録ユーザーと連絡が取れない場合、当該登録ユーザーに対する本サービスの提供を中断または停止することがあります。</Text>
          <Text Tag="p" size="l" color="black">3. 前２項による本サービスの中断、停止または内容の変更によって登録ユーザーに生じた損害または損失について、当社は一切責任を負わないものとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第17条（サービスの停止）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 当社は、以下のいずれかの事由が生じた場合、登録ユーザーへの通知なくして、本サービスの全部または一部の提供を中断または停止することができます。かかる場合に登録ユーザーに生じた損害または損失について、当社は一切責任を負わないものとします。</Text>
          <Text Tag="p" size="l" color="black">（1）天変事変その他の非常事態が発生し、または発生するおそれがある場合</Text>
          <Text Tag="p" size="l" color="black">（2）本サービスの提供を行うための電気通信設備に障害その他やむを得ない事由が生じた場合</Text>
          <Text Tag="p" size="l" color="black">（3）本サービスの提供を行うための電気通信設備の保守・工事等を定期的または緊急に行う場合</Text>
          <Text Tag="p" size="l" color="black">（4）法令による規制、裁判所の決定等がなされた場合</Text>
          <Text Tag="p" size="l" color="black">（5）その他当社が停止または中断を必要と判断した場合</Text>
          <Text Tag="p" size="l" color="black">2. 当社のWEBサイトは、システムの一部を外部システム（Amazon Web Services等）に異存しております。そのため、当社の帰責性なく上記システムが利用不可能となる場合が想定され、その場合当社のWEBサイトも利用できなくなる可能性があります。この場合、当社は登録ユーザーに生じた損害について、一切の責任を負わないものとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第18条（第三者への委託）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>当社は、本契約に基づく当社の業務の全部または一部を第三者に委託して行わせることができるものとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第19条（秘密保持義務）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 登録ユーザーは、本サービスの仕様に関する情報、本サービスに関する書面（契約書、提案書、見積書、注文書、請求書等を含みます）および当社が秘密である旨明記した情報について、厳に秘密を保持し、当社の事前の書面による同意なく、第三者に開示または漏洩してはならないものとします。</Text>
          <Text Tag="p" size="l" color="black">2. 当社は、登録ユーザーが秘密である旨明記した情報について、厳に秘密を保持し、登録ユーザーの事前の書面による同意なく、第三者に開示または漏洩いたしません。</Text>
          <Text Tag="p" size="l" color="black">3. 前各項の規定は、以下の各号に定める情報については適用されないものとします。</Text>
          <Text Tag="p" size="l" color="black">（1）開示を受けたときに既に保有していた情報</Text>
          <Text Tag="p" size="l" color="black">（2）開示を受けた後、秘密保持義務を負うことなく第三者から正当に入手した情報</Text>
          <Text Tag="p" size="l" color="black">（3）開示を受けたときに既に公知の情報</Text>
          <Text Tag="p" size="l" color="black">（4）開示を受けた後、自己の責めに帰すべき事由によらず公知になった情報</Text>
          <Text Tag="p" size="l" color="black">（5）独自に開発した情報</Text>
          <Text Tag="p" size="l" color="black">4. 当社および登録ユーザーは、第１項および第２項の定めにかかわらず、法令に基づき秘密情報の開示が要求された場合、事前に相手方に通知し、可能な限りの秘密保護措置を講じた上で、必要最小限の範囲で当該秘密情報を開示できるものとします。</Text>
          <Text Tag="p" size="l" color="black">5. 第2項の定めにかかわらず、当社は、当社のグループ会社（当社の関係会社および当社と同一の親会社を有する会社をいいます。以下同じ）の事業運営の目的のために、本契約の存在および登録ユーザーと当社との取引履歴等（以下、「契約情報」といいます。）を当社のグループ会社に開示・提供できるものとし、当該グループ会社は契約情報をかかる目的の範囲内で利用できるものとします。この場合、当社は、当該グループ会社による契約情報の利用について責任を負うものとします。</Text>
          <Text Tag="p" size="l" color="black">6. 本条所定の義務は、本契約終了後なお３年間有効に存続するものとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第20条（反社会的勢力の排除）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>1. 当社および登録ユーザーは、相手方に対し、自らまたはその代表者、責任者、もしくは実質的に経営権を有する者が、反社会的勢力等（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者を意味します。以下同じ）に該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。</Text>
          <Text Tag="p" size="l" color="black">2. 当社および登録ユーザーは、相手方に対し、以下の各号に掲げる行為を行わないことを確約します。</Text>
          <Text Tag="p" size="l" color="black">（1）暴力的な手法による要求をすること</Text>
          <Text Tag="p" size="l" color="black">（2）法的な責任を超えた不当な要求をすること</Text>
          <Text Tag="p" size="l" color="black">（3）取引に関して、脅迫的な言動をし、または暴力を用いること</Text>
          <Text Tag="p" size="l" color="black">（4）風説を流布し、偽計を用いまたは威力を用いて当社および登録ユーザーの信用を毀損し、または当社もしくは 登録ユーザーの業務を妨害すること</Text>
          <Text Tag="p" size="l" color="black">（5）反社会的勢力等である第三者をして前各号の行為をおこなわせること</Text>
          <Text Tag="p" size="l" color="black">（6）反社会的勢力等に対して名目の如何を問わず資金提供を行うこと</Text>
          <Text Tag="p" size="l" color="black">（7）第三者が反社会的勢力等と知りながら、当該第三者と取引を行うこと</Text>
          <Text Tag="p" size="l" color="black">（8）代表者等が犯罪行為に関連する行為もしくは公序良俗に違反するような行為、あるいは幇助すること</Text>
          <Text Tag="p" size="l" color="black">（9）その他前各号に準ずる者</Text>
          <Text Tag="p" size="l" color="black">3. 当社および登録ユーザーは、自らが第１項の表明、確約に違反し、もしくは前項各号に該当する行為を行い、またはその恐れがあることが判明した場合、直ちに相手方にその旨を通知しなければならないものとします。</Text>
          <Text Tag="p" size="l" color="black">4. 当社および登録ユーザーは、互いに相手方による反社会的勢力等との関係の有無に関する調査に協力し、相手方から求められた事項については、客観的、合理的なものである限り、これに応じるものとします。</Text>
          <Text Tag="p" size="l" color="black">5. 当社および登録ユーザーは、相手方が前各項に違反した場合には、何らの催告なしに直ちに、当社と登録ユーザー間で締結した一切の契約を解除することができます。</Text>
          <Text Tag="p" size="l" color="black">6. 当社および登録ユーザーは、前項に基づき契約を解除したことにより、相手方に発生した損害について、何らの責任を負わないものとします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="xxl" color="black" >第21条（準拠法・管轄裁判）</Text>
          <Text Tag="p" size="l" color="black" propStyle={tosTextStyle}>本契約の準拠法は日本法とします。本サービスに起因または関連して当社との間に生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</Text>
        </div>
        <div css={tosContentWrapStyle}>
          <Text Tag="p" size="l" color="black" >2023年1月10日 策定</Text>
        </div>
      </div>
    </>
  );
}

Tos.defaultProps = defaultProps;
const tosContentWrapStyle = css`
  margin-top: 32px;
`
const tosTextStyle = css`
  margin-top: 16px;
`