import React from "react";
import { hot } from "react-hot-loader/root";
import AppRouter from "../router";
// import { BrowserRouter as Router } from "react-router-dom";
import RootWithRouter from "../features/location/Root/index";

const App = () => {
  return (
    <>
      <RootWithRouter>
        <AppRouter />
      </RootWithRouter>
    </>
  );
};

export default process.env.NODE_ENV === "development" ? hot(App) : App;
