/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
// import { css } from "@emotion/react"
import { useForm } from "react-hook-form";
import { SalesDetailHeading } from "../../../../molecules/Heading/Sales/Detail";
import FormCard from "../../../../organisms/FormCard"
import Formstepbar from "../../../../molecules/Formstepbar"
import InfoForm from "../../../../organisms/FromStep/Info"
import SkillForm from "../../../../organisms/FromStep/Skill"
import PrForm from "../../../../organisms/FromStep/Pr"
import CareerForm from "../../../../organisms/FromStep/Career"
// import Input from "../../../../atoms/Form/Input"
// import { Text } from "../../../../atoms/Text"
// import Radio from "../../../../atoms/Form/radio"
// import { Button } from "../../../../atoms/Button"
// import { iconStyle } from "../../atoms/Icon";
// import { colors } from "../../../../../styles/_var"


const defaultProps = {};

type Props = {
  modalState: any
  setOpenModal: (type: string, flag: boolean) => void
  children?: React.ReactNode;
} & typeof defaultProps;

export default function TechSkill(props: Props) {
  const {
    control,
    register,
    handleSubmit
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });
  const [formStep, setFormStep] = useState(0);

  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);
  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);

  // const onSubmit = () => {
  //   const data = getValues()
    // console.log(props)
  // }
  const onCreate = () => {
    console.log("tab")
  }

  return (
    <>
      <SalesDetailHeading>スキルシート</SalesDetailHeading>
      <Formstepbar currentStep={formStep} />
      <FormCard currentStep={formStep} prevFormStep={prevFormStep} nextFormStep={nextFormStep}>
        {formStep === 0 && (
          <InfoForm register={register} control={control}></InfoForm>
        )}
        {formStep === 1 && (
          <SkillForm register={register} control={control}></SkillForm>
        )}
        {formStep === 2 && (
          <PrForm register={register} control={control}></PrForm>
        )}
        {formStep === 3 && (
          <CareerForm modalState={props.modalState} onCreate={onCreate} handleSubmit={handleSubmit} register={register} control={control} setOpenModal={props.setOpenModal}></CareerForm>
        )}
      </FormCard>
    
    </>
  );
}

TechSkill.defaultProps = defaultProps;