import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom"
import { RootState } from "../../../App/rootReducer";
// import { fetchSalesUsers,onAddOccupations } from "../salesSlice";
import { onAddOccupations } from "../salesSlice";

import Profile from "../../../components/pages/Sales/Admin/Profile";

type Props = {
  children?: React.ReactNode;
};

export default function SalesWithRedux(props: Props) {
  // TODO: as any良くない
  const occupations = useSelector((state: RootState) => state.sales.occupations) as any;
  const prefecture = useSelector((state: RootState) => state.user.prefecture) as any;
  const dispatch = useDispatch();

  useEffect(() => {
    async function f() {
      // 前処理
      try {
        // dispatch(fetchSalesUsers());
      } catch (error) {
        console.log("error:", error);
      }
      // 後処理
    }
    f();
  }, [dispatch]);

  const onAddOccupation = () => {
    async function f() {
      // 前処理
      try {
        await dispatch(onAddOccupations())
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
      <Profile
        occupations={occupations}
        prefecture={prefecture}
        addOccupations={onAddOccupation}
      />
  );
}
