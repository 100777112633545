/** @jsxImportSource @emotion/react */
import React,{ useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import { InputLabel } from "../../molecules/Form/InputLabel"
import { Button } from "../../atoms/Button"
import Logo from "../../atoms/Img/logo"
import { Text } from "../../atoms/Text"
import { ErrorMessage } from "../../atoms/ErrorMessage"
import { iconStyle } from "../../atoms/Icon";

const defaultProps = {};

type Props = {
  onSubmit: (data: any) => void
  children?: React.ReactNode;
} & typeof defaultProps;

export default function AdminLogin(props: Props) {
  const {
    handleSubmit,
    register,
    formState:{errors,isValid}
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

    // パスワード表示制御ようのstate
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const togglePassword = () => {
      setIsRevealPassword((prevState) => !prevState);
    }


  return (
    <>
      <div css={LoginInnerStyle}>
        <Logo type="service" />
        <Text Tag="p" color="black" size="l" isBold={false} propStyle={TextStyle}>管理画面</Text>
        <form onSubmit={handleSubmit(props.onSubmit)} css={formInnerStyle}>
          <InputLabel label={"ログインID"} type={"text"} name={"loginid"} register={register} rules={{required: true}} isError={typeof errors.loginid !== "undefined"} />
          {errors.loginid && <ErrorMessage>文字が入力されていません</ErrorMessage>}
          <div css={passWordStyle}>
            <InputLabel label={"パスワード"} type={isRevealPassword ? 'text' : 'password'} name={"password"} register={register} rules={{required: true}} isError={typeof errors.password !== "undefined"} />
            <span css={passwordDisplayStyle(isRevealPassword)} onClick={togglePassword}></span>
          </div>
          {errors.password && <ErrorMessage>文字が入力されていません</ErrorMessage>}
          <Button Tag={"button"} type={"primary"} size={"l"} propStyle={ButtonStyle} isDisabled={!isValid} >ログイン</Button>
        </form>
      </div>
    </>
  );
}

AdminLogin.defaultProps = defaultProps;

const LoginInnerStyle = css`
  margin: 68px auto 0;
  max-width: 320px;
`
const TextStyle = css`
  margin-top: 15px;
  text-align: center;
  font-size: 32px;
`
const formInnerStyle = css`
  margin-top: 40px;
 > div {
  margin-top: 30px;
  :first-of-type {
    margin-top: 0;
  }
 }
`
const ButtonStyle = css`
  margin: 40px auto 0;
  max-width: 155px;
`
const passWordStyle = css`
  position: relative;
`
const passwordDisplayStyle = (show:any) => css`
  position: absolute;
  bottom: 16px;
  right: 15px;
  ${show ? iconStyle("icon-eye"): iconStyle("icon-eye_none")}
`