/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { tagColors } from "../../../styles/_var"
import { Status } from "../../../types"

type Size = "s" | "l" 

type Props = {
  status: Status
  size: Size
  propStyle?: SerializedStyles
}
export const Tag: React.FC<Props> = ({status, size, propStyle}) => {
  const setStatus = (status:Status) => {
    switch (status) {
      case "selection":
        return "選考中"
      case "education":
        return "教育中"
      case "sales":
        return "営業中"
      case "work":
        return "入職中"
      default:
        return "no status"
    }
  }
  return(
    <span css={[tagStyle(status,size), propStyle]}>{ setStatus(status) }</span>
  )
}
const tagStyle = ( status: Status, size:Size) => css`
  display: inline-block;
  padding: 2px 0;
  text-align: center;
  align-items: center;
  ${tagSize(size)}
  ${tagColor(status)}
  line-height: 1;
  letter-spacing: 0.03em;
  &::before {
    font-size: 10px;
    margin-right: 1px;
  }
`
const tagColor = (status: Status) => {
  const colorProps = {
    "border": "",
    "color": "",
  }
  switch (status) {
    case "selection":
      colorProps["border"] = "1px solid" + tagColors.defaultBorderColor
      colorProps["color"] = tagColors.defaultColor
      break
    case "education":
      colorProps["border"] = "1px solid" + tagColors.defaultBorderColor
      colorProps["color"] = tagColors.defaultColor
      break
    case "sales":
      colorProps["border"] = "1px solid" + tagColors.defaultBorderColor
      colorProps["color"] = tagColors.defaultColor
      break
    case "work":
      colorProps["border"] = "1px solid" + tagColors.workBorderColor
      colorProps["color"] = tagColors.workColor
      break
  }
  return colorProps
}
const tagSize = (size: Size) => {
  const sizeProps = {
    "width": "",
    "font-size": "",
    "padding": ""
  }
  switch (size) {
    case "s":
      sizeProps["width"] = "45px;"
      sizeProps["font-size"] = "12px"
      sizeProps["padding"] = "2px 0"
      break
    case "l":
      sizeProps["width"] = "65px;"
      sizeProps["font-size"] = "16px"
      sizeProps["padding"] = "8px 0"
      break
  }
  return sizeProps
}