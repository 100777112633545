/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import React from "react"
import Select from 'react-select'
import { Controller } from "react-hook-form";
import { iconStyle } from "../../Icon";
import { colors, formColors } from "../../../../styles/_var"

export type SelectOptionProps = {
  id: number
  name: string
}

type Props = {
  options: any
  isDisabled?: boolean
  propStyle?: SerializedStyles
  placeholder?: string
  name: string
  rules?: any
  control: any
  defaultValue?: any
}

export const SelectBox: React.FC<Props> = (props) => {
  const { options,isDisabled, propStyle, placeholder, name, rules, control, defaultValue } = props

  const formatOptionLabel = (options:any) => <React.Fragment>{options.name}</React.Fragment>;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => <Select
        {...field}
        id={name}
        instanceId={name}
        options={options}
        styles={selectStyles}
        css={[bgStyle(isDisabled), propStyle]}
        placeholder={placeholder ? placeholder : ""}
        isDisabled={isDisabled}
        formatOptionLabel={formatOptionLabel}
        defaultValue={defaultValue}
        value={options.find((x:any) => x.id === field.value)}
        onChange={(newValue) => {
          field.onChange(newValue?.id);
        }}
        isSearchable={false}
      />}
    />
  )
}
// selectのスタイル
// * select全体のスタイル
const bgStyle = (isDisabled?: boolean) => css`
  background-color:${isDisabled ? "#fff" : colors.green100};
  border: 1px solid ${colors.mono300};
  border-radius: 4px;
  height: 48px;
  position: relative;
  ${iconStyle("icon-triangle_bottom")}
  cursor: pointer;
  &::before {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 22px;
    margin-right: 5px;
    transform: translateY(-50%);
  }
`
const controlStyle = {
  "display": "flex",
  "width": "100%",
  "height": "100%",
  "padding": "12px",
  "backgroundColor": "unset",
  "borderRadius": "4px",
  "fontSize": "15px",
  "lineHeight": "1.4",
  "letterSpacing": "0.03em",
}
const controlFn = () => (controlStyle);

// * selectBoxのテキスト部分のスタイル
const valueContainerStyle = {
  "padding": 0
}
const valueContainerFn = (base: any) => ( Object.assign(base, valueContainerStyle) )

// * placeholerのスタイル
const placeholderStyle = {
  "margin": "0",
  "color": `${formColors.placeholderColor}`
}
const placeholderFn = (base: any) => (Object.assign(base, placeholderStyle))

// * 入力エリアのスタイル
const inputStyle = {
  "margin": "0px",
  "padding": "0px"
}
const inputFn = (base: any) => (Object.assign(base, inputStyle))

// *テキストと矢印を分けるセパレート部分のスタイル
const indicatorSeparatorStyle = {
  "display":"none"
}
const indicatorSeparatorFn = () => (indicatorSeparatorStyle)

// * 矢印部分のスタイル
const indicatorContainerStyle = {
  "display":"none",
}
const indicatorContainerFn = (base: any) => (Object.assign(base, indicatorContainerStyle))

// * 選択モーダルのスタイル
const menuStyle = {
  "margin": "0",
  "padding": "0 12px",
  "boxShadow": "0px 0px 10px rgba(0, 0, 0, 0.2)",
}
const menuFn = (base: any) => (Object.assign(base, menuStyle))

// * menuListのスタイル
const menuListStyle = {
  "padding": "0"
}
const menuListFn = (base: any) => (Object.assign(base, menuListStyle))

// * optionのスタイル
const optionStyle = {
  "padding": "12px 0",
  "borderBottom": `1px solid ${formColors.bgColor}`,
}
const optionFn = () => (optionStyle)

// * 全部のスタイルをまとめたオブジェクト
const selectStyles = {
  control: controlFn,
  valueContainer: valueContainerFn,
  placeholder: placeholderFn,
  input: inputFn,
  indicatorSeparator: indicatorSeparatorFn,
  dropdownIndicator: indicatorContainerFn,
  menu: menuFn,
  menuList: menuListFn,
  option: optionFn
}