/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import { SalesHeading } from "../../molecules/Heading/Sales";
import { ClientLabel } from "../../molecules/DataList/Client/Label"
import { ClientList } from "../../molecules/DataList/Client/List"
// import { Text } from "../../atoms/Text"
import Input from "../../atoms/Form/Input"
import { Button } from "../../atoms/Button"
import { colors } from "../../../styles/_var";
// import { iconStyle } from "../../atoms/Icon";

const defaultProps = {};

type Props = {
  clients: any
  clickClientData: any
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Client(props: Props) {
  const {
    register
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  const [openToolTip, setOpenToolTip] = useState({ flag:false });
  const handleShowToolTip = () => {
    setOpenToolTip((prevState)=>({flag:!prevState.flag}))
  }

  return (
    <>
      <SalesHeading openToolTip={openToolTip} handleShowToolTip={handleShowToolTip}>営業先管理</SalesHeading>
      <div css={companyFromStyle}>
        <Input type={"text"} register={register} name={"company"} placeholder={"企業名"} />
        <Button Tag={"button"} type={"quinary"} size={"s"} propStyle={ButtonWrapStyle}>登録</Button>
      </div>
      <ClientLabel />
      <ul>
        { props.clients.map((item:any,key:any) => {
          return (
            <li key={key}>
              <ClientList item={item} clickClientData={props.clickClientData}/>
            </li>
          )
        }) }
      </ul>
    </>
  );
}

Client.defaultProps = defaultProps;

const companyFromStyle = css`
  margin-top: 30px;
  display: flex;
  background-color: #fff;
  padding: 16px;
`
const ButtonWrapStyle = css`
  margin-left: 10px;
  align-items: center;
  max-width: 81px;
  width: 100%;
  padding: 5px 12px;
  border: 1px solid ${colors.orange300};
  border-radius: 4px;
`
// const ButtonStyle = css`
//   max-width: 103px;
// `