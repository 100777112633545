/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form';
import { InputLabel } from "../../molecules/Form/InputLabel"
import { TextareaLabel } from "../../molecules/Form/TextareaLabel"
import { Button } from "../../atoms/Button"
// import  Checkbox  from "../../atoms/Form/CheckBox"
import { Text } from "../../atoms/Text";
import { iconStyle } from "../../atoms/Icon";
// import { iconStyle } from "../.././atoms/Icon";
// import { RecruitUserDetailModel } from "../../../api/model/recruit"


const defaultProps = {};

type Props = {
  userDetail: any
  status: any
  statusDisplay: any
  statusNum: any
  check_items: any
  statuses_attributes: any
  setCheckBox: any
  control: any
  register: UseFormRegister<any>
  modalOpen: any
} & typeof defaultProps;

export default function RecruitContent(props: Props) {
  const { userDetail, statusNum, statusDisplay, statuses_attributes, setCheckBox, register, modalOpen } = props

  const changeChecked = (id:any,statusItem:any) => {
    console.log(statusItem.target.checked)
    setCheckBox([ ...statuses_attributes, {id:id,status:statusItem.target.checked}]);
    statuses_attributes.map((u:any) =>{
      if( u.id !== id){
        // 初回
        return setCheckBox([ ...statuses_attributes, {id:id,status:statusItem.target.checked}]);
      }else{
        // ２回目以降
        let num = 0
        const keys = Object.keys(statuses_attributes);
        for (let i=0; i < keys.length; i++) {
          let key: any = keys[i];
          let val = statuses_attributes[key];
          if (val.id === id) {
            num = i;
          }
        }
        setCheckBox(statuses_attributes.splice(num,1))
        return setCheckBox([ ...statuses_attributes, {id:id,status:statusItem.target.checked}]);
      }
    })
  }

  const recruitContent = (state:string,statusDisplay:any) => {

    switch(statusDisplay) {
      case "document":
        return(
          <div css={recruitContentStyle}>
            { statusNum === "0" ||
              state === "failure" ? (
              <>
                <Text Tag="p" size="s" color="black" isBold>プロフィール・書類URL</Text>
                <Text Tag="p" size="l" color="black" >{userDetail.profile_url}</Text>
                <Text Tag="p" size="s" color="black" isBold>備考・理由等</Text>
                <Text Tag="p" size="l" color="black" >{userDetail.document_remarks}</Text>
              </>
            ):(
              <>
                <InputLabel label={"プロフィール・書類URL"} type={"text"} name={"prifile_url"} register={register} >{userDetail.profile_url}</InputLabel>
                <TextareaLabel label="備考・理由等" name={"document_remarks"} register={register} >{userDetail.document_remarks}</TextareaLabel>
                <div css={buttonWrapStyle}>
                  <Button Tag={"button"} type={"tertiary"} size={"l"} propStyle={declineButtonStyle}>辞退</Button>
                  <Button Tag={"button"} type={"senary"} size={"l"} propStyle={nextButtonStyle}>次の選考</Button>
                </div>
              </>
            )}
          </div>
        )
        case "first":
          return(
            <div css={recruitContentStyle}>
              { statusNum === "0" ||
                state === "failure" ? (
                <>
                  <Text Tag="p" size="s" color="black" isBold>必須承諾項目</Text>
                  <Text Tag="p" size="l" color="black" >選択されたものが入る</Text>
                  <Text Tag="p" size="s" color="black" isBold>備考・理由等</Text>
                  <Text Tag="p" size="l" color="black" >{userDetail.first_remarks}</Text>
                </>
              ):(
                <>
                  <Text Tag="p" size="s" color="black" isBold>必須承諾項目</Text>
                  <div>
                    {props.check_items.first.map((item:any) => {
                      return (
                        <div key={item.id}>
                          <div>
                            <input
                              id={item.id}
                              type="checkbox"
                              value={item.id}
                              defaultChecked={item.status === "true" ? true : false }
                              css={inputStyle}
                              onClick={(e)=>changeChecked(item.id,e)}
                            />
                            <label htmlFor={item.id}>{item.name}</label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <TextareaLabel label="備考・理由等" name={"first_remarks"} register={register} >{userDetail.first_remarks}</TextareaLabel>
                  <div css={buttonWrapStyle}>
                    <Button Tag={"button"} type={"tertiary"} size={"l"} propStyle={declineButtonStyle}>辞退</Button>
                    <Button Tag={"button"} type={"senary"} size={"l"} propStyle={nextButtonStyle}>次の選考</Button>
                  </div>
                </>
              )}
            </div>
          )
        case "second":
          return(
            <div css={recruitContentStyle}>
              { statusNum === "0" ||
                state === "failure" ? (
                  <>
                    <Text Tag="p" size="s" color="black" isBold>必須承諾項目</Text>
                    <Text Tag="p" size="l" color="black" >選択されたものが入る</Text>
                    <Text Tag="p" size="s" color="black" isBold>備考・理由等</Text>
                    <Text Tag="p" size="l" color="black" >{userDetail.second_remarks}</Text>
                  </>
              ):(
                <>
                  <Text Tag="p" size="s" color="black" isBold>必須承諾項目</Text>
                  <div>
                    {props.check_items.second.map((item:any) => {
                      return (
                        <div key={item.id}>
                            <div>
                            <input
                              id={item.id}
                              type="checkbox"
                              value={item.id}
                              defaultChecked={item.status === "true" ? true : false }
                              css={inputStyle}
                              onClick={(e)=>changeChecked(item.id,e)}
                            />
                            <label htmlFor={item.id}>{item.name}</label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <TextareaLabel label="備考・理由等" name={"second_remarks"} register={register} >{userDetail.second_remarks}</TextareaLabel>
                  <div css={buttonWrapStyle}>
                    <Button Tag={"button"} type={"tertiary"} size={"l"} propStyle={declineButtonStyle}>辞退</Button>
                    <Button Tag={"button"} type={"senary"} size={"l"} propStyle={nextButtonStyle}>次の選考</Button>
                  </div>
                </>
              )}
            </div>
          )
        case "offer":
          return(
            <div css={recruitContentStyle}>
              { statusNum === "0" ||
                state === "failure" ? (
                  <>
                    <Text Tag="p" size="s" color="black" isBold>必須承諾項目</Text>
                    <Text Tag="p" size="l" color="black" >選択されたものが入る</Text>
                  </>
              ):(
                <>
                  <Text Tag="p" size="s" color="black" isBold>必須承諾項目</Text>
                  <div>
                    {props.check_items.offer.map((item:any) => {
                      return (
                        <div key={item.id}>
                          <div>
                            <input
                              id={item.id}
                              type="checkbox"
                              value={item.id}
                              defaultChecked={item.status === "true" ? true : false }
                              css={inputStyle}
                              onClick={(e)=>changeChecked(item.id,e)}
                            />
                            <label htmlFor={item.id}>{item.name}</label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div css={buttonWrapStyle}>
                    <Button Tag={"button"} type={"tertiary"} size={"l"} propStyle={declineButtonStyle}>辞退</Button>
                    <Button Tag={"a"} type={"primary"} size={"l"} propStyle={nextButtonStyle} onClick={modalOpen}>内定・採用する</Button>
                  </div>
                </>
              )}
            </div>
          )
          case "failure":
            return(
              <div css={recruitContentStyle}>
                <Text Tag="p" size="s" color="black" isBold>プロフィール・書類URL</Text>
                <Text Tag="p" size="l" color="black" >{userDetail.profile_url}</Text>
                <Text Tag="p" size="s" color="black" isBold>備考・理由等</Text>
                <Text Tag="p" size="l" color="black" >{userDetail.document_remarks}</Text>
            </div>
            )
    }
  }

  return (
    <>
      {recruitContent(userDetail.status,statusDisplay)}
    </>
  );
}

RecruitContent.defaultProps = defaultProps;

const recruitContentStyle = css`
  max-width: 740px;
  width: 100%;
  padding: 40px 32px;
  background-color: #fff;
  >:first-of-type {
    margin-top: 0;
  }
  >div {
    margin-top: 32px;
  }
  >p {
    margin-top: 32px;
  }
`
const buttonWrapStyle = css`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DDDDDD;
  padding-top: 40px;
`
const declineButtonStyle = css`
  max-width: 120px;
  width: 100%;
`
const nextButtonStyle = css`
  max-width: 186px;
  width: 100%;
`

const inputStyle = css`
  padding: 12px;
  ${iconStyle("icon-check_state_off")}
  :checked {
    ${iconStyle("icon-check_state_on")}
  }
`