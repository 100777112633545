import React from "react";
import { useDispatch } from "react-redux";
// import LinearProgress from "@material-ui/core/LinearProgress";
// import { RootState } from "../../../App/rootReducer";
import Header from "../../../components/organisms/Header/Admin";
import { changeModalState } from "../userSlice";

type Props = {
  children?: React.ReactNode;
};

export default function HeaderWithRedux(props: Props) {
  // const modalFlag = useSelector((state: RootState) => state.user.modalFlag);
  // const loading = useSelector((state: RootState) => state.user.loading);

  const dispatch = useDispatch();


  const onChangeModalState = (type: string, flag: boolean) => {
    async function f() {
      // 前処理
      try {
        await dispatch(changeModalState(type, flag))
      } catch (error) {
        console.log('error:', error)
      }
      // 後処理
    }
    f()
  }

  return (
    <React.Fragment>
      <Header setOpenModal={onChangeModalState}/>
    </React.Fragment>
  );
}
