/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom";
import { Text } from "../../../../atoms/Text"
import { StateBar } from "../../../../atoms/StateBar";
import { colors } from "../../../../../styles/_var";

type Props = {
  item: any
}
export const RecruitList: React.FC<Props> = ({ item }) => {
  let navigate = useNavigate();
  const LinkToUserDetailPage = (id:any) => {
    // ボタンクリックによるページ遷移
    navigate("/recruit/detail",{state:id});
  };
  return (
    <div css={userListInnerStyle} onClick={()=>LinkToUserDetailPage(item.id)}>
      <div css={nameWidthStyle}>
        <Text Tag="p" color="gray" size="s" >{"No."+ item.id}</Text>
        <Text Tag="p" color="black" size="xl" >{item.last_name + " " + item.first_name}</Text>
        <Text Tag="p" color="black" size="s" >{(item.last_name_kana) + " " + (item.first_name_kana)}</Text>
      </div>
      <Text Tag="p" color="black" size="l" propStyle={idWidthStyle}>{item.agent.name}</Text>
      <StateBar state={item.recruit.front_status} />
    </div>
  )
}
const userListInnerStyle = css`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  border-bottom: 1px solid ${colors.mono300};
  cursor: pointer;
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 272px;
  min-width: 200px;
  width: 100%;
`
const idWidthStyle = css`
  flex: 1;
  max-width: 272px;
  min-width: 200px;
  width: 100%;
`