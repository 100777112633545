/** @jsxImportSource @emotion/react */
import * as React from "react";
import { css,SerializedStyles } from "@emotion/react"
import { colors, zIndexes, mq } from "../../../styles/_var"
type Props = {
  width: string
  isCenter?: boolean
  children: any
  open: boolean;
  onClose: (isComplete: boolean) => void;
  propStyle?: SerializedStyles;
}
export const Modal: React.FC<Props> = (props) => {
  const { width, isCenter, children, open, onClose, propStyle} = props;
  const handleClose = () => {
    onClose(false);
  };
  return (
    <>
      { open && (
        <>
          <div css={ backgroundStyle } onClick={()=>handleClose()} />
          <div css={[propStyle ? propStyle : containerStyle(width, isCenter)]} >
            {children}
          </div>
        </>
      )}
    </>
  )
}

const backgroundStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(51,51,51,.3);
  z-index: ${zIndexes.modal};
`

const containerStyle = (width: string, isCenter?: boolean) => css`
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${width}px;
  max-height: 80vh;
  overflow: scroll;
  background-color: ${colors.white};
  z-index: ${zIndexes.loader};
  ${isCenter ? centerStyle : ""}
  ${mq['u-sp']}{
    top: auto;
    bottom: 0;
    width: 100%;
    transform: translate(-50%, 0);
  }
`
const centerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`
