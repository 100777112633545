/** @jsxImportSource @emotion/react */
import { css,SerializedStyles } from "@emotion/react"
import { Text } from "../../../../atoms/Text"
import { colors } from "../../../../../styles/_var"

type Props = {
  propStyle?: SerializedStyles
}
export const SalesLabel: React.FC<Props> = ({ propStyle }) => {
  return (
    <div css={[labelStyle, propStyle]}>
      <Text Tag="p" color="gray" size="s" isBold propStyle={nameWidthStyle}>氏名</Text>
      <Text Tag="p" color="gray" size="s" isBold propStyle={caseStyle}>案件名</Text>
      <Text Tag="p" color="gray" size="s" isBold propStyle={dateStyle}>提案日</Text>
      <Text Tag="p" color="gray" size="s" isBold propStyle={stateStyle}>ステータス</Text>
    </div>
  )
}

const labelStyle = css`
  margin-top: 40px;
  display: flex;
  padding: 14px;
  border-bottom: 1px solid ${colors.mono400};
  background-color: ${colors.green100};
`
const nameWidthStyle = css`
  flex: 1;
  max-width: 160px;
  min-width: 150px;
  width: 100%;
`
const caseStyle = css`
  flex: 1;
  max-width: 392px;
  min-width: 392px;
  width: 100%;
`
const dateStyle = css`
  flex: 1;
  max-width: 72px;
  min-width: 72px;
  width: 100%;
`
const stateStyle = css`
  flex: 1;
  max-width: 160px;
  min-width: 160px;
  width: 100%;
  text-align: right;
`