import { css } from "@emotion/react"

export const base = css`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  img {
    width: 100%;
    vertical-align: middle;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: opacity .5s;

    &:hover {
      opacity: .6;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`