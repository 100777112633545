/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react"
import { colors } from "../../../styles/_var";

const defaultProps = {};

type Props = {
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Notfound(props: Props) {

  return (
    <>
      <div css={LoginInnerStyle}>
        <div>
          <p css={mainTextStyle}>404</p>
          <p css={subTextStyle}>NOT FOUND</p>
        </div>
      </div>
      <div css={bgLeftImgStyle}>
        <img src="/assets/img/login_bg_left.png" alt="bg_left" />
      </div>
      <div css={bgRightImgStyle}>
        <img src="/assets/img/login_bg_right.png" alt="bg_right" />
      </div>
    </>
  );
}

Notfound.defaultProps = defaultProps;

const mainTextStyle = css`
  font-weight: 400;
  font-size: 160px;
  line-height: 204px;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${colors.orange500}
`
const subTextStyle = css`
  font-weight: 400;
  font-size: 47px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${colors.orange500}
`
const bgLeftImgStyle = css`
  position: absolute;
  width: 378px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
`
const bgRightImgStyle = css`
  position: absolute;
  width: 318px;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
`
const LoginInnerStyle = css`
  margin: 0 auto;
  padding: 130px 0 30px;
  max-width: 320px;
  z-index: 9;
`