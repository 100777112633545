/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import UserCreateModal from "../../organisms/Modal/UserCreateModal"
import { Heading } from "../../molecules/Heading/Default";
import { TabList } from "../../molecules/TabList"
import { RecruitLabel } from "../../molecules/DataList/Recruit/Label"
import { RecruitList } from "../../molecules/DataList/Recruit/List";
// import Checkbox from "../../atoms/Form/CheckBox" 
// import { recruitTabs } from "../../../consts";
// import { colors } from "../../../styles/_var";

const defaultProps = {};

type Props = {
  users:any
  status:any
  prefecture: any,
  occupations: any,
  agents: any,
  hundleChengeList: any
  onCreate: any
  modalState: any
  setOpenModal: (type: string, flag: boolean) => void
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Recruit(props: Props) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors,isValid },
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  // const onSubmit = () => {
  //   const data = getValues()
    // console.log(props)
  // }
  const [ openTab, setOpenTab ] = useState(0)
  const [ failureTab ] = useState(false)
  const submit = (number:any) => {
    setOpenTab(number)
    props.hundleChengeList(number,failureTab)
  }
  // const failureTabSubmit = (val:any) => {
  //   setfailureTab(val)
  //   props.hundleChengeList(openTab,val)
  // }
  return (
    <>
      <UserCreateModal
        prefecture={props.prefecture}
        occupations={props.occupations}
        agents={props.agents}
        open={props.modalState}
        onClose={()=>{props.setOpenModal("", false )}}
        hundleCreate={props.onCreate}
        handleSubmit={handleSubmit}
        register={register}
        control={control}
        isValid={isValid}
        errors={errors}
        handleOpenModal={props.setOpenModal}
      />
      <Heading>採用</Heading>
      <div css={optionWrapStyle}>
        <TabList openTab={openTab} setOpenTab={submit} list={props.status} />
        {/* <div css={ButtonWrapStyle}>
          <Checkbox
            id={"1"}
            value={failureTab}
            label={"無効応募を表示する"}
            name={"aaa"}
            register={register}
            onClick={failureTabSubmit}
          />
        </div> */}
      </div>
      <RecruitLabel />
      <ul>
        { props.users.map((item:any,key:any) => {
          return (
            <li key={key}>
              <RecruitList item={item} />
            </li>
          )
        }) }
      </ul>
    </>
  );
}

Recruit.defaultProps = defaultProps;

const optionWrapStyle = css`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`
// const ButtonWrapStyle = css`
//   font-size: 14px;
//   color: ${colors.mono600};
//   max-width: 170px;
//   width: 100%;
//   cursor: pointer;
//   &::before {
//     font-size: 16px;
//   }
// `