/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { UseFormRegister } from 'react-hook-form';
import { iconStyle } from "../../Icon";

const defaultProps = {
  name: "hoge"
}

type Props = {
  id: any
  propStyle?: any
  value?: any
  defaultChecked?: any
  register: UseFormRegister<any>
  name: string
  rules?: any
  label: string;
  onClick?: any
} & typeof defaultProps;

export default function Checkbox(props: Props) {
  const { id, label, register, name, defaultChecked } = props
  const valCheck = (value: boolean) => {
    if(value === true){
      props.onClick(false)
    } else {
      props.onClick(true)
    }
  }

  return (
    <div key={id}>
      <input
        id={id}
        type="checkbox"
        value={id}
        defaultChecked={defaultChecked === "true" ? true : false }
        css={inputStyle}
        {...register(name) }
        onClick={()=>valCheck(props.value)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

Checkbox.defaultProps = defaultProps;

const inputStyle = css`
  padding: 12px;
  ${iconStyle("icon-check_state_off")}
  :checked {
    ${iconStyle("icon-check_state_on")}
  }
`