/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Text } from "../Text"
import { colors } from "../../../styles/_var";

const defaultProps = {};

type Props = {
  state: string
} & typeof defaultProps;

export const StateBar: React.FC<Props> = ({
  state
}) => {
  const stateGraph = (state:string) => {
    switch(state) {
      case "書類選考":
        return(
          <>
            <div css={stateTextStyle}>
              <Text Tag="p" size="s" color="black" isBold>書類選考</Text>
              <Text Tag="p" size="s" color="gray">一次選考</Text>
              <Text Tag="p" size="s" color="gray">二次選考</Text>
              <Text Tag="p" size="s" color="gray">内々定</Text>
            </div>
            <div css={stateBarStyle}>
              <div css={stateOpalWrapStyle}>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateGrayOpalStyle}></span>
                <span css={stateGrayOpalStyle}></span>
                <span css={stateGrayOpalStyle}></span>
              </div>
              <span css={stateOrangeBarStyle(state)}></span>
              <span css={stateGrayBarStyle(state)}></span>
            </div>
          </>
        )
      case "一次選考":
        return(
          <>
            <div css={stateTextStyle}>
              <Text Tag="p" size="s" color="orange" isBold>書類選考</Text>
              <Text Tag="p" size="s" color="black" isBold>一次選考</Text>
              <Text Tag="p" size="s" color="gray">二次選考</Text>
              <Text Tag="p" size="s" color="gray">内々定</Text>
            </div>
            <div css={stateBarStyle}>
              <div css={stateOpalWrapStyle}>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateGrayOpalStyle}></span>
                <span css={stateGrayOpalStyle}></span>
              </div>
              <span css={stateOrangeBarStyle(state)}></span>
              <span css={stateGrayBarStyle(state)}></span>
            </div>
          </>
        )
      case "二次選考":
        return(
          <>
            <div css={stateTextStyle}>
              <Text Tag="p" size="s" color="orange" isBold>書類選考</Text>
              <Text Tag="p" size="s" color="orange" isBold>一次選考</Text>
              <Text Tag="p" size="s" color="black" isBold>二次選考</Text>
              <Text Tag="p" size="s" color="gray">内々定</Text>
            </div>
            <div css={stateBarStyle}>
              <div css={stateOpalWrapStyle}>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateGrayOpalStyle}></span>
              </div>
              <span css={stateOrangeBarStyle(state)}></span>
              <span css={stateGrayBarStyle(state)}></span>
            </div>
          </>
        )
      case "内々定":
        return(
          <>
            <div css={stateTextStyle}>
              <Text Tag="p" size="s" color="orange" isBold>書類選考</Text>
              <Text Tag="p" size="s" color="orange" isBold>一次選考</Text>
              <Text Tag="p" size="s" color="orange" isBold>二次選考</Text>
              <Text Tag="p" size="s" color="black" isBold>内々定</Text>
            </div>
            <div css={stateBarStyle}>
              <div css={stateOpalWrapStyle}>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateOrangeOpalStyle}></span>
                <span css={stateOrangeOpalStyle}></span>
              </div>
              <span css={stateOrangeBarStyle(state)}></span>
              <span css={stateGrayBarStyle(state)}></span>
            </div>
          </>
        )
    }
  }
  return (
    <div>
      {stateGraph(state)}
    </div>
  )
}

const stateTextStyle = css`
  display: flex;
  column-gap: 5px;
`
const stateBarStyle = css`
  width: 144px;
  margin: -8px auto;
  position: relative;
`
const stateOrangeBarStyle = (state:string) => css`
  display: inline-block;
  width: 120px;
  width: ${orangeBarwidth(state)};
  height: 2px;
  background-color: ${colors.orange500};
`
const orangeBarwidth = (state:string) => {
  switch (state) {
    case "書類選考":
      return "24px"
    case "一次選考":
      return "72px"
    case "二次選考":
      return "120px"
    case "内々定":
      return "144px"
    default:
      return "24px"
  }
}
const stateGrayBarStyle = (state:string) => css`
  display: inline-block;
  width: ${grayBarwidth(state)};
  height: 2px;
  background-color: ${colors.mono300};
`
const grayBarwidth = (state:string) => {
  switch (state) {
    case "書類選考":
      return "120px"
    case "一次選考":
      return "72px"
    case "二次選考":
      return "24px"
    case "内々定":
      return "0px"
    default:
      return "24px"
  }
}
const stateOpalWrapStyle = css`
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 152px;
  height: 8px;
  display: flex;
  justify-content: space-between;
`
const stateOrangeOpalStyle = css`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${colors.orange500};
`
const stateGrayOpalStyle = css`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${colors.mono300};
`