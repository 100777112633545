import React from 'react';
import A from '../../../components/atoms/A';
// import { Heading } from "../../molecules/Heading/Default";
export default function Education() {
  const students = [
    { id: 1039, name: "堀内 謹二", role: "フロントエンド", date: "2020/12/31", level: "初級", course: "div-pazzle-sp lesson2", progress: 20 },
    { id: 1040, name: "大島 隆哉", role: "フロントエンド", date: "2022/2/31", level: "初級", course: "div-pazzle-sp lesson1", progress: 10 },
    { id: 1041, name: "天野 裕史", role: "フロントエンド", date: "2020/12/4", level: "初級", course: "div-pazzle-sp lesson3", progress: 45 },
    { id: 1041, name: "川野 美緒", role: "フロントエンド", date: "2023/6/3", level: "基礎", course: "DB-04", progress: 60 },
    { id: 1041, name: "服部 敬正", role: "フロントエンド", date: "2020/12/2", level: "初級", course: "Docker-01", progress: 30 },
    { id: 1041, name: "渡部 雅則", role: "フロントエンド", date: "2024/7/3", level: "基礎", course: "Java-Beginner-04", progress: 70 },
    { id: 1041, name: "三木 梨絵", role: "フロントエンド", date: "2022/1/12", level: "基礎", course: "Java-Basic-04", progress: 85 },
  ];

  return (
    <div className="app">
      <style>{`
        /* Reset and base styles */
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        
        .app {
          font-family: Arial, sans-serif;
          color: #333;
          min-height: 100vh;
        }
        
        /* Header styles */
        .header {
          background-color: white;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }
        
        .header-content {
          max-width: 1200px;
          margin: 0 auto;
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .logo {
          font-size: 1.5rem;
          font-weight: bold;
          color: #2563eb;
        }
        
        .nav {
          display: flex;
          gap: 1rem;
        }
        
        .nav-link {
          color: #6b7280;
          text-decoration: none;
        }
        
        .nav-link.active {
          color: #2563eb;
          font-weight: 500;
        }
        
        .header-actions {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        
        .search-input {
          padding: 0.5rem;
          border: 1px solid #d1d5db;
          border-radius: 0.25rem;
        }
        
        .icon-button {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 1.25rem;
          color: #6b7280;
        }
        
        /* Main content styles */
        .main-content {
          max-width: 1200px;
          margin: 2rem auto;
          padding: 0 1rem;
        }
        
        .tabs {
          margin: 0 40px 40px 40px;
          gap: 1rem;
          text-align: center;
        }
        
        .tab {
          padding: 0.5rem 1rem;
          background: none;
          border: none;
          cursor: pointer;
          color: #6b7280;
        }
        
        .tab.active {
          background-color: #fff;
          color: #000;
          font-weight: bold;
          border-radius: 4px;
          border: 1px solid #CCCCCC;
        }
        
        .table-container {

        }
        
        .table-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        }
        
        .sort-button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem;
          background: none;
          border: 1px solid #d1d5db;
          border-radius: 0.25rem;
          cursor: pointer;
        }
        
        .table-filters {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        
        .checkbox-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        
        .select-input {
          padding: 0.5rem;
          border: 1px solid #d1d5db;
          border-radius: 0.25rem;
        }
        
        .data-table {
          width: 100%;
          background-color: #f3f4f6;
          border-collapse: collapse;
        }
        
        .data-table th,
        .data-table td {
          font-size: 12px;
          padding: 15px;
          text-align: left;
          border-bottom: 1px solid #e5e7eb;

          &:nth-child(5) {
            text-align: center;
          }
        }
        
        .data-table th {
          font-weight: 400;
          color: #6b7280;
        }

        .data-table-row {
          padding: 20px;
          border: 1px solid #e5e7eb;
          background-color: #fff;
        }

        .progress-container {
          font-size: 12px;
          font-weight: 400;
          text-align: center;
        }
        
        .progress-bar {
          width: 200px;
          // margin-top: 0.5rem;
          background-color: #e5e7eb;
          border-radius: 9999px;
          height: 0.5rem;
          overflow: hidden;
        }
        
        .progress {
          height: 100%;
          background-color: #FF7760;
          border-radius: 9999px;
        }
        
        /* Responsive design */
        @media (max-width: 768px) {
          .header-content {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
          }
        
          .nav {
            flex-wrap: wrap;
          }
        
          .tabs {
            flex-wrap: wrap;
          }
        
          .table-actions {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
          }
        
          .data-table {
            font-size: 0.875rem;
          }
        }
      `}</style>
      <div className="tabs">
        <button className="tab active">全て 30</button>
        <button className="tab">フロントエンド 23</button>
        <button className="tab">バックエンド 4</button>
        <button className="tab">デザイナー 3</button>
      </div>
      <div className="table-container">
        {/* <div className="table-actions">
          <button className="sort-button">
            並び替え
            <i className="icon-chevron-down">▼</i>
          </button>
          <div className="table-filters">
            <label className="checkbox-label">
              <input type="checkbox" /> 終了を含まない
            </label>
          </div>
        </div> */}
        <table className="data-table">
          <thead>
            <tr>
              <th>氏名</th>
              <th>職種</th>
              <th>目標</th>
              <th>現在作業中のカリキュラム</th>
              <th>進捗</th>
              <th>残り</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => (
              // <A href="/education/de">
                <tr key={student.id} className="data-table-row">
                  <td>{student.name}</td>
                  <td>{student.role}</td>
                  <td>{student.date}</td>
                  <td>{student.level} {student.course}</td>
                  <td>
                    <p className="progress-container">{student.progress}%</p>
                    <div className="progress-bar">
                      <div className="progress" style={{width: `${student.progress}%`}}></div>
                    </div>
                  </td>
                  <td>12/30</td>
                </tr>
              // </A>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
