export const recruitUsers = {
  data:[
    {
      id: 1,
      last_name: "佐藤",
      first_name: "さくら",
      last_name_kana: "サトウ",
      first_name_kana: "サクラ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "書類選考"
      },
      agent: {
        name: "duda"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 2,
      last_name: "鈴木",
      first_name: "一樹",
      last_name_kana: "スズキ",
      first_name_kana: "カズキ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "書類選考"
      },
      agent: {
        name: "Wantedly"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 3,
      last_name: "高橋",
      first_name: "美咲",
      last_name_kana: "タカハシ",
      first_name_kana: "ミサキ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "書類選考"
      },
      agent: {
        name: "Wantedly"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 4,
      last_name: "田中",
      first_name: "大輝",
      last_name_kana: "タナカ",
      first_name_kana: "ダイキ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "一次選考"
      },
      agent: {
        name: "エン転職"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 5,
      last_name: "伊藤",
      first_name: "陽菜",
      last_name_kana: "イトウ",
      first_name_kana: "ヒナ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "二次選考"
      },
      agent: {
        name: "duda"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 6,
      last_name: "山本",
      first_name: "健太",
      last_name_kana: "ヤマモト",
      first_name_kana: "ケンタ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "書類選考"
      },
      agent: {
        name: "Wantedly"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 7,
      last_name: "中村",
      first_name: "結衣",
      last_name_kana: "ナカムラ",
      first_name_kana: "ユイ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "二次選考"
      },
      agent: {
        name: "Wantedly"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 8,
      last_name: "小林",
      first_name: "颯太",
      last_name_kana: "コバヤシ",
      first_name_kana: "ソウタ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "書類選考"
      },
      agent: {
        name: "エン転職"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 9,
      last_name: "加藤",
      first_name: "彩花",
      last_name_kana: "カトウ",
      first_name_kana: "アヤカ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "内々定"
      },
      agent: {
        name: "Wantedly"
      },
      occpation: {
        name: "フロントエンド"
      }
    },
    {
      id: 10,
      last_name: "渡辺",
      first_name: "翔",
      last_name_kana: "ワタナベ",
      first_name_kana: "ショウ",
      parent_status: "",
      recruit: {
        id: 1,
        front_status: "一次選考"
      },
      agent: {
        name: "エン転職"
      },
      occpation: {
        name: "フロントエンド"
      }
    }
  ],
  status: [
  ],
}