import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "../../App/store";
import { SalesModel } from '../../api/model/sales'
import { getSalesUsers } from '../../api/sales'
import { serializeError } from "serialize-error";


// type SalesUsersLoaded = {
//   salesUsers: SalesModel[]
// }
type salesState = {
  salesUsers: SalesModel[]
  occupations: any
  status: any,
  modalType: string,
  modalFlag: boolean,
  loading: boolean;
  error: any;
};

type ModalState = {
  type: string
  flag: boolean
}

const initialState: salesState = {
  salesUsers:[
    {
      first_name: "",
      first_name_kana: "",
      last_name: "",
      last_name_kana: "",
      id: "",
      low_sales: [],
    },
  ],
  occupations: [{}],
  status: [
    {
      step: "書類選考",
      value: "1",
      status: "0"
    },
    {
      step: "一次選考",
      value: "2",
      status: "2"
    },
    {
      step: "二次選考",
      value: "3",
      status: "2"
    },
    {
      step: "内々定",
      value: "4",
      status: "2"
    },
  ],
  modalType: "",
  modalFlag: false,
  loading: false,
  error: null
};


const addOccupation = (occupations: any) => {
  return Array.from(new Set([...occupations, {}]))
}

const sales = createSlice({
  name: "sales",
  initialState,
  reducers: {
    getSalesUsersStart(state) {
      state.loading = true;
    },
    getSalesUsersSuccess(state, action: PayloadAction<any>) {
      const { salesUsers,status } = action.payload
      state.salesUsers = salesUsers;
      state.status = status;
      state.loading = false;
      state.error = null;
    },
    getSalesUsersFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    changeModalStateSuccess(state, action: PayloadAction<ModalState>) {
      const { type, flag } = action.payload
      state.modalType = type
      state.modalFlag = flag
      state.loading = false;
      state.error = null;
    },
    changeModalStateFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    addOccupationsStart(state) {
      state.loading = true;
    },
    addOccupationsSuccess(state, action: PayloadAction<any>) {
      state.occupations = addOccupation(state.occupations);
      state.loading = false;
      state.error = null;
    },
    addOccupationsFailure(state, action: PayloadAction<any>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    }
  }
});

export const {
  getSalesUsersSuccess,
  getSalesUsersStart,
  getSalesUsersFailure,
  changeModalStateSuccess,
  changeModalStateFailure,
  addOccupationsStart,
  addOccupationsSuccess,
  addOccupationsFailure,
  resetError
} = sales.actions;
export default sales.reducer;

export const fetchSalesUsers = ( auth:any ): any => async (dispatch:any) => {
  try {
    dispatch(getSalesUsersStart());
    const res = await getSalesUsers("0",auth)
    dispatch(
      getSalesUsersSuccess({
        salesUsers: res.data,
        status: res.status
      })
    );
  } catch (err: any) {
    dispatch(getSalesUsersFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const changeModalState = ( type: string, flag: boolean ): any => async (dispatch:any) => {
  try {
    dispatch(
      changeModalStateSuccess({type, flag})
    );
  } catch (err: any) {
    dispatch(changeModalStateFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const onAddOccupations = ( ): any => async (dispatch:any) => {
  try {
    dispatch(addOccupationsStart());
    dispatch(
      addOccupationsSuccess({})
    );
  } catch (err: any) {
    dispatch(addOccupationsFailure({ message: err.response.data.errors }));
    throw err;
  }
};
export const chengeList = ( tab: any, auth: any ): any => async (dispatch:any) => {
  try {
    dispatch(getSalesUsersStart());
    const res = await getSalesUsers(tab,auth)
    dispatch(
      getSalesUsersSuccess({
        salesUsers: res.data,
        status: res.status
      })
    );
  } catch (err: any) {
    dispatch(getSalesUsersFailure({ message: err.response.data.errors }));
    throw err;
  }
};