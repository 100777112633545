/** @jsxImportSource @emotion/react */
import { Link, useNavigate } from "react-router-dom";

const defaultProps = {
  children: ""
};

type Props = {
  to?: string;
  href?: string;
  children: any;
  back?: boolean;
} & typeof defaultProps;

export default function A(props: Props) {
  const { href, children, to, back, ...rest } = props;
  const navigate = useNavigate();
  return (
    <>
      {to && (
        <Link to={to} {...rest}>
          {children}
        </Link>
      )}
      {href && (
        <a href={href} {...rest}>
          {children}
        </a>
      )}
      {back && (
        <span onClick={() => navigate(-1)}>
          {children}
        </span>
      )}
    </>
  );
};

A.defaultProps = defaultProps;

