import React, { useState } from 'react';
import A from '../../../atoms/A';

export default function Employees() {
  const styles = {
    app: {
      fontFamily: 'Arial, sans-serif',
      color: '#333',
    },
    nav: {
      display: 'flex',
      gap: '20px',
    },
    navLink: {
      textDecoration: 'none',
      color: '#333',
    },
    searchInput: {
      marginLeft: 'auto',
      padding: '5px 10px',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
    },
    userControls: {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      marginLeft: '20px',
    },
    userAvatar: {
      width: '32px',
      height: '32px',
      backgroundColor: '#e0e0e0',
      borderRadius: '50%',
    },
    mainContent: {
      flex: 1,
      // backgroundColor: '#fff',
      marginTop: '40px',
      overflowY: 'auto' as const,
    },
    mainMenu: {
      display: 'flex',
      maxWidth: "1040px",
      borderBottom: '1px solid #e0e0e0',
    },
    mainMenuItem: {
      padding: '10px 20px',
      fontSize: '14px',
      color: '#333',
      cursor: 'pointer',
    },
    activeMenuItem: {
      borderBottom: '2px solid #4a90e2',
      fontWeight: 'bold',
    },
    uploadForm: {
      gap: '10px',
      padding: '20px',
      marginBottom: '40px',
      backgroundColor: '#fff',
      border: '1px solid #e0e0e0',
    },
    formGroup: {
      marginBottom: '30px',
    },
    formLabel: {
      fontWeight: 'bold',
      margin: '0 20px 0 0',
      gap: '10px',
    },
    formLabel2: {
      gap: '10px',
      color: '#999999',
    },
    formLabelBorder: {
      borderBottom: '2px solid #000',
      fontWeight: 'bold',
    },
    formInput: {
      width: '100%',
      marginTop: '10px',
      padding: '15px',
      border: '1px solid #e0e0e0',
      backgroundColor: '#F8F9FD',
      borderRadius: '4px',
    },
    formSelect: {
      display: 'block',
      backgroundColor: "#fff",
      border: "1px solid colors.mono300",
      borderRadius: "4px",
      height: "48px",
      position: "relative" as const,
      iconStyle: "icon-triangle_bottom",
      cursor: "pointer",
      "&::before": {
        position: "absolute",
        top: "50%",
        right: "10px",
        fontSize: "22px",
        marginRight: "5px",
        transform: "translateY(-50%)",
      }
    },
    formWrapper: {
      margin: "20px 0",
      borderBottom: "1px solid #e0e0e0",
    },
    submitWrapper: {
      textAlign: "right" as const,
    },
    formButton: {
      padding: "10px 20px",
      border: "1px solid #e0e0e0",
      borderRadius: "4px",
      backgroundColor: "#FF7760",
      color: "#fff",
    },
    documentInfo: {
      display: "flex",
      gap: "10px",
    },
    documentItem: {
      display: "flex",
      flexDirection: "column" as const,
      gap: "10px",
      width: "25%", // 4枚並びにするために25%の幅を設定
      padding: "10px", // 適宜調整
    },
    documentImage: {
      width: "150px",
      height: "200px",
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div style={styles.app}>
      <nav style={styles.mainMenu}>
        <div style={{...styles.mainMenuItem}}>
          <A to="/backoffice">議事録</A>
        </div>
        <div style={{...styles.mainMenuItem, ...styles.activeMenuItem}}>
          <A to="/backoffice/employees">雇用</A>
        </div>
      </nav>
      <div style={styles.mainContent}>
        <form onSubmit={handleSubmit} style={styles.uploadForm}>
          <div style={styles.formGroup}>
            <label style={{...styles.formLabel, ...styles.formLabelBorder}}>
              <input type="radio" name="upload-type" value="url" defaultChecked /> URL
            </label>
            <label style={styles.formLabel2}>
              <input type="radio" name="upload-type" value="upload" /> アップロード
            </label>
          </div>
          <div style={styles.formWrapper}>
            <div style={styles.formGroup}>
              <label htmlFor="target-personnel" style={styles.formLabel}>対象人材</label>
              <select id="target-personnel" style={styles.formSelect}>
                <option value="">選択</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="spreadsheet-url" style={styles.formLabel}>勤怠表</label>
              <input
                type="text"
                id="spreadsheet-url"
                style={styles.formInput}
                placeholder="https://docs.google.com/spreadsheets/..."
              />
            </div>
          </div>
          <div style={styles.submitWrapper}>
            <button type="submit" style={styles.formButton}>保存</button>
          </div>
        </form>

        <div style={styles.documentInfo}>
          {[...Array(3)].map((_, index) => (
            <div key={index} style={styles.documentItem}>
              <img src="/assets/img/test.png" alt={`Document ${index + 1}`} style={{width: "150px", height: "200px"}} />
              <div className="document-info">
                <span className="document-number">No.{1234 + index} </span>
                <span className="document-name">野中 愛</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
