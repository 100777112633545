/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom";
import { Button } from "../../../atoms/Button"
import { Text } from "../../../atoms/Text"
import { iconStyle } from "../../../atoms/Icon"
import { colors } from "../../../../styles/_var";
// import { useForm } from 'react-hook-form'

const defaultProps = {};

type Props = {
  sideBarUsersList: any;
  handleOpenModal: any
  hundleHideUserList: any
  children?: React.ReactNode;
} & typeof defaultProps;

export default function SidebarLeft(props: Props) {
  const { sideBarUsersList,handleOpenModal } = props
  let navigate = useNavigate();

  const userItemClick = (id:number) => {
    navigate("/talent",{state:id});
  }

  const [toggleList, setToggleList] = useState("99");
  const onClickToggleList = () => {
    if(toggleList === "0") {
      // 無効表示
      setToggleList("99");
    } else {
      // 有効表示
      setToggleList("0");
    }
    props.hundleHideUserList(toggleList)
  }

  return (
    <>
      <div css={sidebarWrapStyle}>
        <div css={buttonWrapStyle}>
          <Button Tag={"button"} type={"quaternary"} size={"s"} propStyle={ButtonStyle} onClick={()=>handleOpenModal("createUser",true)}>新規発行</Button>
        </div>
        <div css={headingWrapStyle}>
          <Text Tag="p" color="black" size="m" isBold={true}>一覧</Text>
          <div css={hideIconStyle(toggleList)} onClick={() => onClickToggleList()}>
            <Text Tag="p" color="gray" size="m" >無効応募</Text>
          </div>
        </div>
        <ul css={listStyle}>
          {sideBarUsersList.map((item:any,key:number)=>{
            return (
              <li key={key} onClick={()=>userItemClick(item.id)}>
                <Text Tag="p" color="gray" size="l" >{item.last_name + item.first_name}</Text>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  );
}

SidebarLeft.defaultProps = defaultProps;

const sidebarWrapStyle = css`
  width: 200px;
  border-right: 1px solid ${colors.mono300};
  min-height:100%;
`
const buttonWrapStyle = css`
  padding: 12px 8px;
  border-bottom: 1px solid ${colors.mono300};
`
const ButtonStyle = css`
  padding: 14px 20px;
  margin-left: auto;
  font-size: 16px;
  ${iconStyle('icon-Plus')};
  &::before {
    font-size: 16px;
    margin-right: 10px;
  }
`
const headingWrapStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 12px;
`
const listStyle = css`
  padding: 12px 20px;
  >li{
    cursor: pointer;
    margin-top: 24px;
  }
  >:first-of-type {
    margin-top: 0;
  }
`
const hideIconStyle = (toggle:any) => css`
  position: relative;
  padding-right: 20px;
  cursor: pointer;
  ${toggle === "0" ? iconStyle("icon-eye"): iconStyle("icon-eye_none")}
  ::before {
    position: absolute;
    bottom: 3px;
    right: 0;
  }
`