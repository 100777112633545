/** @jsxImportSource @emotion/react */
import React from "react";
// import React, { useState, useEffect } from "react";
import { css } from "@emotion/react"
import { useForm } from 'react-hook-form'
import UserCreateModal from "../../organisms/Modal/UserCreateModal"
import TalentModal from "../../organisms/Modal/TalentModal"
import { CardRecruit } from "../../molecules/Card/Recruit";
// import { CardEducation } from "../../molecules/Card/Education";
import { CardOperationManagement } from "../../molecules/Card/OperationManagement";
import { CardSales } from "../../molecules/Card/Sales";
import { Heading } from "../../molecules/Heading/Talent"
import { colors } from "../../../styles/_var";
// import { TalentModel } from '../../../api/model/talent'


const defaultProps = {};

type Props = {
  talentDetail: any
  prefecture: any,
  occupations: any,
  agents: any,
  onCreate: any
  onChenge: any
  modalState: {type: string, flag: boolean},
  setOpenModal: (type: string, flag: boolean) => void
  children?: React.ReactNode;
} & typeof defaultProps;

export default function Talent(props: Props) {
  const {talentDetail,setOpenModal} = props
  const {
    register,
    control,
    handleSubmit,
    formState: { errors,isValid },
  } = useForm({
    mode:"onChange",
    criteriaMode: "all",
    shouldFocusError: false,
  });

  // const onSubmit = () => {
  //   const data = getValues()
    // console.log(props)
  // }
  // const submit = () => {
  //   console.log("tab")
  // }
  // console.log(talentDetail)
  return (
    <>
    {/* TODO: API,アクティブ状態 */}
      <UserCreateModal
        prefecture={props.prefecture}
        occupations={props.occupations}
        agents={props.agents}
        open={props.modalState}
        onClose={()=>{props.setOpenModal("", false )}}
        hundleCreate={props.onCreate}
        handleSubmit={handleSubmit}
        register={register}
        control={control}
        isValid={isValid}
        errors={errors}
        handleOpenModal={props.setOpenModal}
      />
      {/* <TalentModal
        talentDetail={talentDetail}
        prefecture={props.prefecture}
        agents={props.agents}
        open={props.modalState}
        onClose={()=>{props.setOpenModal("", false )}}
        hundleChenge={props.onChenge}
        handleSubmit={handleSubmit}
        register={register}
        control={control}
      /> */}
      <div css={headingWrapStyle}>
        <Heading talentDetail={talentDetail} handleOpenModal={setOpenModal}/>
      </div>
      <div css={talentContentStyle}>
        <CardRecruit id={talentDetail.id} talentDetail={talentDetail} />
        {/* <CardEducation subject={talentDetail.education.subject} level={talentDetail.education.level} expected_date={talentDetail.education.expected_date} /> */}
        <CardSales id={talentDetail.id} talentDetail={talentDetail} />
        <CardOperationManagement id={talentDetail.id} talentDetail={talentDetail} />
      </div>
    </>
  );
}

Talent.defaultProps = defaultProps;

const headingWrapStyle = css`
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.mono300};
`
const talentContentStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  margin-top: 32px;
`